import { weekDays } from 'features/schedule/data';
import moment from 'moment';
import { getMonthDaysByString } from 'core/utils';

const yearMonth = moment().format('YYYY-MM');

export const initialState: any = {
  yearMonth,
  monthNum: +moment(yearMonth).format('MM'),
  currentMonth: moment(yearMonth).format('YYYY-MM'),
  startWeekDayNum: moment(yearMonth).startOf('month').day(),
  startDate: moment(yearMonth).startOf('month').format('DD.MM.YYYY'),
  endWeekDayNum: moment(yearMonth).endOf('month').day(),
  endDate: moment(yearMonth).endOf('month').format('DD.MM.YYYY'),
  daysInMonth: moment(yearMonth).daysInMonth(),
  calendar: [], // все записи календаря для текущей выборки (по специалисту, раб. месту. филиалу)
  //workplaces: [],
  days: getMonthDaysByString?.(yearMonth)?.map((day: any) => {
    return {
      yearMonth,
      date: day.format('DD.MM.YYYY'),
      dateMsec: day.unix(),
      dayNum: +day.format('DD'),
      monthNum: +day.format('MM'),
      yearNum: +day.format('YYYY'),
      weekDayNum: moment(day).day(),
      weekDay: weekDays.filter((d: any) => d.id === +day.format('DD'))?.[0]?.name || null,
      branch_id: null,
      branch: null,
      workplace_id: null,
      workplace: null,
      //workplaces: null,
      specialist_id: null,
      specialist: null,
      //specialists: null,
      calendar: null, // записи календаря только на этот день с учетом выборки по специалисту, раб. месту. филиалу
      schedule: null,
      /*[
          {
              branch_id: null,
              workplace_id: null,
              specialist_id: null,
              branch: null,
              workplace: null,
              specialist: null,
              grafik: {
                  from: "09:00",
                  to: "18:00"
              },
              smena: {
                  from: "",
                  to: ""
              },
              time: [
                  {
                      from: "09:00",
                      to: "18:00"
                  },
              ],
              timeout: [
                  {
                      from: "13:00",
                      to: "14:00"
                  },
              ],
          },
      ],*/
    };
  }),
};