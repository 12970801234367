import React from 'react';
import styled, {css} from 'styled-components';
import { useAppSelector } from 'core/hooks';
import { specialistSelector } from 'pages/specialist/specialistSlice';

type DayItemProps = {
  item: Record<string, any>;
  itemsCount: number;
  prev?: boolean;
  onRemove?: (id: number) => void;
}

export const DayItem: React.FC<DayItemProps> = ({item, itemsCount, prev, onRemove}) => {
  const specialists = useAppSelector(specialistSelector.specialists);

  const handleItemRemove = () => onRemove?.(item.id);

  const onMouseLeave = () => {
    //setShowSpecialists(false);
  }

  //console.log('DayItem',item.date,{itemsCount,item,prev});
  
  return (
    <Content itemsCount={itemsCount} onMouseLeave={onMouseLeave}>
      <ItemName>{item.time_from} - {item.time_to}</ItemName>
      <SpecialistsSelected>
        {specialists?.filter?.((s: any) => s.id === item.specialist_id)?.map((k,i) => 
          <SpecialistSelectedItem key={i} onClick={() => console.log(k)}>{k.surname} {k.name} {k.patronymic}</SpecialistSelectedItem>
        )}
      </SpecialistsSelected>
      {!prev && <ItemDelete title="Удалить" onClick={handleItemRemove}>&#x2715;</ItemDelete>}
    </Content>
  );
};

const Content = styled.div<{itemsCount: number}>`
  position: relative;
  border-radius: 3px;
  background-color: #E6DAF9;
  color: #282A2F;
  width: 100%;
  padding: 5px 7px;
  line-height: 1.5;
  ${({ itemsCount }) => {
    if (itemsCount === 1) {
      return css`
        height: 100%;
      `;
    }/* else if (itemsCount > 1) {
      return css`
        background-color: #EFF1F4;
      `;
    }*/
  }};
  & p {margin: 0;padding:0;}
`;
const ItemName = styled.p`
  margin-bottom: 5px!important;
`;
const ItemDelete = styled.span`
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 9;
  color: var(--color-red);
  font-size: 12px;
  &:hover {
    opacity: .75;
    cursor: pointer;
  }
`;
const SpecialistsSelected = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
`;
const SpecialistSelectedItem = styled.p`
  padding: 5px;
  text-align: center;
  //background-color: #FFEEC0;
  color: #282A2F;
  width: 100%;
`;