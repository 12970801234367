import createRequestRest from 'api/createRequestRest';
import { put } from 'redux-saga/effects';
import { specialistSlice } from '../specialistSlice';

function* prepareRequest(payload: any) {
  return payload?.payload?.data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  yield put(specialistSlice.actions.getSpecialistIdSuccess(response));
  payload?.payload?.success?.(response);
}

function* prepareFailure(res: any) {
  yield;
}

export const getSpecialistId = () => {
  return createRequestRest({
    url: '/api/v1/specialist/:id',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: specialistSlice.actions.getSpecialistIdRequest,
    method: 'get',
  });
};
