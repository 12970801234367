import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
//import { PlusInput } from 'features/plusInput';
import { SearchSelectorDefault } from 'features/searchSelectorDefault';
import { useSearch } from 'pages/kanban/hooks/useSearch';
import { requestsSearch } from 'api/search';
//import { IconClose } from 'icons';
//import { InfoPopUp } from 'components/infoPopUp';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { kanbanSelector } from 'pages/kanban/kanbanSlice';
import { serviceSlice, servicesServiceSelector } from 'pages/service/serviceSlice';
import { specialistSlice } from 'pages/specialist/specialistSlice';
import { entrySlice } from 'features/entry/entrySlice';

type ServiceItemProps = {
  getActiveData?: (data?: Record<string, any> | null) => void;
};

export const ServiceItem: React.FC<ServiceItemProps> = ({ getActiveData }) => {
  const dispatch = useAppDispatch();
  const activeCard = useAppSelector(kanbanSelector.activeCard);
  const [loading, setLoading] = useState(true);
  const [search, setSearchInput] = useState<string>('');
  const [activeServicesIds, setActiveServicesIds] = useState<number[]>([]);
  const [servicesFiltered, setServicesFiltered] = useState<any[]>([]);
  const [services, setServices] = useState<any[]>([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [searchServiceValue, setSearchServiceValue] = useState('');

  const { handledOnChangeSearch, handleClickItem, activeItem, clearItem, requestData, setActiveItem } = useSearch({
    setLoading: setLoading,
    setSearch: setSearchServiceValue,
    request: (search?: string, size?: number) =>
      requestsSearch
        .get_search_services({
          title: search || null,
          size: size || null,
        })
        .then((res: any) => {
          if (res?.data) {
            setLoading(false);
            if (res?.data?.services?.data) {
              // временный костыль, чтобы выводились дочерние услуги 1-го уровня, нужно дорабатывать поиск на бэкенде
              const parents = res?.data?.services.data.map((item: Record<string, any>) => ({
                id: item.id,
                name: item.title,
                price_dynamic: item.price_dynamic,
                children: item.children,
              }));
              const children: Record<string, any>[] = [];
              parents.forEach((item: Record<string, any>) => {
                if (item?.children?.length) {
                  item?.children?.forEach((child: Record<string, any>) => {
                    children.push({
                      id: child.id,
                      name: child.title,
                      price_dynamic: child.price_dynamic,
                    });
                  });
                }
              });
              setServices([...parents,...children]);
              setServicesFiltered([...parents,...children]);
              return [...parents,...children];
            }
          }
        }),
  });

  useEffect(() => {
    getActiveData?.(activeItem);
    dispatch(serviceSlice.actions.setServiceId(activeItem?.id));
  }, [activeItem]);
  
  useEffect(() => {
    if (activeCard) {
      const service = activeCard?.service;
      service &&
        setActiveItem({
          id: service?.id || service?.service_id,
          name: service?.title || '',
          price_dynamic: service?.price_dynamic || '',
        });
    }
  }, [activeCard]);

  const handleOnChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    return filterList(e.target.value);
  };
  
  const filterList = (search_val = '') => {
    if (search_val) {     
      const _servicesFiltered: Record<string, any>[] = [];       
      services.forEach((item) => {
        if (item?.name?.toLowerCase().search(search_val?.toLowerCase()) !== -1) {
            _servicesFiltered.push(item);
        }
      });
      
      setServicesFiltered(_servicesFiltered);
    } else {
      setServicesFiltered(services);
    }
    
    return servicesFiltered;
  };

  return (
    <Content>
      <SearchSelectorDefault
        onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (!hasSearched) {
            setLoading(true);
            handledOnChangeSearch(e);
            setHasSearched(true);
          } else {
            filterList();
          }
        }}
        onBlur={() => setLoading(false)}
        // value={searchServiceValue || activeItem?.name || ''}
        value={activeItem?.name || search || ''}
        loading={loading}
        valid={activeItem || searchServiceValue ? true : false}
        onChange={handleOnChangeSearch}
        data={servicesFiltered}
        handleClickItem={(item) => {
          handleClickItem(item);
          dispatch(serviceSlice.actions.setServiceId(item?.id));
          dispatch(specialistSlice.actions.setSpecialistId(null));
          dispatch(entrySlice.actions.resetEntry());
        }}
        isClear
        onClear={() => {
          setSearchInput('');
          clearItem();
          filterList();
          dispatch(serviceSlice.actions.setServiceId(null));
          dispatch(specialistSlice.actions.setSpecialistId(null));
          dispatch(entrySlice.actions.resetEntry());
        }}
      />
      {/* <SearchSelectorDefault
        onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
          setLoading(true);
          handledOnChangeSearch(e);
        }}
        onBlur={() => setLoading(false)}
        value={searchServiceValue || activeItem?.name || ''}
        loading={loading}
        valid={activeItem || searchServiceValue ? true : false}
        onChange={handledOnChangeSearch}
        data={requestData}
        handleClickItem={(item) => {
          handleClickItem(item);
          dispatch(serviceSlice.actions.setServiceId(item?.id));
          dispatch(specialistSlice.actions.setSpecialistId(null));
          dispatch(entrySlice.actions.resetEntry());
        }}
        isClear
        onClear={() => {
          clearItem();
          dispatch(serviceSlice.actions.setServiceId(null));
          dispatch(specialistSlice.actions.setSpecialistId(null));
          dispatch(entrySlice.actions.resetEntry());
        }}
      /> */}
      {/*activeItem ? (
        <WrapperTitle>
          <InfoPopUp placement={'right'} component={<Title>{activeItem?.name || ''}</Title>}>
            {activeItem?.name || ''}
          </InfoPopUp>
          <WrapperIcon onClick={clearItem}>
            <IconClose />
          </WrapperIcon>
        </WrapperTitle>
      ) : (
        <>
          <PlusInput
            component={
              <SearchSelectorDefault
                onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setLoading(true);
                  handledOnChangeSearch(e);
                }}
                onBlur={() => setLoading(false)}
                value={searchServiceValue || ''}
                loading={loading}
                onChange={handledOnChangeSearch}
                data={requestData}
                handleClickItem={handleClickItem}
                isClear
                onClear={clearItem}
              />
            }
          />
        </>
      )*/}
    </Content>
  );
};

const Content = styled.div``;
/*const Title = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black-light);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 220px;
`;
const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
`;
const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  cursor: pointer;
  svg {
    path {
      stroke: #d23333;
    }
  }
`;*/
