import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import styled from 'styled-components';
import { WhiteButton } from 'components/buttons';
import { IconKanban, IconTable } from 'icons';

import { Todolist } from 'pages/todolist/index';
import { Kanban } from 'pages/kanban/index';

export const Bids: React.FC = () => {
    const [view, setView] = useState<number>(0); // 0 - Kanban, 1 - TodoList
  
    const handleViewChange = (newView: number) => {
      setView(newView);
    };
  
    return (
        <Content>
            <Head>
                <Title>Заявки</Title>
                <BLockBtn>
                    <>
                        <DefBtn className={view === 0 ? 'active' : ''} onClick={() => handleViewChange(0)} title="Канбан"><IconKanban /></DefBtn>
                    </>
                    <>
                        <DefBtn className={view === 1 ? 'active' : ''} onClick={() => handleViewChange(1)} title="Таблица"><IconTable/></DefBtn>
                    </>
                </BLockBtn>
            </Head>
            <>
                {view === 0 ? <Kanban /> : <Todolist />}
            </>
        </Content>
    );
};
const Content = styled.div`
  width: 100%;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #282a2f;
`;

const DefBtn = styled(WhiteButton)`
    line-height: 1;
    padding: 6px 8px;
    height: auto;
    & svg {
        width: 16px;
        height: 16px;
        fill:var(--color-black-light);
        vertical-align: middle;
    }
    &.active{
        background: var(--color-blue);
        border: 1px solid var(--color-blue);
        color: var(--color-white);
        & svg {
            fill:var(--color-white);
        }
    }
`;
const BLockBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 78px;
  margin-top: 10px;
`;
const Head = styled.div`
  padding: 24px 0px 0px 32px;
  width: 100%;
`;