import React, { ChangeEvent, InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { IconClose } from 'icons';
import { useMouseDown, useAppDispatch } from 'core/hooks';
import { DefaultButton, WhiteButton } from 'components/buttons';
import { Input } from 'components/input';
import { InputMack } from 'components/inputMask';
import { clientsSlice } from 'pages/clients/clientsSlice';
import { Preloader } from 'components/preloader';

export type ItemDataProps = {
  id: string | number;
  reduction: string;
  fullName?: string;
  name: string;
  phone: string;
  birthday_at: string;
};

type SearchProps = InputHTMLAttributes<HTMLInputElement> & {
  icon?: any;
  valid?: boolean;
  name?: string;
  isClear?: boolean;
  onClear?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  disabled?: boolean;
  data?: ItemDataProps[];
  loading?: boolean;
  value?: string;
  handleClickItem?: (data: Record<string, any>) => void;
};

type NewClientProps = InputHTMLAttributes<HTMLInputElement> & {
  handleClickItem?: (data: Record<string, any>) => void;
};

const NewClient: React.FC<NewClientProps> = ({handleClickItem}) => {
  const dispatch = useAppDispatch();
  const [showButton, setShowButton] = useState<boolean>(true);
  const [showInputs, setShowInputs] = useState<boolean>(false);
  const [clientName, setClientName] = useState<string>('');
  const [clientPhone, setClientPhone] = useState<string>('');
  const [showClientNameError, setClientNameError] = useState<boolean>(false);
  const [showClientPhoneError, setClientPhoneError] = useState<boolean>(false);
  const [clientNameErrorMessage, setClientNameErrorMessage] = useState<string>('');
  const [clientPhoneErrorMessage, setClientPhoneErrorMessage] = useState<string>('');

  const handleClickNewClient = () => {
    setShowInputs(true);
    setShowButton(false);
  };

  const handleClickAddClient = () => {
    if (clientName.length && clientPhone.length) {
      dispatch(
        clientsSlice.actions.postCreateClientRequest({
          payload: {
            data: {
              name: clientName,
              phone: clientPhone,
            },
            success: () => {
              handleClickItem?.({
                name: clientName,
                phone: clientPhone
              });
              setShowInputs(false);
              setShowButton(true);
              setClientName('');
              setClientPhone('');
            },
          },
        }),
      );
    } else {
      if (!clientName.length) {
        setClientNameError(true);
        setClientNameErrorMessage('Не указано имя');
      }
      if (!clientPhone.length) {
        setClientPhoneError(true);
        setClientPhoneErrorMessage('Не указан телефон');
      }
    }
  };

  const handleClickClose = () => {
    setShowInputs(false);
    setShowButton(true);
  };

  return(
    <ItemNewClient>
      <ItemContent>
        {showButton && <NewClientButton onClick={handleClickNewClient}>+ Новый клиент</NewClientButton>}
        {
          showInputs &&
          <InputsBlock>
            <Input 
              name={'name'}
              defaultValue={clientName} 
              placeholder={'Имя'} 
              isClear
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setClientNameError(false);
                setClientNameErrorMessage('');
                setClientName(e.target.value);
              }}
              onClear={() => setClientName('')}
            />
            {showClientNameError && clientNameErrorMessage.length && <NewClientError>{clientNameErrorMessage}</NewClientError>}
            <InputMack
              mask={'+9 (999) 999-99-99'}
              name={'phone'}
              defaultValue={clientPhone}
              isClear
              placeholder={'Телефон'}
              value={clientPhone}
              onChange={(e) => {
                setClientPhoneError(false);
                setClientPhoneErrorMessage('');
                setClientPhone(e.target.value
                    /*.replaceAll('-', '')
                    .replaceAll('_', '')
                    .replaceAll('+', '')
                    .replaceAll('(', '')
                    .replaceAll(')', '')
                    .replaceAll(' ', '')
                    .trim(),*/
                );
              }}
              onClear={() => setClientPhone('')}
            />
            {showClientPhoneError && clientPhoneErrorMessage.length && <NewClientError>{clientPhoneErrorMessage}</NewClientError>}
            <ButtonsBlock>
              <NewClientButton onClick={handleClickAddClient}>Добавить</NewClientButton>
              <CloseButton onClick={handleClickClose}>Закрыть</CloseButton>
            </ButtonsBlock>
          </InputsBlock>
        }
      </ItemContent>
    </ItemNewClient>
  );
};

export const SearchClientsSelector: React.FC<SearchProps> = (props) => {
  const closeIconRef = useRef<HTMLSpanElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showList, setShowList] = useState(false);
  const Icon = props?.icon && props.icon;
  const { valid = true, loading } = props;

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (props?.disabled) return;
    props?.onChange?.(e);
  };

  const handleOnFocus = (e: ChangeEvent<HTMLInputElement>) => {
    if (props?.disabled) return;
    setShowList(true);
    props?.onFocus?.(e);
  };

  const handleOnBlur = (e: ChangeEvent<HTMLInputElement>) => {
    if (props?.disabled) return;
    props?.onBlur?.(e);
  };

  useEffect(() => {
    if (props?.value) {
      if (closeIconRef.current) {
        closeIconRef.current.style.opacity = '1';
        closeIconRef.current.style.cursor = 'pointer';
        closeIconRef.current.style.pointerEvents = 'auto';
      }
    } else {
      if (closeIconRef.current) {
        closeIconRef.current.style.opacity = '0';
        closeIconRef.current.style.cursor = 'text';
        closeIconRef.current.style.pointerEvents = 'none';
      }
    }
  }, [props?.value]);

  const handleClickClear = () => {
    if (props?.disabled) return;
    if (inputRef?.current) {
      inputRef.current.value = '';
      props?.onClear?.();
    }
  };

  useMouseDown(() => setShowList(false), listRef, inputRef);

  const handleClickItem = (data: Record<string, any>) => () => {
    props?.handleClickItem?.(data);
    setShowList(false);
  };

  return (
    <WrapperInput>
      {!!props?.icon && (
        <WrapperIcon>
          <Icon />
        </WrapperIcon>
      )}
      <Content
        ref={inputRef}
        disabled={!!props?.disabled}
        value={props?.value}
        name={props?.name}
        type={props?.type}
        icon={!!props.icon}
        valid={!!valid}
        isClear={!!props?.isClear}
        required
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      ></Content>
      {props.placeholder && (
        <Placeholder valid={!!valid} icon={!!props.icon}>
          {props.placeholder}
        </Placeholder>
      )}
      {props?.isClear && (
        <WrapperIconClose ref={closeIconRef} onClick={handleClickClear}>
          <IconClose />
        </WrapperIconClose>
      )}
      {showList && !!props?.value && (
        <List ref={listRef}>
          {loading && <div style={{ margin: 14 }}><Preloader loading={loading} /></div>}
          {props?.data?.length && !loading
            ? props?.data?.map((item) => (
                <ItemClient key={item.id.toString()} onClick={handleClickItem(item)}>
                  <Circle>{item.reduction}</Circle>
                  <ItemContent>
                    <ItemTitle>{item.fullName}</ItemTitle>
                    <ItemContact>
                      <ItemPhone>{item.phone}</ItemPhone>
                      <ItemDate>{item?.birthday_at && item?.birthday_at !== 'Invalid date' ? item?.birthday_at.toString() : ''}</ItemDate>
                    </ItemContact>
                  </ItemContent>
                </ItemClient>
                // <Item key={item.id.toString()} onClick={handleClickItem(item)}>
                //   {item.name}
                // </Item>
              ))
            : !loading && <TextData>Ничего не найдено</TextData>}
          <NewClient handleClickItem={props?.handleClickItem} />
        </List>
      )}
    </WrapperInput>
  );
};

const Circle = styled.div`
  height: 32px;
  width: 32px;
  background: var(--color-yelow);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  margin-right: 10px;
`;

const ItemClient = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  &:hover {
    background: var(--color-grey-light);
  }
`;
const ItemContent = styled.div`
  width: 100%;
`;
const ItemTitle = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
`;
const ItemContact = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const ItemPhone = styled.div`
  font-size: 11px;
  color: #82889a;
`;
const ItemDate = styled.div`
  font-size: 11px;
  color: #82889a;
`;
const ItemNewClient = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  margin-top: 0;
  border-top: 1px #f0f0f0 solid;
`;
const NewClientButton = styled(DefaultButton)`
  width: auto;
  transition: .3s;
  &:hover {
    opacity: .85;
  }
`;
const InputsBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
`;
const ButtonsBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
`;
const CloseButton = styled(WhiteButton)`
  width: auto;
  transition: .3s;
  &:hover {
    opacity: .85;
  }
`;
const NewClientError = styled.small`
  margin: -5px 0 0;
  padding: 0;
  font-size: 10px;
  color: var(--color-red);
`;


const TextData = styled.div`
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
  text-align: center;
`;

const Info = styled.div`
  padding: 50px 0;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: var(--color-black-light);
`;
const Item = styled.div`
  cursor: pointer;
  padding: 7px 16px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black-light);
  cursor: pointer;
  &:hover {
    background: var(--color-grey-light);
  }
`;
const List = styled.div`
  z-index: 9999;
  overflow-y: auto;
  width: 100%;
  max-height: 400px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: var(--color-white);
  transform: translateY(100%);
  border: 0.5px solid #dfe1e4;
  border-radius: 8px;
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.15);
`;
const WrapperIconClose = styled.span`
  cursor: text;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
`;

const Placeholder = styled.span<{ icon: boolean; valid: boolean }>`
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translateY(50%);
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  transition: all 0.33s ease;

  ${({ valid }) => {
    if (valid) {
      return css`
        color: var(--color-black-grey);
      `;
    } else {
      return css`
        color: var(--color-red);
      `;
    }
  }};

  ${({ icon }) => {
    if (icon) {
      return css`
        left: 40px;
      `;
    } else {
      return css`
        left: 16px;
      `;
    }
  }}
`;
const WrapperIcon = styled.div`
  position: absolute;
  left: 12px;
  top: 0;
  transform: translateY(70%);
  display: flex;
  align-items: center;
  justify-content: center;
`;
const WrapperInput = styled.div`
  width: 100%;
  position: relative;
`;
const Content = styled.input<{ icon: boolean; valid: boolean; isClear: boolean; disable?: boolean }>`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black);
  height: 40px;
  border: 1px solid #dfe1e4;
  border-radius: 8px;
  width: 100%;

  &::placeholder {
    color: #ffffff00;
  }

  &:focus {
    border: 1px solid #3562d4;
  }

  &:focus + ${Placeholder} {
    transform: translateY(0);
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    top: -6px;
    background: var(--color-white);
    padding: 0 5px;
    color: #3562d4;
  }
  &:valid + ${Placeholder} {
    transform: translateY(0);
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    top: -6px;
    background: var(--color-white);
    padding: 0 5px;
  }
  ${({ valid }) => {
    if (valid) {
      return css`
        border: 1px solid #dfe1e4;
      `;
    } else {
      return css`
        border: 1px solid var(--color-red);
      `;
    }
  }};

  ${({ icon, isClear }) => {
    if (isClear) {
      if (icon) {
        return css`
          padding: 12px 40px 12px 40px;
        `;
      } else {
        return css`
          padding: 12px 40px 12px 16px;
        `;
      }
    } else if (icon) {
      return css`
        padding: 12px 16px 12px 40px;
      `;
    } else {
      return css`
        padding: 12px 16px;
      `;
    }
  }}
`;
