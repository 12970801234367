import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, {css} from 'styled-components';
//import { IconSearch, IconArrowDown } from 'icons';
import { Input } from 'components/input';
//import { CheckboxService } from 'components/checkboxService';
//import { useAppDispatch, useAppSelector } from 'core/hooks';
//import { InputSearchLocal } from 'components/ui/inputSearchLocal';
import { useTabsContext } from '../context';
//import { serviceSlice, servicesServiceSelector } from 'pages/service/serviceSlice';
import { SearchServiceSelector } from 'features/searchServiceSelector';

type ServiceProps = {
  setChanged: (isChanged: boolean) => void;
};

export const Service: React.FC<ServiceProps> = ({ setChanged }) => {
  //const dispatch = useAppDispatch();
  //const services = useAppSelector(servicesServiceSelector);
  //const [search, setSearch] = useState<string>('');
  const { state: stateTabs, setState: setStateTabs } = useTabsContext();
  //const activeServices = useMemo(() => stateTabs?.person?.data?.services || [], [stateTabs?.person?.data?.services]);
  const data = useMemo(() => stateTabs?.person?.data, [stateTabs?.person?.data]);
  /*const [activeServicesIds, setActiveServicesIds] = useState<number[]>([]);
  const [openParentIds, setOpenParentIds] = useState<number[]>([]);
  const [servicesFiltered, setServicesFiltered] = useState<any[]>([]);*/

  /*useEffect(() => {
    setServicesFiltered(services);
    dispatch(serviceSlice.actions.getAllServiceRequest({
      payload: {
        data: {},
        success: () => {
          setActiveServicesIds(activeServices?.map?.((item) => item.id) || []);
          setServicesFiltered(services);
        }
      }
    }));    
  }, []);

  useEffect(() => {
    if (services.length) {
      if (search.length) {
        const _servicesFiltered: Record<string, any>[] = [];
        for (let i = 0; i < services.length; i++) {
          const _service: Record<string, any> = JSON.parse(JSON.stringify(services[i]));
          _service.children.length = 0;
          for (let j = 0; j < services[i].children.length; j++) {
            if (services[i].children[j].title.toLowerCase().search(search.toLowerCase()) !== -1) {
              _service.children.push(services[i].children[j]);
            }
          }
          if (_service.title.toLowerCase().search(search.toLowerCase()) !== -1 || _service.children.length) {
            _servicesFiltered.push(_service);
          }
        }
        setServicesFiltered(_servicesFiltered);
      } else {
        setServicesFiltered(services);
      }
    }
    return () => setServicesFiltered(services);
  }, [search, services]);

  const handleOnChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };*/

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setStateTabs?.((prev) => ({
      ...prev,
      person: {
        ...prev?.person,
        data: {
          ...prev?.person?.data,
          [e.target.name]: e.target.value,
        },
      },
    }));
    setChanged(true);
  };

  /*const handleSelectParentService = (parent: any) => {
    let ids: number[] = activeServicesIds;
    if (activeServicesIds.includes(parent.id)) {
      ids = activeServicesIds.filter((i: number) => i !== parent.id);
      parent?.children?.forEach((child: any) => {
        ids = ids.filter((i: number) => i !== child.id);
      });
    } else {
      ids.push(parent.id);
      parent?.children?.forEach((i: any) => {
        ids.push(i.id);
      });
    }
    setActiveServicesIds([...ids]);
    setChanged(true);
    setStateTabs?.((prev) => ({
      ...prev,
      person: {
        ...prev?.person,
        data: {
          ...prev?.person?.data,
          services: [...ids],
        },
      },
    }));
  }

  const handleSelectChildService = (child: any) => {
    let ids: number[] = activeServicesIds;
    if (activeServicesIds.includes(child.id)) {
      ids = activeServicesIds.filter(i => i !== child.id);
    } else {
      ids.push(child.id);
    }
    setActiveServicesIds([...ids]);
    setChanged(true);
    setStateTabs?.((prev) => ({
      ...prev,
      person: {
        ...prev?.person,
        data: {
          ...prev?.person?.data,
          services: [...ids],
        },
      },
    }));
  }

  const handleShowChildren = (parent_id: number) => {
    if(openParentIds.includes(parent_id)) {
      setOpenParentIds((prev) => prev.filter(id => id !== parent_id));
    } else {
      setOpenParentIds((prev) => [...prev, parent_id]);
    }
  }

  const getSelectedChildren = useCallback((parent_id: number) => {
    const parent: Record<string, any>[] = services.filter(s => s.id === parent_id);
    const childrenIds: number[] = parent?.[0].children?.map(ch => ch.id);
    const selected = [...new Set(activeServicesIds.filter(id => id === parent_id || childrenIds.includes(id)))];
    return selected.length;
  }, [services, activeServicesIds]);  */
  
  
  return (
    <Content>
      <Left>
        <SearchServiceSelector 
          title='Услуги' 
          placeholder='Найти услугу' 
          useContext={useTabsContext} 
          setChanged={setChanged} 
        />
      </Left>
      {/*<Left>
        <Head>
          <Title>Услуги</Title>
        </Head>
        <WrapperInput>
          <InputSearchLocal
            icon={IconSearch}
            placeholder={'Найти услугу'}
            value={search}
            onChange={handleOnChangeSearch}
            data={search}
            isClear={true}
            onClear={() => setSearch('')}
          />
        </WrapperInput>
        {servicesFiltered.map((parent) => 
          <>
            <ItemParentService key={parent.id}>
              <WrapperIconArrowDown onClick={() => handleShowChildren(parent.id)} show={openParentIds.includes(parent.id) || search.length > 0}>
                <IconArrowDown />
              </WrapperIconArrowDown>
              <CheckboxService
                active={activeServicesIds.includes(parent.id)}
                setActive={() => handleSelectParentService(parent)}
                title={parent.title}
              />
              <ItemServiceCounters>
                <span>{getSelectedChildren(parent.id)}</span>/<span>{parent.children.length + 1}</span>
              </ItemServiceCounters>
            </ItemParentService>
            {(openParentIds.includes(parent.id) || search.length > 0) && parent.children.map((child) => 
              <ItemChildService key={child.id}>
                <CheckboxService
                  active={activeServicesIds.includes(child.id)}
                  setActive={() => handleSelectChildService(child)}
                  title={child.title}
                />
              </ItemChildService>
            )}
          </>)}
      </Left>*/}
      <Right>
        <TitleRight>Профессия специалиста</TitleRight>
        <WrapperInput>
          <Input
            placeholder={'Введите профессию'}
            name='profession'
            value={data?.profession}
            onChange={handleOnChange}
          />
        </WrapperInput>
      </Right>
    </Content>
  );
};

/*const ItemParentService = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  padding: 10px 0;
  &:hover {
    background: rgba(231, 231, 231, 0.56);
  }
  &:not(:last-child) {
    border-bottom: 2px solid var(--color-grey);
  }
`;
const ItemChildService = styled(ItemParentService)`
  padding-left: 39px;
`;
const ItemServiceCounters = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2px;
  justify-self: flex-end;
  margin-left: auto;
  margin-right: 5px;
  font-size: 12px;
`;*/
const Left = styled.div`
  width: 378px;
`;
const Right = styled.div`
  width: 378px;
  margin-left: 56px;
`;
const WrapperInput = styled.div`
  margin-bottom: 24px;
`;
/*const WrapperIconArrowDown = styled.div<{ show: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.33s ease-in-out;
  cursor: pointer;
  ${({ show }) => {
    if (show) {
      return css`
        svg {
          transform: rotate(-90deg);
          transition: all 0.33s ease-in-out;
        }
      `;
    } else {
      return css`
        transform: rotate(0deg);
      `;
    }
  }}
`;*/
const Title = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
`;
const TitleRight = styled(Title)`
  margin-bottom: 16px;
`;
/*const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
`;*/
const Content = styled.div`
  display: flex;
  padding: 24px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 0 8px 8px 8px;
  min-width: 1164px;
`;