import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useScheduleContext } from '../context';
import { useAppSelector } from 'core/hooks';
import { branchSelector } from 'pages/branch/branchSlice';
import { workplaceSelector } from 'pages/workplace/workplaceSlice';
import { specialistSelector } from 'pages/specialist/specialistSlice';
import { scheduleFeatureSelector } from '../scheduleFeatureSlice';
import { RadioInput } from 'components/radioInput';
import { Checkbox } from 'components/checkbox';
import { ScheduleRow, ButtonAddRow, ButtonClear } from '../row';
import { ScheduleShifts } from '../shifts';
import { ScheduleVocation} from '../vocation';
import { scheduleTypes } from '../data';
import { ScheduleCalendar } from 'features/scheduleCalendar';
import { ScheduleSwitcher } from 'features/schedule/switcher';
import { SelectorStatic } from 'components/selectorStatic';
import { Input } from 'components/input';
import { ScheduleFromToType, ScheduleType } from '../types';
import {
  FlexRow,
  ScheduleTypeBlock,
  ScheduleContainer,
  ScheduleRowTitle,
  ScheduleRowBlock,
  WeekDaysBlock,
  WeekDaysScheduleRowsBlock,
  FlexColumn,
} from '../styles';

export const ScheduleSpecialistLayout: React.FC = () => {

  const branches = useAppSelector(branchSelector.branches);
  const workplaces = useAppSelector(workplaceSelector.workplaces);
  const specialist = useAppSelector(specialistSelector.specialist);
  const scheduleTypeNumber = useAppSelector(scheduleFeatureSelector.type);
  const {
    showSwitcher,
    schedule,
    defaultSchedule,
    shifts, 
    commonWithParent,
    scheduleType,
    flexibleGrafik,
    showShifts,
  } = useScheduleContext();

  const {
    setCommonWithParent,
    setScheduleType,
  } = useScheduleContext();

  const {
    handleSetByWeekDays,
    handleAddShift,
    handleRemoveShift,
    handleClear
  } = useScheduleContext();

  const [branchSelected, setBranchSelected] = useState<Record<string, any> | null>(null);
  const [workplaceSelected, setWorkplaceSelected] = useState<Record<string, any> | null>(null);
  const [scheduleSelected, setScheduleSelected] = useState<Record<string, any>[] | null>(null);

  /*useEffect(() => {
    if (specialist?.schedule) {
      setCommonWithParent(false);
    } else {
      setCommonWithParent(true);
    }
    return () => setCommonWithParent(false);
  }, [specialist]);*/

  useEffect(() => {
    if (branchSelected) {
      let _schedule = JSON.parse(JSON.stringify(branchSelected?.schedule?.flat()));
      _schedule = _schedule?.map((item: any) => {
        item.branch_id = branchSelected?.id || null;
        item.workplace_id = workplaceSelected?.id || null;
        item.specialist_id = specialist?.id || null;
        return item;
      });
      setScheduleSelected(_schedule);
    }
  }, [branchSelected,workplaceSelected,specialist]);

  const handleSelectBranch = (b: Record<string, any> | null) => {
    setBranchSelected(b);
  }

  const handleSelectWorkplace = (w: Record<string, any> | null) => {
    setWorkplaceSelected(w);
  }

  return (
    <Content>
      {scheduleTypeNumber === 0 && <ScheduleCalendar showSwitcher={true} />}
      {scheduleTypeNumber === 1 &&
        <Schedule>
          <ScheduleHeader>
          {showSwitcher && <ScheduleSwitcherBlock><ScheduleSwitcher /></ScheduleSwitcherBlock>}
            <SelectorBlock disabled={scheduleType !== scheduleTypes.byWeekDays.type}>
              <p>Филиал</p>
              <SelectorStatic 
              // вернуться к этому варианту! выбор из филиалов раб. мест специалиста
                /*data={specialist?.workplaces?.filter((w: any) => w?.branch?.id)?.map?.((w: any) => {
                  return {
                    id: w.branch.id,
                    value: w.branch.name,
                    data: w.branch.name,
                  };
                })}*/
                data={branches.map((item: any) => {
                  const _schedule = defaultSchedule?.filter?.((b: any) => b?.branch_id === item.id);
                  return {id:item.id,value:item.name,data:item.name,schedule: _schedule?.[0]?.data?.[0] || []};
                })}
                onChange={handleSelectBranch} 
                valueId={branchSelected?.id || null}
              />
            </SelectorBlock>
            <SelectorBlock disabled={scheduleType !== scheduleTypes.byWeekDays.type}>
              <p>Рабочее место</p>
              <SelectorStatic 
              // переделать! выбор из раб. мест специалиста
                data={(specialist?.workplaces || workplaces)?.map((item: any) => {
                  return {id:item.id,value:item.title || item.name,data:item.title || item.name};
                })}
                onChange={handleSelectWorkplace} 
                valueId={workplaceSelected?.id || '1'}
              />
            </SelectorBlock>
          </ScheduleHeader>
          <ScheduleBody>
            <ScheduleTypeBlock>
              <RadioInput
                title={scheduleTypes.byWeekDays.title}
                type={scheduleTypes.byWeekDays.type} 
                value={scheduleType} 
                onClick={handleSetByWeekDays}
              />
              <CheckboxBlock>
                <Checkbox
                  disabled={scheduleType !== scheduleTypes.byWeekDays.type}
                  active={commonWithParent || false}
                  setActive={() => {
                    setCommonWithParent(!commonWithParent);
                  }}
                  title='Совпадает с расписанием рабочего места'
                />
              </CheckboxBlock>
              <ScheduleRowBlock disabled={scheduleType !== scheduleTypes.byWeekDays.type}>
                  <ScheduleRowTitle style={{marginLeft: flexibleGrafik ? '10px' : 0}}>{'Расписание по дням недели'}</ScheduleRowTitle>
                  <WeekDaysBlock>
                    <WeekDaysScheduleRowsBlock>
                    {schedule?.map?.((item: ScheduleType, i: number) => 
                      <ScheduleRow 
                        key={i}
                        type={'time'}
                        number={i}
                        name={item?.data?.[0]?.name}
                        disabled={false}
                        showSuffix={false}
                        titleBegin='Начало'
                        titleEnd='Окончание'
                        grafikTime={item?.data?.[0]?.grafik?.time?.[0]}
                        showWeekDay={true}
                        selectWeekDay={true}
                        showShifts={showShifts}
                      />
                    )}
                    </WeekDaysScheduleRowsBlock>
                  </WeekDaysBlock>
              </ScheduleRowBlock>
              <ButtonClear onClear={handleClear} disabled={scheduleType !== scheduleTypes.byWeekDays.type} />
            </ScheduleTypeBlock>
            <ScheduleTypeBlock>
              <RadioInput
                title={scheduleTypes.byShifts.title}
                type={scheduleTypes.byShifts.type} 
                value={scheduleType} 
                onClick={() => {
                  setScheduleType(scheduleTypes.byShifts.type);
                }}
              />
              <SelectorBlock disabled={scheduleType !== scheduleTypes.byShifts.type}>
                <p>Расписание по графику</p>
                <GrafikBlock>
                  <Input name="grafik_days" type="number" /><label>дней</label>
                  <GrafikOverSpan>через</GrafikOverSpan>
                  <Input name="grafik_days_over" type="number" /><label>дней</label>
                </GrafikBlock>
              </SelectorBlock>
              <ScheduleContainer>
                <ScheduleShiftBlock>
                  <ScheduleRowBlock disabled={scheduleType !== scheduleTypes.byShifts.type}>
                    <ScheduleRowTitle>Рабочее время</ScheduleRowTitle>
                    <ScheduleShifts type={'time'} titleBegin='Начало' titleEnd='Окончание' showSuffix={true} />
                    <ButtonAddRow onAdd={handleAddShift} disabled={scheduleType !== scheduleTypes.byShifts.type} title='Добавить' />
                </ScheduleRowBlock>
                  <ScheduleRowBlock disabled={scheduleType !== scheduleTypes.byShifts.type}>
                    <ScheduleRowTitle>Нерабочее время</ScheduleRowTitle>
                    {shifts?.map?.((item: ScheduleFromToType, i: number) => (
                      <ScheduleRow 
                        key={i}
                        type={'time'}
                        number={i}
                        itemsCount={shifts?.length}
                        disabled={scheduleType !== scheduleTypes.byShifts.type}
                        showSuffix={true}
                        titleBegin='Начало'
                        titleEnd='Окончание'
                        onDelete={handleRemoveShift}
                      />
                    ))}
                    <ButtonAddRow onAdd={handleAddShift} disabled={scheduleType !== scheduleTypes.byShifts.type} title='Добавить' />
                  </ScheduleRowBlock>
                  <ButtonClear onClear={handleClear} disabled={scheduleType !== scheduleTypes.byShifts.type} />
                </ScheduleShiftBlock>
              </ScheduleContainer>
            </ScheduleTypeBlock>
            <ScheduleTypeBlock><ScheduleVocation /></ScheduleTypeBlock>
          </ScheduleBody>
        </Schedule>
      }
    </Content>
  );
};

const Content = styled(FlexRow)`
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
  border: 0;
  width: 100%;
  & > * {
    min-width: 383px;
  }
`;

const Schedule = styled(FlexColumn)`
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
  border: 0;
  width: 100%;
`;

const ScheduleSwitcherBlock = styled(FlexRow)`
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  border: 0;
  width: 100%;
  padding-top: 16px;
`;

const ScheduleHeader = styled(FlexRow)`
  align-items: flex-start;
  justify-content: flex-start;
  gap: 48px;
  border: 0;
  width: 100%;
  padding: 6px 24px;
  & > *:nth-child(1) { max-width: 340px;}
  & > *:nth-child(2) { max-width: 340px;}
`;
const ScheduleBody = styled(FlexRow)`
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
  border: 0;
  width: 100%;
  & > * {
    min-width: 383px;
  }
`;

const CheckboxBlock = styled.div`
  margin: 1em 0;
`;

const SelectorBlock = styled(FlexColumn)<{disabled: boolean}>`
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  border: 0;
  margin: 1em 0 .75em;
  width: 100%;
  & > * {
      width: 100%;
  }
  & p {
    font-size: 12px;
  }
  ${({ disabled }) => {
    if (disabled) {
      return css`
        pointer-events:none;
        opacity: .85;
        color: var(--color-table-title);
      `;
    }
  }};
`;

const ScheduleShiftBlock = styled(FlexColumn)`
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  border: 0;
  width: 100%;
`;
const GrafikBlock = styled(FlexRow)`
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
`;
const GrafikOverSpan = styled.span`
  margin-left: 12px;
`;
