import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'core/store';
import { initialState } from './data';

export const scheduleCalendarSlice = createSlice({
  name: 'scheduleCalendar',
  initialState,
  reducers: {
    resetCalendar: () => initialState,
    /*initCalendar: (state) => {
        state.calendar = initialState;
        console.log('initCalendar',state.calendar);
    },*/
    setCalendar: (state, action: PayloadAction<any>) => {
        state.calendar = action.payload;
        console.log('setCalendar',state.calendar);
    },
  },
});

export const { 
  resetCalendar, 
  //initCalendar,
  setCalendar,
} = scheduleCalendarSlice.actions;

export const scheduleCalendarSelector = {
  calendar: (s: RootState) => s.scheduleCalendar.calendar,
};