import React from 'react';
import styled from 'styled-components';
import { useTabsContext } from '../../context';
import { SearchBranchSelector } from 'features/searchBranchSelector';

type BranchProps = {
  setChanged: (isChanged: boolean) => void;
};

export const Branch: React.FC<BranchProps> = ({setChanged}) => {
  return (
    <Content>
      <Left>
        <SearchBranchSelector 
          title='Филиалы' 
          placeholder='Найти филиал' 
          useContext={useTabsContext} 
          setChanged={setChanged} 
        />
      </Left>
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  padding: 24px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 0 8px 8px 8px;
  margin-bottom: 90px;
  min-width: 720px;
`;

const Left = styled.div`
  width: 378px;
`;

