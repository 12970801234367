import React from 'react';
import styled from 'styled-components';

type CalendarDayGrafikHelpProps = {
  schedule: Record<string, any>;
}

export const CalendarDayGrafikHelp: React.FC<CalendarDayGrafikHelpProps> = ({schedule}) => {
  return (
    <>
      {schedule?.data?.[0]?.grafik?.time?.[0]?.from && schedule?.data?.[0]?.grafik?.time?.[0]?.to ?
        <InfoBlock>
          <p>Расписание:</p>
          <p>{schedule?.data?.[0]?.grafik?.time?.[0]?.from} - {schedule?.data?.[0]?.grafik?.time?.[0]?.to}</p>
        </InfoBlock>
        : <DangerBlock>
          <p>Выходной день</p>
        </DangerBlock>
      }
      {schedule?.data?.[0]?.grafik?.timeout?.filter((item: {from: string | null, to: string | null}) => item.from && item.to )?.length ?
        <DangerBlock>
          <p>Перерывы:</p>
          {schedule?.data?.[0]?.grafik?.timeout?.filter((item: {from: string | null, to: string | null}) =>item.from && item.to ).map?.((item: any, i: number) => 
          <p key={i}>{item.from} - {item.to}</p>)}
        </DangerBlock>
        : null
      }
    </>
  );
};

const DefaultBlock = styled.div`
  border: 1px var(--color-black-light) solid;
  width: 100%;
  padding: 4px;
  line-height: 1.2;
`;
const InfoBlock = styled(DefaultBlock)`
  border: 1px var(--color-blue) solid;
`;
const DangerBlock = styled(DefaultBlock)`
  border: 1px var(--color-red) solid;
`;