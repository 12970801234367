import React from 'react';
import styled from 'styled-components';
import { IconArrowLeft } from 'icons';
import moment from 'moment';

type GenerateDateMonthPros = {
  yearMonth: string;
  monthName: string;
  year: string | null | undefined;
  onChange: (month: string) => void;
};

export const GenerateDateMonth: React.FC<GenerateDateMonthPros> = ({ monthName, year, yearMonth, onChange}) => {
  const getPrevMonth = () => {
    return moment(yearMonth).subtract(1, 'months').startOf('month').format('YYYY-MM')
  }
  const getNextMonth = () => {
    return moment(yearMonth).add(1, 'months').endOf('month').format('YYYY-MM')
  }
  return (
    <Content>
      <WrapperIconLeft onClick={() => onChange(getPrevMonth())}>
        <IconArrowLeft />
      </WrapperIconLeft>
      <TextBlock>{monthName} {year}</TextBlock>
      <WrapperIconRight onClick={() => onChange(getNextMonth())}>
        <IconArrowLeft />
      </WrapperIconRight>
    </Content>
  );
};
const WrapperIconRight = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  svg {
    transform: rotate(180deg);
  }
`;
const WrapperIconLeft = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;
const TextBlock = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #6b6f76;
`;
const Content = styled.div`
  padding: 0 10px;
  display: flex;
  align-items: center;
`;
