import React, {useCallback} from 'react';
import { useScheduleContext } from '../context';
import { ScheduleRowTitle, ScheduleRowBlock, FlexRow } from '../styles';
import WeekDay from './weekDay';
import { ScheduleType, GrafikWeekDaySelectorProps } from '../types';

export const GrafikWeekDaySelector: React.FC<GrafikWeekDaySelectorProps> = ({timeBegin, timeEnd}) => {
  const {schedule, setSchedule} = useScheduleContext();

  const handleSetDaySelected = useCallback((dayName: string, selected: boolean): void => {
    const scheduleClone = JSON.parse(JSON.stringify(schedule));
    for (let i = 0; i < scheduleClone.length; i++) {
      if (scheduleClone[i].data[0].name === dayName) {
        scheduleClone[i].data[0].grafik.time[0].from = selected ? timeBegin : null;
        scheduleClone[i].data[0].grafik.time[0].to = selected ? timeEnd : null;
      }
    }
    setSchedule(scheduleClone);
  }, [timeBegin, timeEnd]);
  
  return (
    <ScheduleRowBlock>
      <ScheduleRowTitle>График</ScheduleRowTitle>
      <FlexRow>
        {schedule?.map?.((item: ScheduleType, i: number) => 
          <WeekDay 
            key={i}
            dayName={item?.data?.[0]?.name} 
            selected={item?.data?.[0]?.grafik?.time?.[0]?.from?.length && item?.data?.[0]?.grafik?.time?.[0]?.to?.length ? true : false}
            setSelected={handleSetDaySelected}
          />
        )}
      </FlexRow>
    </ScheduleRowBlock>
  );
};
