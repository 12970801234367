import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { IconSearch } from 'icons';
import { CheckboxService } from 'components/checkboxService';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { InputSearchLocal } from 'components/ui/inputSearchLocal';
import { TabsContextProps } from './types';
import { branchSelector, branchSlice } from 'pages/branch/branchSlice';

type SearchBranchSelectorProps = {
  title: string;
  placeholder: string;
  useContext: () => TabsContextProps | any;
  setChanged: (isChanged: boolean) => void;
};

export const SearchBranchSelector: React.FC<SearchBranchSelectorProps> = ({ title, placeholder, useContext, setChanged }) => {
  const dispatch = useAppDispatch();
  const branches = useAppSelector(branchSelector.branches);
  const [search, setSearch] = useState<string>('');
  const { state: stateTabs, setState: setStateTabs } = useContext();
  const activeBranch = useMemo(() => stateTabs?.workplace?.data?.branch || [], [stateTabs?.workplace?.data?.branch]);
  const [activeBranchId, setActiveBranchId] = useState<number | null>(null);
  const [branchesFiltered, setBranchesFiltered] = useState<any[]>([]);

  useEffect(() => {
    setBranchesFiltered(branches);
    dispatch(branchSlice.actions.getAllBranchRequest({
      payload: {
        data: {},
        success: () => {
          setActiveBranchId(activeBranch?.id || null);
          setBranchesFiltered(branches);
        }
      }
    }));    
  }, []);

  useEffect(() => {
    if (branches.length) {
      if (search.length) {
        const branchesFiltered: Record<string, any>[] = [];
        for (let i = 0; i < branches.length; i++) {
          const branch: Record<string, any> = JSON.parse(JSON.stringify(branches[i]));
          if (branch.name.toLowerCase().search(search.toLowerCase()) !== -1) {
            branchesFiltered.push(branch);
          }
        }
        setBranchesFiltered(branchesFiltered);
      } else {
        setBranchesFiltered(branches);
      }
    }
    return () => setBranchesFiltered(branches);
  }, [search, branches]);

  const handleOnChangeSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.target.value);
  };

  const handleSetActiveBranch = (id: number): void => {
    setActiveBranchId(id);
    setStateTabs?.((prev) => ({
      ...prev,
      branch: {
        ...prev?.branch,
        data: {
          ...prev?.branch?.data,
          branch: branchesFiltered.filter(item => item.id === id)?.[0] || null,
        },
      },
    }));
    setChanged(true);
  }
  
  return (
    <>
      <Head>
        <Title>{title}</Title>
      </Head>
      <WrapperInput>
        <InputSearchLocal
          icon={IconSearch}
          placeholder={placeholder}
          value={search}
          onChange={handleOnChangeSearch}
          data={search}
          isClear={true}
          onClear={() => setSearch('')}
        />
      </WrapperInput>
      {branchesFiltered.map((item) => 
        <>
          <ItemBranch key={item.id}>
            <CheckboxService
              active={activeBranchId === item.id}
              setActive={() => handleSetActiveBranch(item.id)}
              title={item.name}
            />
          </ItemBranch>
        </>)}
        {!branchesFiltered?.length && <NotFound>Ничего не найдено</NotFound>}
    </>
  );
};

const ItemBranch = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  padding: 10px 0;
  &:hover {
    background: rgba(231, 231, 231, 0.56);
  }
  &:not(:last-child) {
    border-bottom: 2px solid var(--color-grey);
  }
`;
const WrapperInput = styled.div`
  margin-bottom: 24px;
`;
const Title = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black);
`;
const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
`;
const NotFound = styled.div`
  font-weight: 300;
  font-size: 12px;
  color: var(--color-black);
`;