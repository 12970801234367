import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { IconClose, IconEmailUser, IconPlus, IconRub, IconSearch, IconUser } from 'icons';
//import { Input } from 'components/input';
import { DataServiceProps, initValidData, ItemServiceProps, Service } from './service';
import { InputCurrency } from 'components/inputCurrency';
import { useTabsContext } from '../context';
import { useSearchClients } from 'pages/kanban/hooks/useSearchClients';
import { SearchClientsSelector } from 'features/searchClientsSelector';
import { v4 as idV4 } from 'uuid';
import { formatValueNumber, valid } from 'core/utils';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { ColumnProps, kanbanSelector } from 'pages/kanban/kanbanSlice';
import { transformClient } from 'pages/kanban/hooks/helpers';
import { clientsSlice } from 'pages/clients/clientsSlice';
import { ModalChangeClient } from 'features/modals/modalChangeClient';

/*const manifestIconTitle = {
  pending: {
    icon: '/icons/kanban/pending.svg',
    title: 'В ожидании',
  },
  in_work: {
    icon: '/icons/kanban/in_work.svg',
    title: 'В работе',
  },
  completed: {
    icon: '/icons/kanban/completed.svg',
    title: 'Завершенные',
  },
  canceled: {
    icon: '/icons/kanban/canceled.svg',
    title: 'Отмененные',
  },
};*/

type EntryContentProps = {
  data?: ColumnProps;
  setChanged: (isChanged: boolean) => void;
};

const initServiceValues: DataServiceProps = {
  id: undefined,
  service_id: undefined,
  workplace_id: undefined,
  specialist_id: undefined,
  date_at: undefined,
  time_at: undefined,
  reason: undefined,
  amount: undefined,
  comment: undefined,
};
export const EntryContent: React.FC<EntryContentProps> = ({ data: dataColumn, setChanged }) => {
  const dispatch = useAppDispatch();
  const activeCard = useAppSelector(kanbanSelector.activeCard);
  const activeCardDefault = useAppSelector(kanbanSelector.activeCardDefault);
  const [services, setServices] = useState<Record<string, ItemServiceProps>>({
    [idV4()]: {
      data: initServiceValues,
      validData: initValidData,
    },
  });
  const [loading, setLoading] = useState(true);
  const [searchClientsValue, setSearchClientsValue] = useState('');
  const [validClient, setValidClient] = useState(true);
  const [showModalClient, setShowModalClient] = useState(false);
  const { state: stateTabs, setState: setStateTabs } = useTabsContext();

  const recordData = useMemo(() => stateTabs?.record?.data, [stateTabs?.record?.data]);

  const handledValidClient = (value: boolean) => {
    setValidClient(value);
  };

  const { handledOnChangeSearch, handleClickItem, activeClient, clearClient, requestData, setActiveClient } =
    useSearchClients({
      setLoading,
      setSearch: setSearchClientsValue,
      handledValidClient,
    });

  useEffect(() => {
    if (activeCard) {
      setActiveClient(transformClient(activeCard.client));
    }
  }, [activeCard]);

  useEffect(() => {
    setStateTabs?.((prev) => ({
      ...prev,
      record: {
        ...prev?.record,
        data: {
          ...prev?.record?.data,
          client_id: activeClient?.id,
          status_id: dataColumn?.id,
          status_name: dataColumn?.name,
        },
      },
    }));
  }, [activeClient, dataColumn]);

  const handledOnFocus = () => {
    setLoading(true);
  };

  const handledAddService = () => {
    setServices((prev) => ({
      ...prev,
      [idV4()]: {
        data: initServiceValues,
        validData: initValidData,
      },
    }));
    setChanged(true);
  };

  const handledRemoveService = (id: string) => () => {
    setServices((prev) =>
      Object.entries(prev).reduce((acc, [idKey, data]) => {
        if (id !== idKey) {
          return { ...acc, [idKey]: data };
        } else return acc;
      }, {}),
    );
    setChanged(true);
  };

  const handledOnChangePaid = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || valid.number(e.target.value)) {
      setStateTabs?.((prev) => ({
        ...prev,
        record: {
          ...prev?.record,
          data: {
            ...prev?.record?.data,
            paid: +e.target.value || undefined,
          },
        },
      }));
      setChanged(true);
    }
  };

  const totalSum = useMemo(
    () => Object.values(services).reduce((acc, value) => acc + (value?.data?.amount || 0), 0),
    [services],
  );

  useEffect(() => {
    //Проверка на валидацию
    const nextServices: Record<string, any> = Object.entries(services).reduce((acc, [key, service]) => {
      return {
        ...acc,
        [key]: {
          ...service,
          validData: Object.entries(service?.validData || {}).reduce((acc, [k, v]) => {
            return { ...acc, [k]: !!service?.data?.[k as keyof DataServiceProps] };
          }, {}),
        },
      };
    }, {});

    const isValidValues = Object.values(nextServices).map((item) => [...Object.values(item?.validData || {})]);

    setStateTabs?.((prev) => ({
      ...prev,
      record: {
        ...prev?.record,
        data: {
          ...prev?.record?.data,
          services: Object.values(nextServices).map((item) => item.data),
        },
        onIsValid: () => !isValidValues.flat().includes(false) && !!prev?.record?.data?.client_id,
        checkValid: () => {
          setServices(nextServices);
          setValidClient(!!prev?.record?.data?.client_id);
        },
      },
    }));
  }, [services]);

  const requestDataClient = (id?: string) => () => {
    if (id) {
      dispatch(
        clientsSlice.actions.getClientRequest({
          payload: {
            data: {
              id,
            },
            success: () => setShowModalClient(true),
          },
        }),
      );
    }
  };

  const updateRequestData = (data?: Record<string, any>) => {
    if (data) setActiveClient(transformClient(data));
  };

  return (
    <BlocContentModel>
      <Left>
        <Head>
          {activeClient ? (
            <>
              <SearchIcon>{activeClient.reduction}</SearchIcon>
              <WrapperInputs>
                <SearchInput>
                  <FullNameClientActive>{activeClient.fullName || activeClient.name}</FullNameClientActive>
                  <WrapperIcons>
                    <WrapperIconClient disabled>
                      <IconEmailUser />
                    </WrapperIconClient>
                    <WrapperIconClient onClick={requestDataClient(activeClient.id)}>
                      <IconUser />
                    </WrapperIconClient>
                    <WrapperIconClient onClick={() => {
                      clearClient();
                      setChanged(true);
                    }}>
                      <IconClose />
                    </WrapperIconClient>
                  </WrapperIcons>
                </SearchInput>
                <DataUser>
                  <WrapperInput>{activeClient?.phone || ''}</WrapperInput>
                  <WrapperInput>{activeClient?.birthday_at || ''}</WrapperInput>
                </DataUser>
              </WrapperInputs>
            </>
          ) : (
            <>
              <SearchIcon>
                <IconSearch />
              </SearchIcon>
              <WrapperInputs>
                <SearchInput>
                  <SearchClientsSelector
                    valid={validClient}
                    onFocus={handledOnFocus}
                    onBlur={() => {
                      setValidClient(!!activeClient);
                      setLoading(false);
                    }}
                    value={searchClientsValue || ''}
                    loading={loading}
                    placeholder={'Поиск клиента'}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handledOnChangeSearch(e);
                      setChanged(true);
                    }}
                    data={requestData}
                    handleClickItem={(data: Record<string, any>) => {
                      handleClickItem(data);
                      setChanged(true);
                    }}
                    isClear
                    onClear={() => {
                      clearClient();
                      setChanged(true);
                    }}
                  />
                </SearchInput>
                {/*<DataUser>
                  <WrapperInput>
                    <Input placeholder={'Телефон'} disabled />
                  </WrapperInput>
                  <WrapperInput>
                    <Input placeholder={'Дата рождения'} disabled />
                  </WrapperInput>
                </DataUser>*/}
              </WrapperInputs>
            </>
          )}
        </Head>
        {Object.entries(services).map(([id, data]) => {
          return (
            <WrapperServices key={id}>
              <Service
                serviceId={id}
                service={data}
                setServices={setServices}
                countServices={Object.keys(services).length}
                onClose={handledRemoveService(id)}
              />
            </WrapperServices>
          );
        })}
        {!activeCard && (
          <AddService onClick={handledAddService}>
            <WrapperIcon>
              <IconPlus />
            </WrapperIcon>
            <TitleService>Добавить услугу</TitleService>
          </AddService>
        )}
      </Left>
      <Right>
        <Title>Запись</Title>
        <Row>
          <TitleRow>Статус</TitleRow>
          <TypeContent>
            {/*<IconType src={manifestIconTitle['pending'].icon} />*/}
            {/*<TitleType>{manifestIconTitle['pending'].title}</TitleType>*/}
            <TitleType>{dataColumn?.name || 'Новые заявки'}</TitleType>
          </TypeContent>
        </Row>
        <Row>
          <TitleRow>Итого</TitleRow>
          <SumValue>
            <span>{formatValueNumber(totalSum || stateTabs?.record?.data?.services?.[0]?.amount)}</span> <IconRub />
          </SumValue>
        </Row>
        <Row>
          <TitleRow></TitleRow>
          <InputCurrency value={recordData?.paid} onChange={handledOnChangePaid} />
        </Row>
      </Right>
      <ModalChangeClient
        updateRequestData={updateRequestData}
        showModal={showModalClient}
        setShowModal={setShowModalClient}
      />
    </BlocContentModel>
  );
};

const WrapperServices = styled.div`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const WrapperIconClient = styled.div<{ disabled?: boolean }>`
  margin-right: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:last-child {
    margin-left: 20px;
    svg {
      path {
        stroke: #d23333;
      }
    }
  }
  ${({ disabled }) => {
    if (disabled) {
      return css`
        cursor: auto;
        background: var(--color-grey);
        svg {
          path,
          rect {
            stroke: var(--color-black-grey);
          }
        }
      `;
    }
  }}
`;
const WrapperIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
`;

const FullNameClientActive = styled.div`
  font-size: 15px;
  font-weight: 500;
`;

const SumValue = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #282a2f;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  span {
    margin-right: 5px;
  }
  svg {
    path {
      fill: #282a2f;
    }
  }
`;
const TitleType = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #282a2f;
  white-space: nowrap;
`;
const IconType = styled.img`
  margin-right: 8px;
`;
const TypeContent = styled.div`
  display: flex;
  align-items: center;
`;
const TitleRow = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #6b6f76;
`;
const Row = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 87px 1fr;
  &:not(:last-child) {
    margin-bottom: 18px;
  }
`;
const Title = styled.div`
  margin-bottom: 18px;
`;
const BlocContentModel = styled.div`
  display: grid;
  grid-template-columns: 1fr 250px;
  background: #ffffff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 0 8px 8px 8px;
  margin-bottom: 8px;
`;
const TitleService = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #6471e0;
`;
const AddService = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
`;
const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    path {
      stroke: #6471e0;
    }
  }
`;
const WrapperInput = styled.div`
  font-size: 13px;
  color: #6b6f76;
  width: 139px;
  &:not(:last-child) {
    margin-right: 8px;
  }
`;
const DataUser = styled.div`
  display: flex;
  align-items: center;
`;
const WrapperInputs = styled.div``;
const SearchInput = styled.div`
  margin-top: 5px;
  margin-bottom: 8px;
  width: 410px;
  display: flex;
`;
const Head = styled.div`
  width: 460px;
  display: flex;
  margin-bottom: 16px;
  position: relative;
`;
const SearchIcon = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  background: #eff1f4;
`;

const Left = styled.div`
  padding: 20px 16px;
`;
const Right = styled.div`
  border-left: 1px solid #eff1f4;
  padding: 18px 28px;
  height: 100%;
`;
