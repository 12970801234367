import React from 'react';
import styled from 'styled-components';

type AddButtonProps = {
  title?: string;
  onClick?: () => void;
}

export const AddButton: React.FC<AddButtonProps> = ({title, onClick}) => {
  return (
    <Content title={title} onClick={onClick}>
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.5 7V17" stroke="#6471E0" strokeWidth="1.5" strokeLinecap="round"/>
        <path d="M7.5 12L17.5 12" stroke="#6471E0" strokeWidth="1.5" strokeLinecap="round"/>
      </svg>
    </Content>
  );
};

const Content = styled.button`
  width: 100%;
  padding: 0;
  text-align: center;
  background-color: #F0F3F9;
  &:hover {
    cursor: pointer;
    opacity: .75;
  }
`;