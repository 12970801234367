import React from 'react';
import { useScheduleContext } from '../context';
import { ScheduleRow, ButtonAddRow } from '../row';
import { TimePickersRow } from '../row/timePickersRow';
import { ScheduleFromToType, ScheduleType } from '../types';

export const GrafikTimeout: React.FC<{showFirstOnly?: boolean}> = ({showFirstOnly = false}) => {
  const {schedule, setSchedule, showShifts, handleAddShift, handleRemoveShift} = useScheduleContext();  
  
  const handleSetTimeoutFrom = (number: number, dayName?: string | null, time?: string) => {
    if (schedule?.length) {
      const scheduleClone = JSON.parse(JSON.stringify(schedule));
      for (let i = 0; i < scheduleClone.length; i++) {
        if (number === i && scheduleClone[i].data[0].name === dayName) {
          scheduleClone[i].data[0].grafik.timeout[0].from = time || null; // пока только первый перерыв
        }
      }
      setSchedule(scheduleClone);
    }
  }

  const handleSetTimeoutTo = (number: number, dayName?: string | null, time?: string) => {
    if (schedule?.length) {
      const scheduleClone = JSON.parse(JSON.stringify(schedule));
      for (let i = 0; i < scheduleClone.length; i++) {
        if (number === i && scheduleClone[i].data[0].name === dayName) {
          scheduleClone[i].data[0].grafik.timeout[0].to = time || null; // пока только первый перерыв
        }
      }
      setSchedule(scheduleClone);
    }
  }
  return (
    <>
      {schedule?.filter((item: ScheduleType, i: number) => showFirstOnly ? i === 0 : true)?.map?.((item: ScheduleType, i: number) => 
        <div key={i}>
          {item?.data?.[0]?.grafik?.timeout?.length ?
          item?.data?.[0]?.grafik?.timeout?.map?.((t: ScheduleFromToType, j: number) => <>
            <TimePickersRow 
              key={j}
              number={j}
              name={item?.data?.[0]?.name}
              titleBegin='Начало'
              titleEnd='Окончание'
              begin={t?.from || undefined}
              end={t?.to || undefined}
              disabled={false}
              showSuffix={false}
              showWeekDay={!showFirstOnly}
              selectWeekDay={false}
              showShifts={showShifts}
              setBegin={(time?: string) => handleSetTimeoutFrom(i, item?.data?.[0]?.name, time)}
              setEnd={(time?: string) => handleSetTimeoutTo(i, item?.data?.[0]?.name, time)}
            />
          </>
          ) : 
          <TimePickersRow 
            key={1}
            number={1}
            name={item?.data?.[0]?.name}
            titleBegin='Начало'
            titleEnd='Окончание'
            begin={item?.data?.[0]?.grafik?.timeout?.[0]?.from || ''}
            end={item?.data?.[0]?.grafik?.timeout?.[0]?.to || ''}
            disabled={false}
            showSuffix={false}
            showWeekDay={true}
            selectWeekDay={false}
            showShifts={showShifts}
            setBegin={(time?: string) => handleSetTimeoutFrom(1, item?.data?.[0]?.name, time)}
            setEnd={(time?: string) => handleSetTimeoutTo(1, item?.data?.[0]?.name, time)}
          />}
        </div>
      )}
      {/*<ButtonAddRow onAdd={handleAddShift} title='Добавить' />*/}
    </>
  );
};
