import React, { useState, useEffect } from 'react';
import { DatePicker } from 'components/datePicker';
import { FlexColumn, FlexRow, ValidationError } from '../styles';
import { compareDates } from '../utils';
import { DateTimePickersRowProps } from '../types';

export const DatePickersRow: React.FC<DateTimePickersRowProps> = (props) => {
  const {titleBegin, titleEnd, begin, end, disabled, showSuffix, setBegin, setEnd} = props;
  const [isValid, setValid] = useState<boolean>(true);
  
  useEffect(() => {
    if (begin !== undefined && end !== undefined && begin?.length && end?.length) {
      setValid(compareDates(begin, end));
    }
    return () => setValid(true);
  }, [begin, end]);

  useEffect(() => {
    !isValid && setEnd?.('');
  }, [isValid]);

  return (
    <FlexColumn>
      <FlexRow>
        <DatePicker
          title={titleBegin}
          value={begin}
          placeholder={'дд:мм:гггг'}
          height={32}
          disabled={disabled || false}
          showSuffix={showSuffix}
          valid={isValid}
          onChange={setBegin}
        />
        <div>&ndash;</div>
        <DatePicker
          title={titleEnd}
          value={end}
          placeholder={'дд:мм:гггг'}
          height={32}
          disabled={disabled || false}
          showSuffix={showSuffix}
          valid={isValid}
          onChange={setEnd}
        />
      </FlexRow>
      {!isValid && <ValidationError>Дата окончания не может быть больше даты начала</ValidationError>}
    </FlexColumn>
  );
};
