import moment, { Moment } from 'moment';

const regxEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regxPhone = /(\+?[0-9]|\(|\)|\.|-|\+)$/;
const regexNumber = /^[0-9\b]+$/;
export const valid = {
  email: (value: string) => regxEmail.test(value),
  phone: (value: string) => regxPhone.test(value),
  number: (value: string) => regexNumber.test(value),
  empty: (value: string) => value.length > 0,
};

export const getWeekDays = (next = 0): Moment[] => {
  const numberWeek = [1, 2, 3, 4, 5, 6, 7];
  return numberWeek.map((number) => moment().isoWeekday(number + next * 7));
};

export function getMonthDays(i = 1) {
  let daysInMonth = moment(i).daysInMonth();
  const arrDays: Moment[] = [];

  while(daysInMonth) {
    const current: Moment = moment().date(daysInMonth);
    arrDays.push(current);
    daysInMonth--;    
  }

  return arrDays;
}

export const formatDateToBack = (date: any) => {
  return moment(date).format('YYYY-MM-DD');
};
export const formatDateToFront = (date: any) => {
  return moment(date).format('DD.MM.YYYY');
};

export const clearValueDate = (value: string) => value.replaceAll('_', '').replaceAll('.', '');

export const declension = (val: number, forms: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return forms[val % 100 > 4 && val % 100 < 20 ? 2 : cases[val % 10 < 5 ? val % 10 : 5]];
};

export const isNewImage = (image: any) => {
  return image?.file_path ? null : image || null;
};

export const formatValueNumber = (value?: number | string) => {
  if (typeof value === 'number') {
    return value.toLocaleString('ru');
  } else if (value && !Number.isNaN(+value)) {
    return Number(value).toLocaleString('ru');
  }
  return '';
};

export const getNameAbbr = (name: string, splitSeparator = ' ', wordsSeparator = '') => {
  if (!name?.length) return '';
  return name.split(splitSeparator).length === 1 ? 
        name.charAt(0).toUpperCase() : 
        name.split(splitSeparator).reduce(
          (acc: string, item: string) => acc.charAt(0).toUpperCase() + wordsSeparator + item.charAt(0).toUpperCase()
        );
};

export const isEmail = (value: string): boolean => regxEmail.test(value);
export const isPhone = (value: string): boolean => regexNumber.test(clearPhoneNumber(value)) && clearPhoneNumber(value).length >= 11;
export const isNumber = (value: string): boolean => regexNumber.test(value);
export const isEmpty = (value: string): boolean => value.length > 0;
export const objectClearEmpty = (obj: Record<string, any>): Record<string, any> => Object.entries(obj).reduce((a: any,[k,v]) => (v ? (a[k]=v, a) : a), {});
export const clearPhoneNumber = (phone: string): string => phone.replace(/[+()_ -]/g,'');


export const getMonthDaysByString = (month: string) => {
  return new Array(moment(month).daysInMonth()).fill(null).map((x, i) => moment(month).startOf('month').add(i, 'days'));
}