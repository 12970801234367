import React, {useState, useEffect} from 'react';
import { useScheduleContext } from '../context';
import { TimePicker } from 'components/timePicker';
import { GrafikWorkDayProps } from '../types';
import { scheduleTypes } from '../data';
import { compareTime } from '../utils';
import { ScheduleRowTitle, ScheduleRowBlock, FlexRow, ValidationError } from '../styles';

const GrafikWorkDay: React.FC<GrafikWorkDayProps> = ({timeBegin, timeEnd, setTimeBegin, setTimeEnd}) => {
  const { scheduleType } = useScheduleContext();
  const [isValid, setValid] = useState<boolean>(true);

  useEffect(() => {
    if (timeBegin !== undefined && timeEnd !== undefined && timeBegin?.length && timeEnd?.length) {
      setValid(compareTime(timeBegin, timeEnd));  
    }
  }, [timeBegin,timeEnd]);

  useEffect(() => {
    !isValid && setTimeEnd?.('');
  }, [isValid]);  

  return (
    <ScheduleRowBlock>
      <ScheduleRowTitle>Рабочий день</ScheduleRowTitle>
      <FlexRow>
      <TimePicker
          title='Начало'
          value={timeBegin || undefined}
          placeholder={'чч:мм'}
          height={32}
          disabled={scheduleType !== scheduleTypes.byWeekDays.type}
          showSuffix={false}
          valid={isValid}
          onChange={setTimeBegin}
        />
        <div>&ndash;</div>
        <TimePicker
          title='Конец смены'
          value={timeEnd || undefined}
          placeholder={'чч:мм'}
          height={32}
          disabled={scheduleType !== scheduleTypes.byWeekDays.type}
          showSuffix={false}
          valid={isValid}
          onChange={setTimeEnd}
        />
      </FlexRow>
      {!isValid && <ValidationError>Время окончания не может быть больше времени начала</ValidationError>}
    </ScheduleRowBlock>
  );
};
export default React.memo(GrafikWorkDay);