import { all } from 'redux-saga/effects';
import { getSpecialistAll } from './getSpecialistAll';
import { createSpecialist } from './postCreateSpecialist';
import { getSpecialistId } from './getSpecialistId';
import { updateSpecialist } from './postUpdateSpecialist';
import { deleteSpecialist } from './deleteSpecialist';
import { getDataTimeTable } from './getDataTImeTable';
import { searchService } from './searchService';
import { createSpecialistCalendar } from './postCreateSpecialistCalendar';
import { deleteSpecialistCalendar } from './deleteSpecialistCalendar';

export default function* specialistSaga() {
  yield all([
    getSpecialistAll(),
    createSpecialist(),
    getSpecialistId(),
    updateSpecialist(),
    deleteSpecialist(),
    getDataTimeTable(),
    searchService(),
    createSpecialistCalendar(),
    deleteSpecialistCalendar(),
  ]);
}
