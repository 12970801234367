import React from 'react';
import { ScheduleGrafikWeekDayProps } from '../types';
import { WeekDayCircle } from '../styles';

const WeekDay: React.FC<ScheduleGrafikWeekDayProps> = ({dayName, selected, setSelected}) => {
  
  const handleClick = () => {
    setSelected(dayName, !selected);
  }
  
  return (
    <WeekDayCircle 
      selected={selected} 
      selectWeekDay={true} 
      onClick={handleClick} 
      title={selected ? 'Нерабочий день' : 'Рабочий день'}
    >{dayName}</WeekDayCircle>
  );
};

export default React.memo(WeekDay);