import React, {useState, useEffect, useCallback} from 'react';
import { useScheduleContext } from '../context';
import { WeekDayProps } from '../types';
import { WeekDayCircle } from '../styles';

export const WeekDay: React.FC<WeekDayProps> = (props) => {
  const {number, name, begin, end, selectWeekDay, isValid, setBegin, setEnd} = props;
  const {defaultSchedule} = useScheduleContext();
  const [selected, setSelected] = useState<boolean>(false);
  const [defaultBegin, setDefaultBegin] = useState<string>('');
  const [defaultEnd, setDefaultEnd] = useState<string>('');
  const [title, setTitle] = useState<string>('');

  const handleClick = useCallback(() => {
    if (!selectWeekDay) return;
    if (!begin && !end) {
      setBegin?.(defaultBegin);
      setEnd?.(defaultEnd);
    } else {
      setBegin?.('');
      setEnd?.('');
    }
    setSelected(!selected);
  }, [defaultBegin, defaultEnd, selected, begin, end, selectWeekDay]);
  
  useEffect(() => {
    setSelected(begin?.length && end?.length && isValid && selectWeekDay ? true : false);
    return () => setSelected(false);
  }, [begin, end, isValid, selectWeekDay]);

  useEffect(() => {
    if (defaultSchedule?.length) {
      const grafikTime = defaultSchedule?.filter(item => item?.data?.[0]?.name === name)?.[0]?.data?.[0]?.grafik?.time?.[0];
      if (grafikTime?.from) {
        setDefaultBegin(grafikTime.from);
      }
      if (grafikTime?.to) {
        setDefaultEnd(grafikTime.to);
      }
    }
  }, [defaultSchedule]);

  useEffect(() => {
    if (!selected && defaultBegin && defaultEnd) {
      setTitle(`Рабочее время по умолчанию: ${defaultBegin} - ${defaultEnd}`);
    } else if (selected && begin && end) {
      setTitle('Нерабочий день');
    } else if (!begin && !end && selectWeekDay) {
      setTitle('Выберите время');
      setSelected(false);
    }
    return () => setTitle('');
  }, [selected, defaultBegin, defaultEnd, begin, end, selectWeekDay]);
  
  return (
    <WeekDayCircle 
      selected={selected} 
      selectWeekDay={selectWeekDay} 
      onClick={handleClick} 
      title={title}
    >{name}</WeekDayCircle>
  );
};
