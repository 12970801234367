import { BranchProps } from './types';

export const transformBranch = (data: Record<string, any>[]): BranchProps[] => {
  return data.map((item) => ({
    id: item.id,
    name: item?.name,
    address: item?.address,
    phone: item?.phone,
    description: item?.description,
    image_file: item?.image_file,
    is_active: item?.is_active,
    map_image_file: item?.map_image_file,
    ya_map: item?.ya_map,
    workplaces: item?.workplaces,
  }));
};
