import React, { useState, useEffect, useContext, useMemo } from 'react';
import styled from 'styled-components';
//import { mockData } from './mockData';
//import { ItemRowTypes } from './types';
//import { RenderRows } from './helpers';

import { useAppDispatch, useAppSelector } from 'core/hooks';
import { recordPrioritySlice, telegramSettingsServiceSelector } from 'pages/recordingPriority/recordingPrioritySlice';

import { HolderOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Table as SortableTable } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Checkbox } from 'components/checkbox';

const noop = () => undefined;

interface SequencesDataType {
  key: number;
  name: string;
  title: string | null;
  required: any | null;
  active: any | null;
}

interface RowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
}

const RowContext = React.createContext<RowContextProps>({});

const DragHandle: React.FC = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{ cursor: 'move' }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const columns: ColumnsType<SequencesDataType> = [
  { key: 'sort', align: 'center', width: 60, render: () => <DragHandle /> },
  //{ title: '№', dataIndex: 'key', align: 'center', width: 50 },
  { title: 'Название очередности', dataIndex: 'title', align: 'left', width: 'auto' },
  { title: 'Обязательный выбор', dataIndex: 'required', align: 'center', width: 200 },
  { title: 'Активный шаг', dataIndex: 'active', align: 'center', width: 200 },
];

const sequencesData: SequencesDataType[] = [];

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row: React.FC<RowProps> = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props['data-row-key'] });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  const contextValue = useMemo<RowContextProps>(
    () => ({ setActivatorNodeRef, listeners }),
    [setActivatorNodeRef, listeners],
  );

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

export const Table: React.FC = () => {
  //const [rows, setRows] = useState<Record<number, ItemRowTypes>>(mockData);//<ItemRowTypes[]>
  
  const dispatch = useAppDispatch();
  const settings = useAppSelector(telegramSettingsServiceSelector);

  const [dataSource, setDataSource] = React.useState<SequencesDataType[]>(sequencesData);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((prevState) => {
        const activeIndex = prevState.findIndex((record) => record.key === active?.id);
        const overIndex = prevState.findIndex((record) => record.key === over?.id);
        
        const moved = arrayMove(prevState, activeIndex, overIndex);
        const newSequences = {sequences: {}};
        
        for (let i = 0; i < moved.length; i++) {
          if (i === 0) newSequences.sequences[moved[i].name] = 1;
          else if (i === 1) newSequences.sequences[moved[i].name] = 3;
          else if (i === 2) newSequences.sequences[moved[i].name] = 4;
        }

        dispatch(recordPrioritySlice.actions.postUpdateTelegramSettingsSequencesRequest(newSequences));

        return moved;
      });
    }
  };
  
  useEffect(() => {
    dispatch(recordPrioritySlice.actions.getTelegramSettingsRequest());
  }, []);

  useEffect(() => {
    if (settings && settings?.sequences) {

      const service = { 
        key: settings.sequences.service, 
        name: 'service', 
        title: 'Услуга', 
        required: <Checkbox active={true} setActive={noop} />, 
        active: <Checkbox active={true} setActive={noop} /> 
      };

      const date = { 
        key: settings.sequences.date, 
        name: 'date', 
        title: 'Дата', 
        required: <Checkbox active={false} setActive={noop} />, 
        active: <Checkbox active={true} setActive={noop} /> 
      };

      const specialist = { 
        key: settings.sequences.specialist, 
        name: 'specialist', 
        title: 'Специалист', 
        required: <Checkbox active={true} setActive={noop} />, 
        active: <Checkbox active={true} setActive={noop} /> 
      };

      setDataSource([service,date,specialist].sort((a, b) => a.key - b.key));
    }
  }, [settings]);

  return (
    <Content>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext items={dataSource.map((i) => i.key)} strategy={verticalListSortingStrategy}>
          <SortableTable
            rowKey="key"
            components={{ body: { row: Row } }}
            columns={columns}
            dataSource={dataSource}
            pagination={{ position: ['none', 'none'] }}            
          />
        </SortableContext>
      </DndContext>
      {/*<br /><br />
      <Header>
        <TitleHeader>№</TitleHeader>
        <TitleHeader>Название очередности</TitleHeader>
        <TitleHeader>Обязательный выбор</TitleHeader>
        <TitleHeader>Активный шаг</TitleHeader>
      </Header>
      <RenderRows data={rows} />*/}
    </Content>
  );
};
const TitleHeader = styled.div`
  color: #6b6f76;
  font-size: 12px;
  font-weight: 400;
`;
const Header = styled.div`
  display: grid;
  grid-template-columns: 70px 1fr 180px 100px;
  grid-template-rows: 48px;
  background: #f9f9f9;
  align-items: center;
  padding: 0 15px 0 55px;
`;
const Content = styled.div`
  .ant-table-thead .ant-table-cell {
    color: rgb(107, 111, 118);
    font-size: 12px;
    font-weight: 400;
  }
`;
