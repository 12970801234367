import React from 'react';
import { useScheduleContext } from '../context';
import { ScheduleRow, ButtonAddRow } from '../row';
import { DatePickersRow } from '../row/datePickersRow';
import { ScheduleContainer, ScheduleRowTitle, ScheduleRowBlock } from '../styles';
import { ScheduleFromToType } from '../types';

export const ScheduleVocation: React.FC = () => {
  const {vocation, setVocation, handleRemoveVocation, handleAddVocation} = useScheduleContext();

  const handleSetBegin = (number: number, date?: string) => {
    if (vocation?.length) {
      const vocationClone = JSON.parse(JSON.stringify(vocation));
      for (let i = 0; i < vocationClone.length; i++) {
        if (i === number)
          vocationClone[i].from = date || null;
        console.log(i,number,date);
        
      }
      setVocation(vocationClone);
      console.log('begin', {vocationClone,date,number});
    }
  }
  const handleSetEnd = (number: number, date?: string) => {
    console.log(vocation);
    
    if (vocation?.length) {
      const vocationClone = JSON.parse(JSON.stringify(vocation));
      for (let i = 0; i < vocationClone.length; i++) {
        if (i === number)
          vocationClone[i].to = date || null;
      }
      setVocation(vocationClone);
      console.log('end', {vocationClone,date,number});
      
    }
  }

  console.log({vocation});
  

  return (
    <>
      <ScheduleContainer>
        <ScheduleRowBlock>
          <ScheduleRowTitle>Отпуск</ScheduleRowTitle>
          {vocation?.map?.((item: ScheduleFromToType, i: number) => (
            <>
              <DatePickersRow 
                number={i}
                name={'vocation'}
                titleBegin='Дата начала'
                titleEnd='Дата окончания'
                begin={item?.from || undefined}
                end={item?.to || undefined}
                disabled={false}
                showSuffix={true}
                setBegin={(date?: string) => handleSetBegin?.(i, date)}
                setEnd={(date?: string) => handleSetEnd?.(i, date)}
              />
            </>
          ))}
        </ScheduleRowBlock>
      </ScheduleContainer>
      {/*<ButtonAddRow onAdd={handleAddVocation} title='Добавить' />*/}
    </>
  );
};
