import React, { useState, useEffect, useMemo, useContext, createContext } from 'react';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { branchSelector } from 'pages/branch/branchSlice';
import { workplaceSelector } from 'pages/workplace/workplaceSlice';
import { specialistSelector } from 'pages/specialist/specialistSlice';
import { scheduleFeatureSlice } from './scheduleFeatureSlice';
import { initialState, scheduleTypes } from './data';
import { ScheduleContextProps, ScheduleContextProviderProps, ScheduleType, ScheduleFromToType } from './types';

const ScheduleContext = createContext<ScheduleContextProps | undefined>(undefined);

export const ScheduleContextProvider: React.FC<ScheduleContextProviderProps> = (props) => {
  const { children, showSwitcher, setChanged, tabsContext } = props;

  const dispatch = useAppDispatch();

  const branch = useAppSelector(branchSelector.branch);
  const workplace = useAppSelector(workplaceSelector.workplace);
  const specialist = useAppSelector(specialistSelector.specialist);

  const [schedule, setSchedule] = useState<ScheduleType[] | null>(initialState.schedule);
  const [defaultSchedule] = useState<ScheduleType[] | null>(initialState.schedule);
  const [shifts, setShifts] = useState<ScheduleFromToType[] | null>(initialState.shifts);
  const [vocation, setVocation] = useState<ScheduleFromToType[] | null>(initialState.vocation);
  const [commonWithParent, setCommonWithParent] = useState<boolean>(false);
  const [showCommonWithParent, setShowCommonWithParent] = useState<boolean>(false);
  const [scheduleType, setScheduleType] = useState<string>(scheduleTypes.byWeekDays.type);
  const [flexibleGrafik, setFlexibleGrafik] = useState<boolean>(initialState.flexibleGrafik);
  const [showShifts, setShowShifts] = useState<boolean>(false);
  const [showVocation, setShowVocation] = useState<boolean>(false);
  const [showResult, setShowResult] = useState<boolean>(false);

  const { state: stateTabs } = tabsContext();

  const handleSetByWeekDays = () => {
    setScheduleType(scheduleTypes.byWeekDays.type);
    //setShifts([0]);
  }

  const handleAddShift = () => {
    /*const _shifts: number[] = [...shifts];
    const _last: number | undefined = _shifts?.at(-1);
    if (_last !== undefined) {
      _shifts.push(_last+1);
      setShifts(_shifts);
    }*/
    //setChanged(true);
  }

  const handleRemoveShift = (i: number) => {
    /*const _shifts: number[] = [...shifts].filter(s => s !== i);
    setShifts(_shifts);*/
    //setChanged(true);
  }

  const handleAddVocation = () => {
    /*const _vocations: number[] = [...vocation];
    const _last: number | undefined = _vocations?.at(-1);
    if (_last !== undefined) {
      _vocations.push(_last+1);
      setVocation(_vocations);
    }*/
    //setChanged(true);
  }

  const handleRemoveVocation = (date: number) => {
    /*const _vocations: number[] = [...vocation].filter(s => s !== i);
    setVocation(_vocations);*/
    //setChanged(true);
  }

  const handleFlexibleGrafik = () => {
    if (scheduleType == scheduleTypes.byWeekDays.type) {
      setFlexibleGrafik(!flexibleGrafik);
    }
  }

  const handleClear = () => {
    setFlexibleGrafik(false);
    setCommonWithParent(false);
    //setShifts([0]);
    //setVocation([0]);
    //setChanged(true);
  }

  useEffect(() => {
    if (workplace?.schedule?.schedule) {
      setSchedule(workplace.schedule.schedule);
      setFlexibleGrafik(workplace.schedule.flexibleGrafik);
    } else if (specialist?.schedule?.schedule) {
      setSchedule(specialist?.schedule.schedule);
      setFlexibleGrafik(specialist.schedule.flexibleGrafik);
    } else if (branch?.schedule?.schedule) {
      setSchedule(branch.schedule.schedule);
      setFlexibleGrafik(branch.schedule.flexibleGrafik);
      setCommonWithParent(false);
    } else {      
      setSchedule(initialState.schedule);
      setFlexibleGrafik(initialState.flexibleGrafik);
      setCommonWithParent(true);
    }    
    return () => setSchedule(null);
  }, [branch, specialist, workplace, defaultSchedule]);

  useEffect(() => {
    if (schedule?.length && tabsContext && stateTabs) {
      if (stateTabs?.branch?.data) {
        stateTabs.branch.data.schedule = JSON.stringify({flexibleGrafik,schedule,vocation});
      } else if (stateTabs?.person?.data) {
        stateTabs.person.data.schedule = commonWithParent ? null : JSON.stringify({flexibleGrafik,schedule,vocation});
      } else if (stateTabs?.workplace?.data) {
        stateTabs.workplace.data.schedule = commonWithParent ? null : JSON.stringify({flexibleGrafik,schedule,vocation});
      }
    }
  }, [flexibleGrafik, schedule, tabsContext, stateTabs, commonWithParent, vocation]);

  useEffect(() => {
    if (commonWithParent) {
      if (workplace) {
        if (workplace?.branch) {
          const workplaceBranch = workplace?.branch;
          if (workplaceBranch?.schedule) {
            const workplaceBranchSchedule = JSON.parse(workplaceBranch?.schedule);
            setSchedule(workplaceBranchSchedule?.schedule);
          }
        }
      } else if (specialist) {
        if (specialist?.workplaces?.length) {
          const specialistWorkplace = specialist?.workplaces?.filter((w: any) => w?.schedule?.length)?.[0];
          if (specialistWorkplace?.schedule) {
            const specialistWorkplaceSchedule = JSON.parse(specialistWorkplace?.schedule);            
            setSchedule(specialistWorkplaceSchedule?.schedule);
          }
        }
      }
    }
  }, [commonWithParent,workplace,specialist]);

  useEffect(() => {
    if (workplace?.schedule?.vocation) {
      setVocation(workplace.schedule.vocation);
    } else if (specialist?.schedule?.vocation) {
      setVocation(specialist.schedule.vocation);
    } else if (branch?.schedule?.vocation) {
      setVocation(branch.schedule.vocation);
    } else {
      setVocation(initialState.vocation);
    }    
    return () => setVocation(null);
  }, [branch, specialist, workplace, vocation]);

  const values = useMemo(() => ({
    showSwitcher,
    stateTabs,
    schedule, 
    defaultSchedule,
    shifts, 
    vocation, 
    commonWithParent,
    showCommonWithParent,
    scheduleType,
    flexibleGrafik,
    showShifts,
    showVocation,
    showResult,
  }), [
    showSwitcher,
    stateTabs,
    schedule, 
    defaultSchedule,
    shifts, 
    vocation, 
    commonWithParent,
    showCommonWithParent,
    scheduleType,
    flexibleGrafik,
    showShifts,
    showVocation,
    showResult,
  ]);

  const setters = useMemo(() => ({
    setChanged, 
    setSchedule, 
    setShifts, 
    setVocation, 
    setCommonWithParent,
    setShowCommonWithParent,
    setScheduleType,
    setFlexibleGrafik,
    setShowShifts,
    setShowVocation,
    setShowResult
  }), [
    setChanged, 
    setSchedule, 
    setShifts, 
    setVocation, 
    setCommonWithParent,
    setShowCommonWithParent,
    setScheduleType,
    setFlexibleGrafik,
    setShowShifts,
    setShowVocation,
    setShowResult
  ]);

  const handlers = useMemo(() => ({
    handleSetByWeekDays,
    handleAddShift,
    handleRemoveShift,
    handleAddVocation,
    handleRemoveVocation,
    handleFlexibleGrafik,
    handleClear
  }), [
    handleSetByWeekDays,
    handleAddShift,
    handleRemoveShift,
    handleAddVocation,
    handleRemoveVocation,
    handleFlexibleGrafik,
    handleClear
  ]);

  useEffect(() => {
    dispatch(scheduleFeatureSlice.actions.resetSchedule());
  }, []);

  return <ScheduleContext.Provider value={{ ...values, ...setters, ...handlers }}>{children}</ScheduleContext.Provider>;
};

export const useScheduleContext = () => {
  const context = useContext(ScheduleContext);
  if (context === undefined) {
    throw new Error('useConfigState must be used within a ConfigProvider');
  }
  return context;
};
