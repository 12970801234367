import { routingApi } from 'core/apiRouting';
import { ItemNavBar } from './type';
import {
  IconAccount,
  IconCalendar,
  IconCloseNB,
  IconCRM,
  IconData,
  IconGlobal,
  IconMessage,
  IconOnline,
  IconProcent,
  IconSetting,
  IconUsersData,
} from 'icons';

export const initDataNavBar: ItemNavBar[] = [
  {
    id: 1,
    icon: IconCRM,
    title: 'Заявки',
    path: routingApi.crm.link,
    show: false,
    active: false,
    child: [],
  },
  {
    id: 2,
    icon: IconCalendar,
    title: 'Расписание',
    path: routingApi.schedule.link,
    show: false,
    active: false,
    child: [],
  },
  {
    id: 3,
    icon: IconData,
    title: 'Данные',
    path: routingApi.data.link,
    show: false,
    active: false,
    child: [
      {
        id: 31,
        title: 'Услуги',
        path: routingApi.data.services.link,
        show: false,
        active: false,
        child: [],
      },
      {
        id: 34,
        title: 'Филиалы',
        path: routingApi.data.branches.link,
        show: false,
        active: false,
        child: [],
      },
      {
        id: 33,
        title: 'Рабочее место',
        path: routingApi.data.workplace.link,
        show: false,
        active: false,
        child: [],
      },
      {
        id: 32,
        title: 'Специалисты',
        path: routingApi.data.specialists.link,
        show: false,
        active: false,
        child: [],
      },
      /*{
        id: 35,
        title: 'Каталог',
        path: routingApi.data.catalog.link,
        show: false,
        active: false,
        child: [],
      },*/
      /*{
        id: 36,
        title: 'Заявки',
        path: routingApi.data.todolist.link,
        show: false,
        active: false,
        child: [],
      },*/
    ],
  },
  {
    id: 4,
    icon: IconUsersData,
    title: 'База клиентов',
    path: routingApi.clientBase.link,
    show: false,
    active: false,
    child: [],
  },
  /*{
    id: 5,
    icon: IconProcent,
    title: 'Акции',
    path: routingApi.stock.link,
    show: false,
    active: false,
    child: [
      {
        id: 51,
        title: 'Настройка',
        path: routingApi.stock.setting.link,
        show: false,
        active: false,
        child: [],
      },
      {
        id: 52,
        title: 'История',
        path: routingApi.stock.story.link,
        show: false,
        active: false,
        child: [],
      },
    ],
  },*/
  /*{
    id: 6,
    icon: IconMessage,
    title: 'Сообщения',
    path: routingApi.messages.link,
    show: false,
    active: false,
    child: [
      {
        id: 61,
        title: 'Настройка',
        path: routingApi.messages.setting.link,
        show: false,
        active: false,
        child: [],
      },
      {
        id: 62,
        title: 'История',
        path: routingApi.messages.story.link,
        show: false,
        active: false,
        child: [],
      },
    ],
  },*/
  {
    id: 7,
    icon: IconOnline,
    title: 'Онлайн-запись',
    path: routingApi.appointment.priority.link,
    show: false,
    active: false,
    child: [],
    // child: [
    //   {
    //     id: 71,
    //     title: 'Настройка',
    //     path: routingApi.appointment.setting.link,
    //     show: false,
    //     active: false,
    //     child: [],
    //   },
    //   {
    //     id: 72,
    //     title: 'Очередность',
    //     path: routingApi.appointment.priority.link,
    //     show: false,
    //     active: false,
    //     child: [],
    //   },
    // ],
  },
  /*{
    id: 8,
    icon: IconGlobal,
    title: 'Сайт-визитка',
    path: routingApi.site.link,
    show: false,
    active: false,
    child: [],
  },
  {
    id: 9,
    icon: IconSetting,
    title: 'Общие настройки',
    path: routingApi.general.link,
    show: false,
    active: false,
    child: [
      {
        id: 91,
        title: 'Окно записи',
        path: routingApi.general.window.link,
        show: false,
        active: false,
        child: [],
      },
      {
        id: 92,
        title: 'Карточка клиента',
        path: routingApi.general.card.link,
        show: false,
        active: false,
        child: [],
      },
      {
        id: 93,
        title: 'Шаблоны',
        path: routingApi.general.templates.link,
        show: false,
        active: false,
        child: [],
      },
    ],
  },
  {
    id: 10,
    icon: IconAccount,
    title: 'Аккаунт',
    path: routingApi.account.link,
    show: false,
    active: false,
    child: [
      {
        id: 101,
        title: 'Финансы',
        path: routingApi.account.finance.link,
        show: false,
        active: false,
        child: [],
      },
      {
        id: 102,
        title: 'Помощь',
        path: routingApi.account.help.link,
        show: false,
        active: false,
        child: [],
      },
    ],
  },*/
];
