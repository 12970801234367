import React, {useState} from 'react';
import { AddFile } from 'components/addFile';
import styled from 'styled-components';

export type DocumentsProps = {
  setChanged: (isChanged: boolean) => void;
};

export const Documents: React.FC<DocumentsProps> = ({ setChanged }) => {

  const [files, setFiles] = useState<Record<string, any>[]>([]);
  const handleUpload = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFiles((prev) => [...prev, {value: e.target.value}]);
    setChanged(true);
  }
  return (
    <Content>
      <Title>Документы</Title>
      {!files.length && <AddFile title='Загрузить' onChange={handleUpload} />}
      {!!files.length &&
      <Block>
        {files.map((file: any, i: number) => <p key={i}>{file?.value}</p>)}
      </Block>}
    </Content>
  );
};

const Title = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 16px;
  padding: 24px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 0 8px 8px 8px;
  min-width: 720px;
`;
const Block = styled.div`
  width: 378px;
`;
