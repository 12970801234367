import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Header } from 'layout/header';
import { ButtonPros } from 'core/types';
import { IconCloud, IconPrinter, IconTrash } from 'icons';
import { ModalAnt } from 'components/modalAnt';
import { Table } from './table';
import { ItemTabs, Tabs } from 'components/tabs';
import { GeneralInformation } from './table/tabsModal/generalInformation';
import { History } from './table/tabsModal/history';
import { Discount } from './table/tabsModal/discount';
import { DefaultButton, IconButton, WhiteButton } from 'components/buttons';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { clientClientsSelector, clientsClientsSelector, clientsSlice, countClientsSelector } from './clientsSlice';
import { createData, dataSorting, initFilters, updateData } from './helpers';
import { MultiFilter, MultiFilterDataProps } from 'components/filters/multiFilter';
import { DataSort, Sorting } from 'components/filters/sorting';
import { InputSearch } from 'components/inputSearch';
import { PaginationBack } from 'features/paginationBack';
import { ItemFilter, NumberFiler } from 'features/numberFiler';
import { dataFilter, getRowsData, header } from './table/heplers';
import { ItemTable } from './table/types';
import { getFilterRequest } from 'components/filters/utils';
import { TabsContextProvider, useTabsContext, StateProps } from './table/tabsModal/context';
import { PopUpAnt } from 'components/popupAnt';
import debounce from 'lodash/debounce';
import config from 'api/config';
import { PreloaderLarge, Preloader } from 'components/preloader';

export type DataTabProps = {
  general?: {
    data?: Record<string, any>;
    onIsValid?: () => boolean;
  };
};

type PaginationProps = {
  page: number; 
  count: number; 
  size: number;
  onChange: (value: number) => void;
  onFilter: (d: ItemFilter) => void;
};

const Pagination: React.FC<PaginationProps> = ({page, count, size, onChange, onFilter}) => {
  return (
    <BlockPagination>
      <WrapperPagination>
        <PaginationBack page={page} count={count} size={size} onChange={onChange} />
      </WrapperPagination>
      <WrapperFilter>
        <NumberFiler title='По' data={dataFilter} onClick={onFilter} />
      </WrapperFilter>
    </BlockPagination>
  );
};

export const ClientsContent: React.FC = () => {
  const deleteId = useRef<number>();
  const dispatch = useAppDispatch();
  const client = useAppSelector(clientClientsSelector);
  const clients = useAppSelector(clientsClientsSelector);
  const count = useAppSelector(countClientsSelector);

  const [showModal, setShowModal] = useState(false);
  const [showPopUp, setShowPopUp] = useState<'save' | 'delete' | 'saveAndClose' | 'confirmToClose' | null>(null);
  const { state: stateTabs, setState: setStateTabs } = useTabsContext();

  const [checkSearch, setCheckSearch] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<MultiFilterDataProps>(initFilters);
  const [size, setSize] = useState(dataFilter[0].value);
  const [page, setPage] = useState(1);
  const [sortType, setSortType] = useState<'desc' | 'asc' | null>('desc');
  const [sort, setSort] = useState<any>('id');
  const [dataRows, setDataRows] = useState<ItemTable[][]>([]);
  const [isChanged, setChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleCloseModal = () => {
    setShowPopUp(null);
    setShowModal(false);
    setStateTabs?.({});
    dispatch(clientsSlice.actions.resetClientsActive());
    setChanged(false);
  };

  const handleRequest = useCallback(
    (search?: string) => {
      setLoading(true);
      dispatch(
        clientsSlice.actions.getAllClientsRequest({
          payload: {
            data: {
              page,
              size,
              sort,
              search: search || null,
              sortType,
              ...getFilterRequest(filters),
            },
            success: () => {
              setCheckSearch(!!search);
              setLoading(false);
            },
          },
        }),
      );
    },
    [page, size, sort, sortType],
  );

  const updatePage = (type: 'create' | 'remove') => {
    switch (type) {
      case 'create': {
        const nextValue = Math.ceil(count / size);
        setPage(nextValue);
        break;
      }
      case 'remove':
        if (dataRows.length === 1 && page !== 1) {
          setPage((prev) => prev - 1);
        } else {
          handleRequest();
          deleteId.current = undefined;
          setShowPopUp(null);
          handleCloseModal();
        }
        break;
      default:
        return;
    }
  };

  const buttons: ButtonPros[] = [
    /*{
      id: 1,
      type: 'icon',
      icon: IconCloud,
      onClick: () => console.log('print'),
    },
    {
      id: 2,
      type: 'icon',
      icon: IconPrinter,
      onClick: () => console.log('print'),
    },*/
    {
      id: 3,
      title: 'Добавить клиента',
      onClick: () => setShowModal(true),
    },
  ];

  const dataTabs: ItemTabs[] = [
    {
      label: 'Общая информация',
      content: <GeneralInformation setChanged={setChanged} />,
    },
    {
      label: 'История',
      content: <History />,
    },
    {
      label: 'Файлы',
      content: <div style={{ minWidth: '980px' }}>Файлы</div>,
    },
    {
      label: 'Скидка',
      content: <Discount />,
    },
    {
      label: 'Сообщения',
      content: <div style={{ minWidth: '980px' }}>Сообщения</div>,
    },
  ];

  useEffect(() => {
    if (client) {
      setStateTabs?.((prev) => ({ ...prev, general: { ...prev?.general, data: client } }));
    }
  }, [client]);

  useEffect(() => {
    //запрос при сортировке/пагинации
    handleRequest();
  }, [handleRequest]);

  const handleChangeRow = (id?: number, remove?: boolean) => {
    if (remove) {
      setShowPopUp('delete');
      deleteId.current = id;
    } else {
      dispatch(
        clientsSlice.actions.getClientRequest({
          payload: {
            data: { id },
            success: () => setShowModal(true),
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (clients) {
      const handleClick = (id: number) => {
        handleChangeRow(id);
      };
      const rows: ItemTable[][] = clients.map((item) => getRowsData(header, item, handleClick));
      setDataRows(rows);
    }
    return;
  }, [clients]);

  const handleClickPagination = (value: number) => {
    setPage(value);
  };

  const handleClickFilter = (d: ItemFilter) => {
    setSize(+d.value);
    setPage(1);
  };
  const handleClickOnChangeSorting = (d: DataSort | null, type?: 'desc' | 'asc' | null) => {
    if (d?.id) {
      setSort(d?.value);
    } else {
      setSort('id');
    }
    type !== undefined && setSortType(type);
  };
  const handleClickOnChangeFilter = (d: MultiFilterDataProps) => {
    setFilters(d);
  };

  const handleCloseFilters = () => {
    setPage(1);
    dispatch(
      clientsSlice.actions.getAllClientsRequest({
        payload: {
          data: {
            page: 1,
            size,
            sort,
            sortType,
            ...getFilterRequest(filters),
          },
          // success: () => setLoading(false),
        },
      }),
    );
  };

  const handleClickSave = (exit?: boolean) => {
    if (stateTabs?.general && !client) {
      //CREATE
      if (stateTabs?.general?.onIsValid?.()) {
        setLoading(true);
        dispatch(
          clientsSlice.actions.postCreateClientRequest({
            payload: {
              data: createData(stateTabs?.general?.data),
              success: () => {
                handleRequest();
                if (exit) {
                  handleCloseModal();
                } else setShowPopUp(null);
                setLoading(false);
              },
            },
          }),
        );
      }
    } else {
      //UPDATE
      if (stateTabs?.general?.onIsValid?.()) {
        setLoading(true);
        dispatch(
          clientsSlice.actions.postUpdateClientRequest({
            payload: {
              data: updateData(stateTabs?.general?.data),
              success: () => {
                handleRequest();
                if (exit) {
                  handleCloseModal();
                } else setShowPopUp(null);
                setLoading(false);
              },
            },
          }),
        );
      }
    }
  };
  const handleSave = (exit?: boolean) => () => {
    if (exit && stateTabs?.general?.onIsValid?.()) {
      //сохранить и выйти
      setShowPopUp('saveAndClose');
    } else if (stateTabs?.general?.onIsValid?.()) {
      //сохранить
      setShowPopUp('save');
    }
  };
  const handleClickDelete = () => {
    setLoading(true);
    dispatch(
      clientsSlice.actions.deleteClientRequest({
        payload: {
          data: { id: deleteId.current },
          success: () => {
            updatePage('remove');
            setLoading(false);
          },
        },
      }),
    );
  };
  const contentPopUp: Record<string, any> = {
    confirmToClose: {
      title: 'Закрыть окно?',
      onClick: () => handleCloseModal(),
    },
    save: {
      title: 'Сохранить изменения?',
      onClick: () => handleClickSave(),
    },
    saveAndClose: {
      title: 'Сохранить изменения и закрыть?',
      onClick: () => handleClickSave(true),
    },
    delete: {
      title: 'Удалить клиента?',
      onClick: () => handleClickDelete(),
    },
  };

  const handleDelete = () => {
    setShowPopUp('delete');
    deleteId.current = client?.id;
  };

  const title = client?.id ? [client?.surname, client?.name, client?.patronymic].join(' ') : 'Данные клиента';

  const showValue = (search: string) => {
    setCheckSearch(true);
    setPage(1);
    handleRequest(search);
  };

  const handledDebounce = useCallback(debounce(showValue, config.delaySearch), []);

  const handledOnChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    handledDebounce(e.target.value);
  };

  return (
    <React.Fragment>
      <Content>
        <ContentHeader>
          <Header title='База клиентов' buttons={buttons} />
          {/* ----  FILTERS ---- */}
          <FiltersBLock>
            <WrapperFilter>
              <MultiFilter onClose={handleCloseFilters} data={filters} onChange={handleClickOnChangeFilter} />
            </WrapperFilter>
            <WrapperFilter>
              <Sorting data={dataSorting} onChange={handleClickOnChangeSorting} />
            </WrapperFilter>
            <WrapperFilter>
              <InputSearch title='Поиск' placeholder='Поиск' onChange={handledOnChangeSearch} value={search} />
            </WrapperFilter>
          </FiltersBLock>
          {loading ? <div style={{ marginLeft: 24, marginTop: 24 }}><PreloaderLarge loading={loading} /></div> :
          dataRows && !!dataRows?.length ? (
            <>
              <Table data={dataRows} handleChangeRow={handleChangeRow} />
              <Pagination page={page} count={count} size={size} onChange={handleClickPagination} onFilter={handleClickFilter}
              />
            </>
          ) :  <TextNotData>{checkSearch ? 'Данные не найдены' : 'Данные отсутствуют'}</TextNotData>}
        </ContentHeader>
      </Content>
      {/* ----  MODAL ---- */}
      <ModalAnt key={String(showModal)} show={showModal} onClose={() => isChanged ? setShowPopUp('confirmToClose') : handleCloseModal()}>
        <Title>{title}</Title>
        <Tabs data={dataTabs} />
        <Footer>
          <BLockBtn>
            <WrapperButton>
              <DefaultButton onClick={handleSave()}>Сохранить</DefaultButton>
            </WrapperButton>
            <WrapperButton>
              <WhiteButton onClick={handleSave(true)}>Сохранить и выйти</WhiteButton>
            </WrapperButton>
            {loading && <Preloader loading={loading} />}
          </BLockBtn>
          {client?.id && (
            <WrapperIconButton>
              <IconButton onClick={handleDelete}>
                <IconTrash />
              </IconButton>
            </WrapperIconButton>
          )}
        </Footer>
      </ModalAnt>
      {showPopUp !== null && (
        <PopUpAnt
          visible
          title={contentPopUp[showPopUp].title}
          onClick={contentPopUp[showPopUp].onClick}
          onCancel={() => setShowPopUp(null)}
        />
      )}
    </React.Fragment>
  );
};

export const Clients: React.FC = (props) => {
  return (
    <TabsContextProvider>
      <ClientsContent {...props} />
    </TabsContextProvider>
  );
};
const TextNotData = styled.div`
  width: 100%;
  display: inline-block;
  padding: 20px;
  font-size: 14px;
  text-align: center;
`;
const WrapperPagination = styled.div`
  padding: 0 8px 0 25px;
  border-right: 1px solid var(--color-grey);
`;
const BlockPagination = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  padding-bottom: 120px;
`;
const WrapperFilter = styled.div`
  &:not(:last-child) {
    margin-right: 25px;
  }
`;
const FiltersBLock = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ContentHeader = styled.div`
  padding: 30px 30px 10px 30px;
`;

const Content = styled.div`
  width: 100%;
  padding-bottom: 80px;
`;
const WrapperIconButton = styled.div`
  button {
    width: 40px;
    height: 40px;
  }
`;
const WrapperButton = styled.div`
  button {
    height: 40px;
  }
  &:not(:last-child) {
    margin-right: 18px;
  }
`;
const BLockBtn = styled.div`
  display: flex;
  align-items: center;
`;
const Footer = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-left: 12px;
  color: var(--color-black-light);
  margin-bottom: 10px;
`;
