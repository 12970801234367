import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getMonthDays, getMonthDaysByString } from 'core/utils';
import { GenerateDateMonth } from 'features/generateDateMonth';
import { Preloader } from 'components/preloader';
import moment, { Moment } from 'moment';
import { CalendarDays } from './calendarDays';
import { WeekDays } from './weekDays';
import { ScheduleSwitcher } from 'features/schedule/switcher';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { scheduleFeatureSelector, scheduleFeatureSlice } from 'features/schedule/scheduleFeatureSlice';
import { branchSelector } from 'pages/branch/branchSlice';
import { workplaceSelector } from 'pages/workplace/workplaceSlice';
import { specialistSelector, specialistSlice } from 'pages/specialist/specialistSlice';
import { weekDays, scheduleTypes } from 'features/schedule/data';
import { scheduleCalendarSelector, scheduleCalendarSlice } from './scheduleCalendarSlice';
import { ScheduleDataType, WeekDaysType } from 'features/schedule/types';
import { SpecialistProps } from 'pages/specialist/types';
import { initialState } from './data';
import { CalendarFilter } from './calendarFilter';

type ScheduleCalendarProps = {
  showSwitcher?: boolean;
}

export const ScheduleCalendar: React.FC<ScheduleCalendarProps> = ({showSwitcher = false}) => {
  const dispatch = useAppDispatch();
  //const branches = useAppSelector(branchSelector.branches);
  //const workplaces = useAppSelector(workplaceSelector.workplaces);
  const specialists = useAppSelector(specialistSelector.specialists);
  const branch = useAppSelector(branchSelector.branch);
  const workplace = useAppSelector(workplaceSelector.workplace);
  const specialist = useAppSelector(specialistSelector.specialist);

  //const branchSchedule = useAppSelector(branchSelector.schedule);
  //const workplaceSchedule = useAppSelector(workplaceSelector.schedule);
  //const specialistSchedule = useAppSelector(specialistSelector.schedule);
  //const scheduleType = useAppSelector(scheduleFeatureSelector.type);
  const defaultSchedule = useAppSelector(scheduleFeatureSelector.schedule);
  //const calendar = useAppSelector(scheduleCalendarSelector.calendar);

  const [loading, setLoading] = useState<boolean>(false);
  //const [week, setWeek] = useState(0);
  const [months, setMonths] = useState<string[]>(['', '']); // первый - последний дни месяца
  const [days, setDays] = useState<Moment[]>([]); // Moment[]
  const [yearMonth, setYearMonth] = useState<string>(moment().format('YYYY-MM')); // 2024-11
  const [monthName, setMonthName] = useState<string>(moment().format('MMMM')); // сентябрь
  const [year, setYear] = useState<string>(moment().format('YYYY')); // 2024
  const [monthNum, setMonthNum] = useState<string>(moment().format('MM')); // 12
  
  const [branchSelected, setBranchSelected] = useState<Record<string, any> | null>(null);
  const [workplaceSelected, setWorkplaceSelected] = useState<Record<string, any> | null>(null);
  const [specialistSelected, setSpecialistSelected] = useState<Record<string, any> | null>(null);
  const [dateSelected, setDateSelected] = useState<string | null | undefined>(null);
  const [workTimeBeginSelected, setWorkTimeBeginSelected] = useState<string | null | undefined>();
  const [workTimeEndSelected, setWorkTimeEndSelected] = useState<string | null | undefined>();
  //const [scheduleSelected, setScheduleSelected] = useState<ScheduleDataType[] | null | undefined>(null);

  useEffect(() => {
    setMonths(moment.months());
    dispatch(scheduleCalendarSlice.actions.resetCalendar());
  }, []);

  useEffect(() => {
    const _days = getMonthDaysByString(yearMonth);//getMonthDays(+monthNum || 1);
    const startDate = _days[0];
    const endDate = moment(_days.at(-1));
    setDays(_days.sort((a: Moment | number, b: Moment | number) => moment(a).unix() - moment(b).unix()));
    setMonths([startDate.toString(), endDate.toString()]);
    setMonthNum(moment(yearMonth).format('MM'));
    setMonthName(moment(yearMonth).format('MMMM'));
    setYear(moment(yearMonth).format('YYYY'));
  }, [yearMonth]);

  useEffect(() => {
    const _calendar = {
      ...initialState,
        calendar: specialist?.calendar?.filter((c: any) => branchSelected ? branchSelected?.id === c?.branch_id : c) 
          || specialists.filter((s: SpecialistProps) => s?.calendar).map((s: SpecialistProps) => {
            return s.calendar; // собираем все календари всех специалистов, сортируем по дате
          }).flat()?.filter((c: any) => branchSelected ? branchSelected?.id === c?.branch_id : c).sort((a,b) => moment(a.date).unix() - moment(b.date).unix()),
        /*workplaces: specialist?.workplaces
          || specialists.filter((s: SpecialistProps) => s?.workplaces || true).map((s: SpecialistProps) => {
            return s.workplaces; // собираем все раб. места всех специалистов
          }).flat(),*/
        days: specialist && getMonthDaysByString?.(yearMonth)?.map((day: Moment) => {
          const specialistSchedule = specialist?.schedule?.schedule 
            || specialist?.workplaces?.filter((w: any) => w?.schedule)?.[0]?.schedule?.schedule
            || (specialist?.workplaces?.filter((w: any) => w?.schedule)?.[0]?.branch?.schedule
              ? JSON.parse(specialist?.workplaces?.filter((w: any) => w?.schedule)?.[0]?.branch?.schedule)?.schedule
              : null
            )
            || workplace?.schedule 
            || branch?.schedule 
            || defaultSchedule
            || null;

          return {
            yearMonth,
            date: day.format('DD.MM.YYYY'),
            dateMsec: day.unix(),
            dayNum: +day.format('DD'),
            monthNum: +day.format('MM'),
            yearNum: +day.format('YYYY'),
            weekDayNum: +moment(day).day()+1,
            weekDay: weekDays.filter((d: WeekDaysType) => d.id === +moment(day).day()+1)?.[0]?.name || null,
            branch_id: branchSelected?.id || branch?.id || null,
            branch: branchSelected || branch || null,
            workplace_id: workplaceSelected?.id || workplace?.id || null,
            workplace: workplaceSelected || workplace || null,
            //workplaces: specialist?.workplaces?.map((w: any) => {return{id: w.id, title: w.title || w.name}}),
            specialist_id: specialistSelected?.id || specialist?.id || null,
            specialist: specialistSelected || specialist || null,
            //specialists: specialists || null,
            calendar: specialist?.calendar?.filter((c: any) => c.date === day.format('YYYY-MM-DD') 
                && (branchSelected ? branchSelected?.id === c?.branch_id : c)
                && (workplaceSelected ? workplaceSelected?.id === c?.workplace_id : c)
                && (dateSelected ? moment(new Date(`${dateSelected.split('.')[2]}-${dateSelected.split('.')[1]}-${dateSelected.split('.')[0]}`)).format('YYYY-MM-DD') === c?.date : c)
                && (workTimeBeginSelected ? workTimeBeginSelected === c?.time_from : c)
                && (workTimeEndSelected ? workTimeEndSelected === c?.time_to : c)
              ) 
              || null,
            schedule: specialistSchedule,
          };
        }),
    };
    dispatch(scheduleCalendarSlice.actions.setCalendar(_calendar));
  }, [days, yearMonth, specialist, branchSelected, workplaceSelected, specialistSelected, dateSelected, workTimeBeginSelected, workTimeEndSelected]);
  
  const handleOnChange = (value: string) => {
    setYearMonth(value);
  };
  
  return (
    <Content>
      <ControlBlock>
        {showSwitcher && <ScheduleSwitcher/>}
        <GenerateDateMonth 
          yearMonth={yearMonth || ''}
          monthName={monthName || ''}
          year={year || null}
          onChange={handleOnChange}
        />
      </ControlBlock>
      <CalendarFilter 
        dateSelected={dateSelected}
        workTimeBeginSelected={workTimeBeginSelected}
        workTimeEndSelected={workTimeEndSelected}
        branchSelected={branchSelected}
        workplaceSelected={workplaceSelected}
        specialistSelected={specialistSelected}
        setBranchSelected={setBranchSelected}
        setWorkplaceSelected={setWorkplaceSelected}
        setSpecialistSelected={setSpecialistSelected}
        setDateSelected={setDateSelected}
        setWorkTimeBeginSelected={setWorkTimeBeginSelected}
        setWorkTimeEndSelected={setWorkTimeEndSelected}
      />
      <CalendarBlock>
        <WeekDays />
        <CalendarDays yearMonth={yearMonth} />
      </CalendarBlock>
      {loading && <Preloader loading={loading} />}
    </Content>
  );
};


const Content = styled.div`
  padding: 24px 20px;
  background: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 12px;
  border-radius: 8px;
  width: 100%;
`;
const ControlBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  & > *:last-child {
    justify-self: flex-end;
  }
  width: 100%;
`;
const CalendarBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap:5px;
  padding: 0;
  width: 100%;
  min-width: 800px;
  //overflow-x: auto;
  overflow-y: unset;
`;