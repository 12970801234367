import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { IconClose, IconCloseBig, IconCloseSmall, IconPlus, IconCalendar } from 'icons';
import { InputCurrency } from 'components/inputCurrency';
import { Textarea } from 'components/textarea';
import { ServiceItem } from './serviceItem';
import { SpecialistItem } from './specialistItem';
import { WorkplaceItem } from './workplaceItem';
import { Input } from 'components/input';
import { valid } from 'core/utils';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { kanbanSelector } from 'pages/kanban/kanbanSlice';
import { entrySlice } from 'features/entry/entrySlice';
import { ServiceCalendar } from './serviceCalendar';

export type DataServiceProps = {
  service_id?: string; // Услуга
  workplace_id?: number; // Место проведения
  specialist_id?: number; // Специалист
  date_at?: string; // Дата
  time_at?: string; // Время
  reason?: string; // Причина
  amount?: number; // Стоимость
  comment?: string;
  id?: string;
};

type ValidData = {
  date_at: boolean;
  time_at: boolean;
};
export type ItemServiceProps = {
  validData: ValidData;
  data?: DataServiceProps | null;
};

type ServiceProps = {
  countServices: number;
  onClose?: () => void;
  serviceId: string;
  service: ItemServiceProps;
  setServices: React.Dispatch<React.SetStateAction<Record<string, ItemServiceProps>>>;
};

export const initValidData: ValidData = {
  date_at: true,
  time_at: true,
};

export const Service: React.FC<ServiceProps> = ({ countServices, onClose, serviceId, service, setServices }) => {
  const dispatch = useAppDispatch();
  const activeCard = useAppSelector(kanbanSelector.activeCard);
  const activeCardDefault = useAppSelector(kanbanSelector.activeCardDefault);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  useEffect(() => {
    dispatch(entrySlice.actions.resetEntry());
  }, []);  

  useEffect(() => {
    if (activeCardDefault) {
      setServices?.((prev) => ({
        ...prev,
        [serviceId]: {
          ...prev[serviceId],
          data: {
            ...prev[serviceId].data,
            time_at: activeCardDefault?.time_at,
            date_at: activeCardDefault?.date_at,
          },
        },
      }));
    }
  }, [activeCardDefault]);

  useEffect(() => {
    if (activeCard) {
      setServices?.((prev) => ({
        ...prev,
        [serviceId]: {
          ...prev[serviceId],
          data: {
            ...prev[serviceId].data,
            time_at: activeCard?.time_at,
            date_at: activeCard?.date_at,
            comment: activeCard?.comment,
            amount: Number(activeCard?.amount),
            reason: activeCard?.reason,
            id: activeCard?.id,
          },
        },
      }));
    }
  }, [activeCard]);

  const handledActiveService = (data?: Record<string, any> | null) => {
    setShowCalendar(false);
    setServices?.((prev) => ({
      ...prev,
      [serviceId]: {
        ...prev[serviceId],
        data: {
          ...prev[serviceId].data,
          service_id: data?.id,
        },
      },
    }));
  };

  const handledActiveSpecialist = (data?: Record<string, any> | null) => {
    setShowCalendar(false);
    setServices?.((prev) => ({
      ...prev,
      [serviceId]: {
        ...prev[serviceId],
        data: {
          ...prev[serviceId].data,
          specialist_id: data?.id,
        },
      },
    }));
  };

  const handledActiveWorkplace = (data?: Record<string, any> | null) => {
    setServices?.((prev) => ({
      ...prev,
      [serviceId]: {
        ...prev[serviceId],
        data: {
          ...prev[serviceId].data,
          workplace_id: data?.id,
        },
      },
    }));
  };

  const onChangeCause = (e: ChangeEvent<HTMLInputElement>) => {
    setServices?.((prev) => ({
      ...prev,
      [serviceId]: {
        ...prev[serviceId],
        data: {
          ...prev[serviceId].data,
          reason: e.target.value,
        },
      },
    }));
  };
  const onChangeSum = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || valid.number(e.target.value))
      setServices?.((prev) => ({
        ...prev,
        [serviceId]: {
          ...prev[serviceId],
          data: {
            ...prev[serviceId].data,
            amount: +e.target.value,
          },
        },
      }));
  };

  const onChangeComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setServices?.((prev) => ({
      ...prev,
      [serviceId]: {
        ...prev[serviceId],
        data: {
          ...prev[serviceId].data,
          comment: e.target.value,
        },
      },
    }));
  };

  const onChangeDate = (value?: string) => {
    setServices?.((prev) => ({
      ...prev,
      [serviceId]: {
        ...prev[serviceId],
        validData: {
          ...prev[serviceId].validData,
          date_at: !!value,
        },
        data: {
          ...prev[serviceId].data,
          date_at: value,
        },
      },
    }));
  };

  const onChangeTime = (value?: string) => {
    setServices?.((prev) => ({
      ...prev,
      [serviceId]: {
        ...prev[serviceId],
        validData: {
          ...prev[serviceId].validData,
          time_at: !!value,
        },
        data: {
          ...prev[serviceId].data,
          time_at: value,
        },
      },
    }));
  };

  const dataService = useMemo<DataServiceProps | null | undefined>(() => service.data, [service.data]);  

  return (
    <Content>
      {countServices > 1 && (
        <Close onClick={onClose}>
          <IconCloseSmall />
        </Close>
      )}
      <Row>
        <Title>Услуга</Title>
        <ServiceItem getActiveData={handledActiveService} />
      </Row>
      <Row>
        <Title>Специалист</Title>
        <SpecialistItem getActiveData={handledActiveSpecialist} />
      </Row>
      <Row>
        <Title>Дата и время</Title>
        <ServiceCalendar 
          serviceId={serviceId}
          service={service}
          dataService={dataService}
          showCalendar={showCalendar}
          setShowCalendar={setShowCalendar}
          setServices={setServices}
          onChangeDate={onChangeDate}
          onChangeTime={onChangeTime}
        />
      </Row>
      <Row>
        <Title>Место</Title>
        <WorkplaceItem getActiveData={handledActiveWorkplace} />
      </Row>
      <Row>
        <Title>Причина</Title>
        <WrapperInputCause>
          <Input value={dataService?.reason || ''} onChange={onChangeCause} isClear />
        </WrapperInputCause>
      </Row>
      <Row>
        <Title>Стоимость</Title>
        <WrapperCurrency>
          <InputCurrency value={Number(dataService?.amount) || ''} onChange={onChangeSum} />
        </WrapperCurrency>
      </Row>
      <TextareaBlock>
        <WrapperTextarea>
          <Textarea
            value={dataService?.comment || ''}
            onChange={onChangeComment}
            placeholder={'Комментарий к услуге'}
          />
        </WrapperTextarea>
      </TextareaBlock>
      {/*!addComment ? (
        <Comment>
          <WrapperPlus onClick={() => setAddComment(true)}>
            <IconPlus />
          </WrapperPlus>
          <TitleComment>Комментарий к услуге</TitleComment>
        </Comment>
      ) : (
        <TextareaBlock>
          <WrapperTextarea>
            {dataService?.comment?.length ? (
              <Clear
                onClick={() => {
                  setServices?.((prev) => ({
                    ...prev,
                    [serviceId]: {
                      ...prev[serviceId],
                      data: {
                        ...prev[serviceId].data,
                        comment: undefined,
                      },
                    },
                  }));
                }}
              >
                <IconCloseBig />
              </Clear>
            ) : null}
            <Textarea
              value={dataService?.comment || ''}
              onChange={onChangeComment}
              placeholder={'Комментарий к услуге'}
            />
          </WrapperTextarea>
          <WrapperCloseIcon onClick={() => setAddComment(false)}>
            <IconClose />
          </WrapperCloseIcon>
        </TextareaBlock>
      )*/}
    </Content>
  );
};

const TextareaBlock = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-top: 15px;
`;

/*const WrapperCloseIcon = styled.div`
  margin-left: 10px;
  cursor: pointer;
  svg {
    path {
      stroke: #d23333;
    }
  }
`;*/
const WrapperInputCause = styled.div`
  /*width: 220px;*/
`;
/*const Clear = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 999;
  cursor: pointer;
`;*/
const WrapperTextarea = styled.div`
  position: relative;
  width: 100%;
`;
/*const TitleComment = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #282a2f;
`;
const Comment = styled.div`
  display: flex;
  align-items: center;
`;
const WrapperPlus = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    path {
      stroke: #6471e0;
    }
  }
`;*/
const WrapperCurrency = styled.div`
  width: 76px;
`;
const Title = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #6b6f76;
`;
const Close = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;
`;
const Row = styled.div`
  min-height: 32px;
  display: grid;
  grid-template-columns: 138px 1fr;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 3px;
  }
`;
const Content = styled.div`
  position: relative;
  padding: 14px 20px;
  border: 1px solid #dfe1e4;
  border-radius: 16px;
  input {
    height: 32px;
    padding: 6px 12px;
  }
`;
