import React, {useState, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import { WorkplaceProps } from './types';
import { useTabsContext } from '../context';
import { BranchTree } from 'features/branchTree';

export const Workplace: React.FC<WorkplaceProps> = ({ setChanged }) => {
  const { state: stateTabs, setState: setStateTabs } = useTabsContext();
  const activeWorkplaces = useMemo(() => stateTabs?.person?.data?.workplaces || [], [stateTabs?.person?.data?.workplaces]);
  const [activeWorkplacesIds, setActiveWorkplacesIds] = useState<any[]>([]);

  useEffect(() => {
    setActiveWorkplacesIds([...new Set(activeWorkplaces.map((item: Record<string, any>) => item.id))]);
  }, [activeWorkplaces]);

  return (
    <Content>
      <Block>
        <BranchTree 
          activeWorkplacesIds={activeWorkplacesIds}
          setActiveWorkplacesIds={setActiveWorkplacesIds}
          setChanged={setChanged}
        />
      </Block>
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  gap: 64px;
  padding: 24px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 0 8px 8px 8px;
  min-width: 1164px;
`;
const Block = styled.div`
  width: 378px;
`;
