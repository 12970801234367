import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useScheduleContext } from '../context';
import { TimePickersRow } from './timePickersRow';
import { DatePickersRow } from './datePickersRow';
import { ButtonAddRow } from './buttonAddRow';
import { ButtonRemoveRow } from './buttonRemoveRow';
import { ButtonClear } from './buttonClear';
import { ScheduleRowProps } from '../types';

const ScheduleRow: React.FC<ScheduleRowProps> = (props) => {
  const { showShifts, schedule, setSchedule } = useScheduleContext();
  const {
    type, 
    number, 
    name, 
    itemsCount, 
    titleBegin, 
    titleEnd, 
    disabled, 
    showAdd = false, 
    showSuffix, 
    showWeekDay, 
    selectWeekDay,  
    grafikTime, 
    onDelete
  } = props;

  const [timeBegin, setTimeBegin] = useState<string | undefined>('');
  const [timeEnd, setTimeEnd] = useState<string | undefined>('');
  const [showAdditionalRow, setShowAdditionalRow] = useState<boolean>(false);
  const [dateBegin, setDateBegin] = useState<string | undefined>('');
  const [dateEnd, setDateEnd] = useState<string | undefined>('');  

  useEffect(() => {
    if (grafikTime?.from) {
      setTimeBegin(grafikTime.from)
    }
    if (grafikTime?.to) {
      setTimeEnd(grafikTime.to)
    }
  }, [grafikTime]);

  const handleAddAdditionalRow = () => {
    setShowAdditionalRow(true);
  }

  const handleDeleteAdditionalRow = () => {
    setShowAdditionalRow(false);
  }

  useEffect(() => {
    if (schedule?.length) {
      const scheduleClone = JSON.parse(JSON.stringify(schedule));
      for (let i = 0; i < scheduleClone.length; i++) {
        const scheduleName = scheduleClone[i]?.data?.[0]?.name;
        if (scheduleName === name) {
            scheduleClone[i].data[0].grafik.time[0].from = timeBegin?.length ? timeBegin : null;
            scheduleClone[i].data[0].grafik.time[0].to = timeEnd?.length ? timeEnd : null;
        }
      }
      setSchedule(scheduleClone);
    }
  }, [timeBegin,timeEnd]);


  return (
    <Content>
      {<InputsBlock>
        {type === 'time' && 
          <TimePickersRow 
            number={number}
            name={name}
            titleBegin={titleBegin}
            titleEnd={titleEnd}
            begin={timeBegin}
            end={timeEnd}
            disabled={disabled || false}
            showSuffix={showSuffix}
            showWeekDay={showWeekDay || false}
            selectWeekDay={selectWeekDay || false}
            showShifts={showShifts}
            setBegin={setTimeBegin}
            setEnd={setTimeEnd}
          />
        }
        {type === 'date' && 
          <DatePickersRow 
            number={number}
            name={name}
            titleBegin={titleBegin}
            titleEnd={titleEnd}
            begin={dateBegin}
            end={dateEnd}
            disabled={disabled || false}
            showSuffix={showSuffix}
            setBegin={setDateBegin}
            setEnd={setDateEnd}
          />
        }
        {itemsCount && itemsCount > 1 && <ButtonRemoveRow number={number} onDelete={onDelete} />}
        {type === 'time' && !!showAdd && showAdditionalRow && <ButtonAddRow onAdd={handleAddAdditionalRow} />}
        {type === 'time' && !!showAdd && !showAdditionalRow && <ButtonRemoveRow number={number} onDelete={handleDeleteAdditionalRow} />}
      </InputsBlock>}
    </Content>
  );
};

export {ScheduleRow, ButtonAddRow, ButtonClear};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  width: 100%;
`;
const InputsBlock = styled.div`
  display: flex!important;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  & input[type=time]:disabled {color: var(--color-white);}
  & input[type=date]:disabled {color: var(--color-white);}
`;