import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { getTwoWeekDays } from './lib';
import { DateDay } from 'components/ui/date';
import moment from 'moment';
import 'moment/locale/ru';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { IconCloseSmall } from 'icons';
import { TimeEntry } from 'features/timeEntry';
import { specialistSelector, specialistSlice } from 'pages/specialist/specialistSlice';
import { serviceIdSelector } from 'pages/service/serviceSlice';
import { formatDateToBack } from 'core/utils';
import { setDate, entrySelector, entrySlice } from 'features/entry/entrySlice';
import { scheduleCalendarSelector } from 'features/scheduleCalendar/scheduleCalendarSlice';
import { Preloader } from 'components/preloader';

moment.locale('ru');

type CalendarDaysProps = {
  setShowCalendar: (show: boolean) => void;
  changeDate: (value?: string) => void;
  changeTime: (value?: string) => void;
};

const getDateFormatted = (date: string | null | undefined): string | null => {
  const dateSplitted: string[] | null | undefined = date?.split?.('.');
  const day: string | null = dateSplitted?.length ? dateSplitted[0] : null;
  const month: string | null = dateSplitted?.length ? dateSplitted[1] : null;
  const year: string | null = dateSplitted?.length ? dateSplitted[2] : null;
  return day && month && year ? `${year}-${month}-${day}` : null;
}

export const CalendarDays: React.FC<CalendarDaysProps> = ({ setShowCalendar, changeDate, changeTime }) => {
  const dispatch = useAppDispatch();
  const refBlock = useRef<HTMLDivElement>(null);
  const refLastItem = useRef<HTMLDivElement>(null);
  const dates = getTwoWeekDays();
  const serviceId = useAppSelector(serviceIdSelector);
  const specialistId = useAppSelector(specialistSelector.specialistId);
  const specialist = useAppSelector(specialistSelector.specialist);
  const specialistSchedule = useAppSelector(specialistSelector.schedule);
  const calendar = useAppSelector(scheduleCalendarSelector.calendar);
  const entry = useAppSelector(entrySelector.entry);
  const date_at = useAppSelector(entrySelector.date_at);
  const time_at = useAppSelector(entrySelector.time_at);

  const [activeDayNum, setActiveDayNum] = useState<number>(+moment().format('D'));
  const [activeDayString, setActiveDayString] = useState<string>(moment().format('DD.MM.YYYY').toString());
  const [activeMonth, setActiveMonth] = useState<string>(moment().format('MMM'));
  const [activeYear, setActiveYear] = useState<string>(moment().format('YYYY'));
  const [activeWeekDay, setActiveWeekDay] = useState<number>(+moment().day());
  const [loading, setLoading] = useState<boolean>(false);
  const [schedule, setSchedule] = useState<Record<string, any>[] | null>(null);
  const [selectedDaySchedule, setSelectedDaySchedule] = useState<{from: string | null, to: string | null} | null>(null);
  const [timeData, setTimeData] = useState<{id: number, label: string}[]>([]);

  useEffect(() => {
    if (date_at) {
      const date_formatted = getDateFormatted(date_at);
      setActiveDayNum(+moment(date_formatted).format('D'));
      setActiveMonth(moment(date_formatted).format('MMM'));
      setActiveYear(moment(date_formatted).format('YYYY'));
      setActiveDayString(moment(date_formatted).format('DD.MM.YYYY').toString());
      setActiveWeekDay(+moment(date_formatted).day());
      dispatch(entrySlice.actions.setDate(moment(date_formatted).format('DD.MM.YYYY').toString()));
    } else {
      setActiveDayNum(+moment().format('D'));
      setActiveMonth(moment().format('MMM'));
      setActiveYear(moment().format('YYYY'));
      setActiveDayString(moment().format('DD.MM.YYYY').toString());
      setActiveWeekDay(+moment().day());
      dispatch(entrySlice.actions.setDate(moment().format('DD.MM.YYYY').toString()));
    }
    return () => {
      dispatch(entrySlice.actions.resetEntry());
    }
  }, [date_at]);

  useEffect(() => {
    if (calendar) {
      setSelectedDaySchedule(
        calendar?.days?.
          filter?.((day: any) => day.date === activeDayString)?.[0]?.schedule?.
          filter?.((s: any) => s.week === activeWeekDay)?.[0]?.data?.[0]?.grafik?.time?.[0]
        || null
      );
    }
    return () => {
      setSelectedDaySchedule(null);
    }
  }, [activeWeekDay, activeDayString, calendar]);

  useEffect(() => {
    if (selectedDaySchedule) {
      const fromHours = selectedDaySchedule?.from?.split(':')?.[0];
      const fromMinutes = selectedDaySchedule?.from?.split(':')?.[1];
      const toHours = selectedDaySchedule?.to?.split(':')?.[0];
      const toMinutes = selectedDaySchedule?.to?.split(':')?.[1];
      const _timeData: {id: number, label: string}[] = [];
      if (fromHours && toHours) {
        let id = 1;
        let i = +fromHours;
        while (i <= +toHours) {
          _timeData.push({
            id,
            label: i !== +toHours ? `${i < 10 ? '0'+`${i}` : i}:${fromMinutes}` : `${toHours}:${toMinutes}`
          });
          i++;
          id++;
        }
      }
      setTimeData(_timeData);
    } else {
      setTimeData([]);
    }
    return () => {
      setTimeData([]);
    }
  }, [selectedDaySchedule]);

  useEffect(() => {
    if (serviceId && specialistId) {
      setLoading(true);
      dispatch(
        specialistSlice.actions.getScheduleRequest({
          specialist_ids: specialistId.toString(),
          group_by: 'specialist_id',
          group_by_sub: 'date_at',
          begin_at: formatDateToBack(dates[0]),
          end_at: formatDateToBack(dates[dates.length-1]),
        }),
      );
      dispatch(
        specialistSlice.actions.getSpecialistIdRequest({
          payload: {
            data: { id: specialistId },
            success: () => {
              setLoading(false);
            },
          },
        }),
      );
    }
    return () => {
      dispatch(specialistSlice.actions.resetData());
    };
  }, [serviceId, specialistId]);

  useEffect(() => {
    if (specialistId && specialistSchedule) {
      if (Object?.keys(specialistSchedule)?.length) {
        setSchedule(
          Object
            .values(Object.keys(specialistSchedule)
            .map(key => specialistSchedule[key])?.[0])
            .flat()
            .filter((item: any) => item?.specialist?.specialist_id === specialistId)
            .map((item: any) => {
              return {
                id: item.id,
                service_id: item?.service?.service_id,
                specialist_id: item?.specialist?.specialist_id,
                date_at: item.date_at,
                time_at: item.time_at,
              };
            })
        );
      }
    }
    return () => {
      setSchedule(null);
    };
  }, [specialistSchedule, specialistId]);

  const weekStart = moment().startOf('isoWeek').format('DD.MM.YYYY');
  const weekEnd = moment(moment().startOf('isoWeek')).add(10, 'days').format('DD.MM.YYYY');

  /*console.log('CalendarDays',{
    date_at,
    date_at_formatted: getDateFormatted(date_at), 
    entry,
    weekStart,
    weekEnd,
    activeDayNum,
    activeMonth,
    activeYear,
    selectedDaySchedule,
    calendar,
    dates,
    schedule,
    specialist,
    specialistSchedule,
    specialistId,
    timeData,
    activeDayString,
    activeWeekDay,
  });*/

  return (
    <WrapperContent onBlur={() => setShowCalendar(false) }>
      <Month>{activeMonth.charAt(0).toUpperCase() + activeMonth.slice(1)} {activeYear}</Month>
      <Content ref={refBlock}>
        {dates.map((item, index) => {
          return (
            <WrapperItem
              ref={(ref) => {
                if (index === dates.length) {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  refLastItem.current = ref;
                }
              }}
              key={item.toISOString()}
            >
              <DateDay 
                date={item} 
                activeDayNum={activeDayNum} 
                setActiveDayNum={setActiveDayNum} 
                changeDate={changeDate} 
                setLoading={setLoading}
              />
            </WrapperItem>
          );
        })}
      </Content>
      {loading
        ? <Preloader loading={loading} /> 
        : (
            timeData?.length 
            ? <TimeEntry 
                setShowCalendar={setShowCalendar} 
                changeTime={changeTime} 
                timeData={timeData} 
                schedule={schedule}
              />
            : <TimeEntryEmpty>Выходной день</TimeEntryEmpty>
          )
      }
      <Close onClick={() => setShowCalendar(false) }><IconCloseSmall/></Close>
    </WrapperContent>
  );
};

const WrapperContent = styled.div`
  position: absolute;
  top: 35px;
  right: -10px;
  z-index: 98;
  background-color: var(--color-white);
  border-radius: 0;
  padding: 10px;
  max-width: 440px;
  min-height: 192px;
  border: 1px var(--color-grey) solid;
  border-radius: 8px;
  box-shadow: 2px 6px 4px rgba(0,0,0,0.15);
`;
const Month = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
  padding-left: 5px;
`;
const WrapperItem = styled.div`
  &:not(:last-child) {
    margin-right: 5px;
  }
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  /* хром, сафари */
  &::-webkit-scrollbar {
    width: 0;
  }
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
`;
const Close = styled.button`
  position: absolute;
  right: 5px;
  top: 8px;
  z-index: 99;
`;
const TimeEntryEmpty = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 6px;
  padding: 6px;
  width: 100%;
  height: max-content;
  border: 1px var(--color-grey) solid;
`;