import { all } from 'redux-saga/effects';
import { getBranchAll } from './getBranchAll';
import { getBranchId } from './getBranchId';
import { deleteBranch } from './deleteBranch';
import { createBranch } from './createBranch';
import { updateBranch } from './updateBranch';
import { getDataTimeTable } from './getDataTImeTable';
import { searchService } from './searchService';

export default function* branchSaga() {
  yield all([
    getBranchAll(),
    createBranch(),
    getBranchId(),
    updateBranch(),
    deleteBranch(),
    getDataTimeTable(),
    searchService(),
  ]);
}
