import React from 'react';
import styled from 'styled-components';
import { TimePicker } from 'components/timePicker';

type CalendarDayGrafikTimeProps = {
  timeFrom?: string | null; 
  timeTo?: string | null; 
  setTimeFrom: (time?: string) => void; 
  setTimeTo: (time?: string) => void; 
}

export const CalendarDayGrafikTime: React.FC<CalendarDayGrafikTimeProps> = ({timeFrom, timeTo, setTimeFrom, setTimeTo}) => {
  return (
    <Content>
      <TimePicker
        title={''}
        value={timeFrom || ''}
        placeholder={'чч:мм'}
        height={26}
        disabled={false}
        showSuffix={false}
        valid={true}
        onChange={(from?: string) => setTimeFrom(from)}
      />
      <div>&ndash;</div>
      <TimePicker
        title={''}
        value={timeTo || ''}
        placeholder={'чч:мм'}
        height={26}
        disabled={false}
        showSuffix={false}
        valid={true}
        onChange={(to?: string) => setTimeTo(to)}
      />
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3px;
  width: 100%;
`;