import React, { useState } from 'react';
import styled from 'styled-components';
import { ItemTable, HistoryTable } from './types';
import { IconArrowsGrey, IconArrowsGreyAsc, IconArrowsGreyDesc } from 'icons';

type HeaderTableProps = {
  grid: string;
  data: ItemTable[] | HistoryTable[];
  children?: React.ReactNode;
  setSort: (value:string) => void;
  setSortType?: (type?: 'desc' | 'asc' | null | undefined) => void;
  sortType?: 'desc' | 'asc' | null | undefined;
  sort?: string | 'id';

};

export const HeaderTable: React.FC<HeaderTableProps> = ({ grid, data , setSort, setSortType, sortType, sort}) => {  
  const handleClickSort = (item) => {
    if (item?.id) {
      setSort(item?.key);
    } else {
      setSort('id');
    }
    if(sortType == 'asc'){
        setSortType?.('desc');
    } else {
      setSortType?.('asc');
    }
  };
  return (
    <Content grid={grid}>
      {data.map((item: ItemTable) => {
        let IconFilter;
        if (sort === item.key) {
          IconFilter = sortType === 'asc' ? IconArrowsGreyAsc : IconArrowsGreyDesc;
        } else {
          IconFilter = IconArrowsGrey;
        }
        return (
          <Item key={item.id}><ShellFilter onClick={() => handleClickSort(item)}>{item.value}<IconFilterShell><IconFilter/></IconFilterShell></ShellFilter></Item>
        )
      })}
    </Content>
  );
};

const IconFilterShell = styled.span`
height: 12px;
  & svg{
    stroke-width: 2px;
  }
`;

const ShellFilter = styled.span`
  vertical-align: sub;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
`;
const Item = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: var(--color-table-title);
  line-break: normal;
  word-break: break-word;
  hyphens: auto;
`;

const Content = styled.div<{ grid: string }>`
  gap: 10px;
  background: var(--color-table-header-bg);
  display: grid;
  justify-content: space-between;
  grid-template-columns: ${({ grid }) => grid};
  padding: 15px 30px;
  @media (min-width: 768px) and (max-width: 1024px) {
    justify-content: stretch;
    align-content: space-between;
    justify-items: stretch;
    align-items: stretch;
    padding: 10px;
  }
  @media (min-width: 768px) and (max-width: 820px) {
    grid-template-columns: 10% 10% 8% 8% 8% 8% 8% 8% 8% auto;
  }
  @media (min-width: 820px) and (max-width: 1024px) {
    grid-template-columns: 12% 12% 8% 8% 10% 10% 10% 8% 8% auto;
  }
`;
