import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Input } from 'components/input';
import { InputMack } from 'components/inputMask';
import { Textarea } from 'components/textarea';
import { AddPhoto } from 'features/addPhoto';
import { useAppSelector } from 'core/hooks';
import { branchSelector } from '../branchSlice';
import { useTabsContext } from '../context';
import { transformDataSearch } from 'pages/specialist/utils';

const initValidData: Record<string, boolean> = {
  name: true,
  address: true,
  phone: true,
};

type AboutBranchProps = {
  setChanged: (isChanged: boolean) => void
};

export const AboutBranch: React.FC<AboutBranchProps> = ({ setChanged }) => {
  const branch = useAppSelector(branchSelector.branch);
  const [validData, setValidData] = useState<Record<string, boolean>>(initValidData);
  const { state: stateTabs, setState: setStateTabs } = useTabsContext();
  const data = useMemo(() => stateTabs?.branch?.data, [stateTabs?.branch?.data]);

  useEffect(() => {
    setStateTabs?.((prev) => ({
      ...prev,
      branch: {
        ...prev?.branch,
        onIsValid: () => {
          const newValid = Object.entries(validData).reduce(
            (acc, [key, _]) => ({ ...acc, [key]: !!prev?.branch?.data?.[key] }),
            {},
          );
          setValidData(newValid);
          return !Object.entries(newValid)
            .map(([_, item]) => item)
            .includes(false);
        },
      },
    }));
  }, [validData, setValidData, data]);

  useEffect(() => {
    if (branch) {
      setStateTabs?.((prev) => ({
        ...prev,
        branch: {
          ...prev?.branch,
          data: {
            ...branch,
            services: transformDataSearch(branch?.services as Record<string, any>[], true) || [],
          },
        },
      }));
    }
  }, [branch]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setStateTabs?.((prev) => ({
      ...prev,
      branch: {
        ...prev?.branch,
        data: {
          ...prev?.branch?.data,
          [e.target.name]: e.target.value,
        },
      },
    }));

    if (Object.keys(validData).includes(e.target.name)) {
      setValidData({ ...validData, [e.target.name]: true });
    }
    setChanged(true);
  };

  const handleOnChangePhoto = (file: Record<string, any>) => {
    setStateTabs?.((prev) => ({
      ...prev,
      branch: {
        ...prev?.branch,
        data: {
          ...prev?.branch?.data,
          image_file: file,
        },
      },
    }));
    setChanged(true);
  };

  const handleOnChangeMapPhoto = (file: Record<string, any>) => {
    setStateTabs?.((prev) => ({
      ...prev,
      branch: {
        ...prev?.branch,
        data: {
          ...prev?.branch?.data,
          map_image_file: file,
        },
      },
    }));
    setChanged(true);
  };

  return (
    <Content>
      <Left>
        <Title>Данные</Title>
        <WrapperInput>
          <Input
            onChange={handleOnChange}
            value={data?.name || ''}
            valid={validData.name}
            name={'name'}
            placeholder={'Название филиала'}
          />
        </WrapperInput>
        <WrapperInput>
          <Input
            onChange={handleOnChange}
            value={data?.address || ''}
            valid={validData.address}
            name={'address'}
            placeholder={'Адрес'}
          />
        </WrapperInput>
        <WrapperInput>
          <InputMack
            mask='+7 (999) 999-99-99'
            valid={validData.phone}
            placeholder='Телефон'
            value={data?.phone}
            name={'phone'}
            //required={true}
            //invalidText='Неверный формат телефона'
            onChange={handleOnChange}
          />
        </WrapperInput>
        <WrapperInput>
          <Textarea
            onChange={handleOnChange}
            value={data?.ya_map || ''}
            name={'ya_map'}
            placeholder={'Код Яндекс.Карты'}
          />
        </WrapperInput>
        <WrapperInput>
          <Textarea
            onChange={handleOnChange}
            value={data?.description || ''}
            name={'description'}
            placeholder={'Описание'}
          />
        </WrapperInput>
      </Left>
      <Right>
        <RightBlock>
          <Title>Фотография</Title>
          <AddPhoto
            onChange={handleOnChangePhoto}
            id='image_file'
            urlImg={data?.image_file?.file_path}
            title='Загрузить'
            height={148}
            width={148}
          />
        </RightBlock>
        <RightBlock>
          <Title>Фотография карты</Title>
          <AddPhoto
            onChange={handleOnChangeMapPhoto}
            id='map_image_file'
            urlImg={data?.map_image_file?.file_path}
            title='Загрузить'
            height={148}
            width={300}
          />
        </RightBlock>
      </Right>
    </Content>
  );
};
const WrapperInput = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;
const Left = styled.div`
  width: 320px;
  margin-right: 40px;
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 340px;
`;
const RightBlock = styled.div`
  padding: 0;
  margin: 0;
`;
const Title = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 16px;
`;

const Content = styled.div`
  padding: 24px 20px;
  background: #ffffff;
  display: flex;
  align-items: flex-start;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 0 8px 8px 8px;
`;
