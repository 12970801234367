import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
//import { PlusInput } from 'features/plusInput';
import { SearchSelectorDefault } from 'features/searchSelectorDefault';
import { useSearch } from 'pages/kanban/hooks/useSearch';
import { requestsSearch } from 'api/search';
//import { IconClose } from 'icons';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { kanbanSelector } from 'pages/kanban/kanbanSlice';
import { serviceIdSelector } from 'pages/service/serviceSlice';
import { specialistSelector, specialistSlice } from 'pages/specialist/specialistSlice';
import { entrySlice } from 'features/entry/entrySlice';


type SpecialistItemProps = {
  getActiveData?: (data?: Record<string, any> | null) => void;
};

export const SpecialistItem: React.FC<SpecialistItemProps> = ({ getActiveData }) => {
  const dispatch = useAppDispatch();
  const activeCard = useAppSelector(kanbanSelector.activeCard);
  const serviceId = useAppSelector(serviceIdSelector);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchSpecialistValue, setSearchSpecialistValue] = useState('');

  const [search, setSearchInput] = useState<string>('');
  const [servicesFiltered, setServicesFiltered] = useState<any[]>([]);
  const [services, setServices] = useState<any[]>([]);
  const [hasSearched, setHasSearched] = useState<boolean>(false);
  //const [searchServiceValue, setSearchServiceValue] = useState('');

  const { handledOnChangeSearch, handleClickItem, activeItem, clearItem, requestData, setActiveItem } = useSearch({
    setLoading: setLoading,
    setSearch: setSearchSpecialistValue,
    request: (search?: string, size?: number) => 
      requestsSearch
        .get_specialist({
          title: search || null,
          size: size || null,
          // service_id: serviceId || activeCard?.service?.service_id || null,
        })
        .then((res: any) => {
          if (res?.data) {
            setLoading(false);
            if (res?.data?.data) {              
              const spec = res?.data?.data.map((item: Record<string, any>) => ({
                id: item.id,
                name: [item?.name, item?.surname, item.patronymic].filter(Boolean).join(' '),
                service_list: item.services.map((serviceItem: Record<string, any>) => serviceItem.id)
              }));
              setServices(spec);
              if(serviceId != null && serviceId != undefined){
                const new_spec: Record<string, any>[] = []; 
                spec.forEach((item) => {
                  if(item.service_list.includes(serviceId)){
                    new_spec.push(item);
                  }
                });
                setServicesFiltered(new_spec);
              } else {
                setServicesFiltered(spec);
              }    
              return spec;
            }
          }
        })
  });
  
  useEffect(() => {
    getActiveData?.(activeItem);
    dispatch(specialistSlice.actions.setSpecialistId(activeItem?.id));
  }, [activeItem]);

  useEffect(() => {
    if (activeCard) {
      const specialist = activeCard?.specialist;      
      specialist &&
        setActiveItem({
          id: specialist?.id || specialist?.specialist_id,
          name: [specialist?.name || '', specialist?.surname || '', specialist.patronymic || '']
            .filter(Boolean)
            .join(' '),
        });
    }
  }, [activeCard]);


  const handleOnChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    return filterList(e.target.value);
  };
  
  const filterList = (search_val = '') => {
    if (search_val) {     
      const _servicesFiltered: Record<string, any>[] = [];       
      services.forEach((item) => {
        if (item?.name?.toLowerCase().search(search_val?.toLowerCase()) !== -1) {
            if(serviceId != null && serviceId != undefined){
              if(item.service_list.includes(serviceId)){
                _servicesFiltered.push(item);
              }
            } else {
              _servicesFiltered.push(item);
            }
        }
      });
      setServicesFiltered(_servicesFiltered);
    } else {
      if(serviceId != null && serviceId != undefined){
        const spec_for_service: Record<string, any>[] = []; 
        services.forEach((item) => {
          if(item.service_list.includes(serviceId)){
            spec_for_service.push(item);
          }
        });
        setServicesFiltered(spec_for_service);
      } else {
        setServicesFiltered(services);
      }
    }
    
    return servicesFiltered;
  };

  //console.log('SpecialistItem',{serviceId,activeCard,activeItem,specialist_id: activeCard?.specialist?.specialist_id});
  return (
    <Content>
      <SearchSelectorDefault
        onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (!hasSearched) {
            clearItem();
            dispatch(specialistSlice.actions.setSpecialistId(null));
            setLoading(true);
            handledOnChangeSearch(e);
            setHasSearched(true);
          } else {
            filterList();
          }
        }}
        onBlur={() => setLoading(false)}
        value={activeItem?.name || search || ''}
        loading={loading}
        valid={searchSpecialistValue || activeItem?.name ? true : false}
        onChange={handleOnChangeSearch}
        data={servicesFiltered}
        handleClickItem={(item) => {
          handleClickItem(item);
          dispatch(specialistSlice.actions.setSpecialistId(item?.id));
        }}
        isClear
        onClear={() => {
          clearItem();
          dispatch(specialistSlice.actions.setSpecialistId(null));
          dispatch(entrySlice.actions.resetEntry());
        }}
      />
      {/* <SearchSelectorDefault
        onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (!hasSearched) {
            clearItem();
            dispatch(specialistSlice.actions.setSpecialistId(null));
            setLoading(true);
            handledOnChangeSearch(e);
          } else {
            filterList();
          }
        }}
        onBlur={() => setLoading(false)}
        value={searchSpecialistValue || activeItem?.name || ''}
        loading={loading}
        valid={searchSpecialistValue || activeItem?.name ? true : false}
        onChange={(e) => {
          handledOnChangeSearch(e);
          if (e.target.value === '') {
            dispatch(specialistSlice.actions.setSpecialistId(null));
            dispatch(entrySlice.actions.resetEntry());
          }
        }}
        data={requestData}
        handleClickItem={(item) => {
          handleClickItem(item);
          dispatch(specialistSlice.actions.setSpecialistId(item?.id));
        }}
        isClear
        onClear={() => {
          clearItem();
          dispatch(specialistSlice.actions.setSpecialistId(null));
          dispatch(entrySlice.actions.resetEntry());
        }}
      /> */}
      {/*activeItem ? (
        <WrapperTitle>
          <Title>{activeItem.name}</Title>
          <WrapperIcon onClick={clearItem}>
            <IconClose />
          </WrapperIcon>
        </WrapperTitle>
      ) : (
        <>
          <PlusInput
            component={
              <SearchSelectorDefault
                onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setLoading(true);
                  handledOnChangeSearch(e);
                }}
                onBlur={() => setLoading(false)}
                value={searchSpecialistValue || ''}
                loading={loading}
                onChange={handledOnChangeSearch}
                data={requestData}
                handleClickItem={handleClickItem}
                isClear
                onClear={clearItem}
              />
            }
          />
        </>
      )*/}
    </Content>
  );
};

const Content = styled.div``;
/*const Title = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black-light);
  //white-space: nowrap;
  //overflow: hidden;
  //text-overflow: ellipsis;
  width: 220px;
`;
const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
`;
const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  cursor: pointer;
  svg {
    path {
      stroke: #d23333;
    }
  }
`;*/
