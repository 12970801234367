import { getApi } from './index';
import configUrl from './config';
import Cookies from 'js-cookie';
import { AxiosInstance } from 'axios';

type BodySearchService = {
  title: string | null;
  size?: number;
};
type BodySearchSpecialist = {
  title: string | null;
  size?: number;
  service_id?: string | number;
};

type BodySearchWorkplace = {
  title: string | null;
  size?: number;
};

const config = (baseURL = configUrl.baseUrl) => {
  return {
    baseURL: `${baseURL}/api/v1/`,
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Cookies.get('TOKEN') || ''}`,
    },
  };
};

const Methods = (instance: AxiosInstance) => ({
  /**
   * Методы для kanban карточки с услугами
   * @param search
   */
  get_all_client(search: string) {
    return instance.get(`client/getAll?search=${search}`, config());
  },
  get_search_services(body: BodySearchService) {
    let url = `service/search`;
    if (body?.title === null || body?.title?.trim() === '') {
      url = `service/search?size=${body?.size ? body?.size : 10}`;
    } else {
      url = `service/search?title=${body?.title}`;
    }
    return instance.get(url, config());
  },
  get_specialist(body: BodySearchSpecialist) {
    let url = `specialist/getAll`;    
    if (body?.title === null || body?.title?.trim() === '') {
      url = `specialist/getAll?size=${body?.size ? body?.size : 10}`;
      if (body?.service_id) {
        url = `specialist/getAll?service_id=${body?.service_id.toString()}`;
      }
    } else {
      url = `specialist/getAll?search=${body?.title}`;
      if (body?.service_id) {
        url = `specialist/getAll?service_id=${body?.service_id.toString()}&search=${body?.title}`;
      }
    }
    return instance.get(url, config());
  },
  get_workplace(body: BodySearchWorkplace) {
    let url = `workplace/getAll`;
    if (body?.title === null || body?.title?.trim() === '') {
      url = `workplace/getAll?size=${body?.size ? body?.size : 10}`;
    } else {
      url = `workplace/getAll?title=${body?.title}`;
    }
    return instance.get(url, config());
  },
});

export const requestsSearch = getApi(Methods);
