import React from 'react';
import { ScheduleRow } from '../row';
import { scheduleTypes } from '../data';
import { useScheduleContext } from '../context';
import { ScheduleShiftsType, ScheduleFromToType } from '../types';

export const ScheduleShifts: React.FC<ScheduleShiftsType> = ({type, titleBegin, titleEnd, showSuffix}) => {
  const {shifts, scheduleType, handleRemoveShift} = useScheduleContext();
  return (
    <>
    {shifts?.map?.((item: ScheduleFromToType, i: number) => (
      <ScheduleRow 
        key={i}
        type={type}
        number={i}
        itemsCount={shifts?.length}
        disabled={scheduleType !== scheduleTypes.byShifts.type}
        showSuffix={showSuffix || false}
        grafikTime={{from: item?.from, to: item?.to}}
        titleBegin={titleBegin}
        titleEnd={titleEnd}
        onDelete={handleRemoveShift}
      />
    ))}
    </>
  );
};
