import React from 'react';
import { ButtonLink } from 'components/buttons';

type buttonClearProps = {
  disabled?: boolean;
  onClear?: () => void;
};

export const ButtonClear: React.FC<buttonClearProps> = ({disabled, onClear}) => {
  return (
      <ButtonLink onClick={onClear} disabled={disabled || false}>Сбросить</ButtonLink>
  );
};
