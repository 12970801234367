import React, { useCallback, useEffect, useState } from 'react';
import styled, {css} from 'styled-components';
import { IconSearch, IconArrowDown } from 'icons';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { CheckboxService } from 'components/checkboxService';
import { InputSearchLocal } from 'components/ui/inputSearchLocal';
import { branchSlice, branchSelector } from 'pages/branch/branchSlice';

type BranchTreeProps = {
  activeWorkplacesIds: number[];
  setActiveWorkplacesIds: (ids: number[]) => void;
  setChanged?: (isChanged: boolean) => void;
};

export const BranchTree: React.FC<BranchTreeProps> = (props) => {
  const {activeWorkplacesIds, setActiveWorkplacesIds, setChanged} = props;
  const dispatch = useAppDispatch();
  const branches = useAppSelector(branchSelector.branches);
  const [search, setSearch] = useState<string>('');
  const [openBranchIds, setOpenBranchIds] = useState<number[]>([]);
  const [activeBranchesIds, setActiveBranchesIds] = useState<number[]>([]);
  const [branchesFiltered, setBranchesFiltered] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    dispatch(branchSlice.actions.getAllBranchRequest({
      payload: {
        data: {},
        success: () => {
          setBranchesFiltered(branches.filter(b => b.workplaces.length));
        }
      }
    }));
  }, []);  

  useEffect(() => {
    const _branches: Record<string, any>[] = branches.filter(b => b.workplaces.length);
    if (_branches.length) {
      if (search.length) {
        const branchesFiltered: Record<string, any>[] = [];
        for (let i = 0; i < _branches.length; i++) {
          const branch: Record<string, any> = JSON.parse(JSON.stringify(_branches[i]));
          branch.workplaces.length = 0;
          for (let j = 0; j < _branches[i].workplaces.length; j++) {
            if (_branches[i].workplaces[j].title.toLowerCase().search(search.toLowerCase()) !== -1) {
              branch.workplaces.push(_branches[i].workplaces[j]);
            }
          }
          if (branch.name.toLowerCase().search(search.toLowerCase()) !== -1 || branch.workplaces.length) {
            branchesFiltered.push(branch);
          }
        }
        setBranchesFiltered(branchesFiltered);
      } else {
        setBranchesFiltered(_branches);
      }
    }
    return () => setBranchesFiltered(_branches);
  }, [search, branches]);

  const handleOnChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSelectBranch = (branch: Record<string, any>) => {
    let branchIds: number[] = activeBranchesIds;
    let workplaceIds: number[] = activeWorkplacesIds;
    if (activeBranchesIds.includes(branch.id)) {
      branchIds = activeBranchesIds.filter((i: number) => i !== branch.id);
      branch?.workplaces?.forEach((w: Record<string, any>) => {
        workplaceIds = workplaceIds.filter((i: number) => i !== w.id);
      });
    } else {
      branchIds.push(branch.id);
      branch?.workplaces?.forEach((w: Record<string, any>) => {
        workplaceIds.push(w.id);
      });
    }
    setActiveBranchesIds([...new Set([...branchIds])]);
    setActiveWorkplacesIds([...new Set([...workplaceIds])]);
    setChanged?.(true);   
  }

  const handleSelectWorkplace = (workplace: Record<string, any>) => {
    let workplaceIds: number[] = activeWorkplacesIds;
    if (activeWorkplacesIds.includes(workplace.id)) {
      workplaceIds = activeWorkplacesIds.filter((id: number) => id !== workplace.id);
    } else {
      workplaceIds.push(workplace.id);
    }
    workplaceIds = [...new Set([...workplaceIds])];
    setActiveWorkplacesIds(workplaceIds);
    if (!workplaceIds.length) {
      setActiveBranchesIds([]);
    }
    setChanged?.(true);
  }

  const handleShowWorkplaces = (branch_id: number) => {
    if(openBranchIds.includes(branch_id)) {
      setOpenBranchIds((prev) => prev.filter(id => id !== branch_id));
    } else {
      setOpenBranchIds((prev) => [...prev, branch_id]);
    }
  }

  const getSelectedWorkplacesCount = useCallback((branch_id: number) => {
    const branch: Record<string, any> = branches.filter(b => b.workplaces.length && b.id === branch_id)?.[0];
    const branchWorkplaces = branch.workplaces.filter(w => activeWorkplacesIds.includes(w.id)) || [];    
    return branchWorkplaces.length;
  }, [branches, activeWorkplacesIds]);  
  
  return (
    <>
      <Head>
        <Title>Рабочее место</Title>
      </Head>
      <WrapperInput>
        <InputSearchLocal
          icon={IconSearch}
          placeholder={'Найти рабочее место'}
          value={search}
          onChange={handleOnChangeSearch}
          data={search}
          isClear={true}
          onClear={() => setSearch('')}
        />
      </WrapperInput>
      {branchesFiltered.map((branch) => 
        <Branches key={branch.id}>
          <ItemBranch>
            <WrapperIconArrowDown onClick={() => handleShowWorkplaces(branch.id)} show={openBranchIds.includes(branch.id) || search.length > 0 || getSelectedWorkplacesCount(branch.id) > 0}>
              <IconArrowDown />
            </WrapperIconArrowDown>
            <CheckboxService
              active={activeBranchesIds.includes(branch.id) || branches.filter(b => b.id === branch.id && b.workplaces.length && b.workplaces.filter(w => activeWorkplacesIds.includes(w.id)).length).length > 0}
              setActive={() => handleSelectBranch(branch)}
              title={branch.name}
            />
            <ItemCounters>
              <span>{getSelectedWorkplacesCount(branch.id)}</span>/<span>{branch.workplaces.length}</span>
            </ItemCounters>
          </ItemBranch>
          {(openBranchIds.includes(branch.id) || search.length > 0 || getSelectedWorkplacesCount(branch.id) > 0) && branch.workplaces.map((workplace: Record<string, any>) => 
            <ItemWorkplace key={workplace.id}>
              <CheckboxService
                active={activeWorkplacesIds.includes(workplace.id)}
                setActive={() => handleSelectWorkplace(workplace)}
                title={workplace.title}
              />
            </ItemWorkplace>
          )}
        </Branches>)}
    </>
  );
};

const Branches = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  width: 100%;
  border-bottom: 2px solid var(--color-grey);
  &:last-child {
    border-bottom: 0;
  }
`;
const ItemBranch = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  padding: 10px 0;
  width: 100%;
  &:hover {
    background: rgba(231, 231, 231, 0.56);
  }
  &:not(:last-child) {
    border-bottom: 2px solid var(--color-grey);
  }
`;
const ItemWorkplace = styled(ItemBranch)`
  padding-left: 39px;
`;
const ItemCounters = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2px;
  justify-self: flex-end;
  margin-left: auto;
  margin-right: 5px;
  font-size: 12px;
`;
const WrapperInput = styled.div`
  margin-bottom: 24px;
`;
const WrapperIconArrowDown = styled.div<{ show: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.33s ease-in-out;
  cursor: pointer;
  ${({ show }) => {
    if (show) {
      return css`
        svg {
          transform: rotate(-90deg);
          transition: all 0.33s ease-in-out;
        }
      `;
    } else {
      return css`
        transform: rotate(0deg);
      `;
    }
  }}
`;
const Title = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
`;
const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
`;