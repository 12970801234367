import createRequestRest from 'api/createRequestRest';
import { put } from 'redux-saga/effects';
import { recordPrioritySlice } from '../recordingPrioritySlice';

function* prepareRequest(data: any) {
  return data;
  yield;
}

function* prepareSuccess(response: any, data: any) {
  data?.success?.();
  yield put(recordPrioritySlice.actions.postUpdateTelegramSettingsSequencesSuccess(response));

}

function* prepareFailure(res: any) {
  yield;
}

export const updateTelegramSettingsSequences = () => {
  return createRequestRest({
    url: '/api/v1/telegram/settings/sequences/set',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: recordPrioritySlice.actions.postUpdateTelegramSettingsSequencesRequest,
    method: 'post',
  });
};
