import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ScheduleDefaultLayout} from './layout';
import { ScheduleSpecialistLayout } from './layout/specialist';
import { ScheduleProps } from './types';
import { ScheduleContextProvider } from './context';

export const Schedule: React.FC<ScheduleProps> = ({showSwitcher = false, setChanged, tabsContext}) => {
  const location = useLocation();
  const [path, setPath] = useState(location.pathname);

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  return (
    <ScheduleContextProvider showSwitcher={showSwitcher} setChanged={setChanged} tabsContext={tabsContext}>
      {path === '/data/specialists' ? <ScheduleSpecialistLayout /> : <ScheduleDefaultLayout />}
    </ScheduleContextProvider>
  );
};