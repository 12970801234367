import React, { useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { HeadColumn } from './headColumn';
import { KartProps } from '../types';
import { AddKart } from './addKart';
import { ItemTabs, Tabs } from 'components/tabs';
import { EntryContent } from '../tabsContent/entryContent';
import { ModalAnt } from 'components/modalAnt';
import { DefaultButton, IconButton } from 'components/buttons';
import { useTabsContext, StateProps } from '../tabsContent/context';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { ColumnProps, kanbanSelector, kanbanSlice } from '../kanbanSlice';
import { ItemKart } from './itemKart';
import { IconTrash } from 'icons';
import { PopUpAnt } from 'components/popupAnt';
import { Preloader } from 'components/preloader';

type ItemColumnProps = {
  item: ColumnProps;
  items: KartProps[];
};
const noop = () => undefined;
export const ItemColumn: React.FC<ItemColumnProps> = ({ item, items }) => {  
  const dispatch = useAppDispatch();
  const activeCard = useAppSelector(kanbanSelector.activeCard);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showPopUp, setShowPopUp] = useState<'save' | 'delete' | 'confirmToClose' | null>();
  const { state: stateTabs, setState: setStateTabs } = useTabsContext();
  const [isChanged, setChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const tabs: ItemTabs[] = [
    {
      label: 'Запись',
      content: <EntryContent data={item} setChanged={setChanged} />,
    },
    // {
    //   label: 'Заказ',
    //   content: <OrderContent />,
    // },
  ];

  const handledClickClose = () => {
    setShowModal(false);
    setShowPopUp(null);
    dispatch(kanbanSlice.actions.resetActiveCard());
    setStateTabs?.((prev) => ({
      ...prev,
      record: {
        ...prev?.record,
        data: null,
      },
    }));
    setChanged(false);
  };

  const handledClickAdd = () => {
    if (stateTabs?.record?.onIsValid?.()) {
      dispatch(
        kanbanSlice.actions.postCreateTodoListRequest({
          payload: {
            data: stateTabs?.record?.data,
            success: (res: Record<string, any>) => {
              if (res.message?.type !== 'error') {
                handledClickClose();
                dispatch(kanbanSlice.actions.getAllTodoListRequest());
              }
            },
          },
        }),
      );
    } else {
      stateTabs?.record?.checkValid?.();
    }
  };

  const getKard = (id: string) => {
    //
    dispatch(
      kanbanSlice.actions.getCardTodoListRequest({
        payload: {
          data: { id },
          success: () => {
            setShowModal(true);
          },
        },
      }),
    );
  };

  const requestSave = () => {
    setLoading(true);
    dispatch(
      kanbanSlice.actions.postUpdateCardTodoListRequest({
        payload: {
          data: {
            status_name: stateTabs?.record?.data?.status_name,
            client_id: stateTabs?.record?.data?.client_id,
            status_id: stateTabs?.record?.data?.status_id,
            ...stateTabs?.record?.data?.services?.[0],
          },
          success: (res: Record<string, any>) => {
            if (res.message?.type !== 'error') {
              dispatch(kanbanSlice.actions.getAllTodoListRequest());
              setShowPopUp(null);
              setChanged(false);
              setLoading(false);
            }
          },
        },
      }),
    );
  };
  const handledClickSave = () => {
    //
    if (stateTabs?.record?.onIsValid?.()) {
      setShowPopUp('save');
    } else {
      stateTabs?.record?.checkValid?.();
    }
  };

  const handedClickRemove = () => {
    setLoading(true);
    dispatch(
      kanbanSlice.actions.postDeleteCardTodoListRequest({
        payload: {
          data: { id: activeCard?.id },
          success: () => {
            dispatch(kanbanSlice.actions.getAllTodoListRequest());
            handledClickClose();
            setLoading(false);
          },
        },
      }),
    );
  };

  const contentPopUp = {
    confirmToClose: {
      title: 'Закрыть окно?',
      onClick: () => handledClickClose(),
    },
    save: {
      title: 'Сохранить изменения?',
      onClick: () => requestSave(),
    },
    delete: {
      title: 'Удалить карточку?',
      onClick: () => handedClickRemove(),
    },
  };

  return (
    <>
      <Content>
        <HeadColumn item={item} count={items?.length} onClick={handleShowModal} />
        <Droppable key={item.prefix} droppableId={item?.id?.toString()} type={'card'}>
          {(provided, snapshot) => {
            return (
              <>
                <WrapperColumn ref={provided.innerRef} {...provided.droppableProps}>
                  {items?.map((kart, index) => (
                    <WrapperItem key={kart.id.toString()}>
                      <ItemKart data={kart} index={index} getKard={getKard} />
                    </WrapperItem>
                  ))}
                  <AddKart onClick={handleShowModal} />
                </WrapperColumn>
              </>
            );
          }}
        </Droppable>
      </Content>
      {showModal && (
        <ModalAnt show={true} onClose={() => isChanged ? setShowPopUp('confirmToClose') : handledClickClose()}>
          <Tabs data={tabs} />
          <FooterBtn>
            <BLockBtn>
              {activeCard?.id ? (
                <>
                  <WrapperButton>
                    <DefaultButton onClick={handledClickSave}>Сохранить</DefaultButton>
                  </WrapperButton>
                </>
              ) : (
                <>
                  <WrapperButton>
                    <DefaultButton onClick={handledClickAdd}>Добавить прием</DefaultButton>
                  </WrapperButton>
                </>
              )}
            {loading && <Preloader loading={loading} />}
            </BLockBtn>
            {activeCard?.id && (
              <WrapperIconButton>
                <IconButton onClick={() => setShowPopUp('delete')}>
                  <IconTrash />
                </IconButton>
              </WrapperIconButton>
            )}
          </FooterBtn>
        </ModalAnt>
      )}
      {!!showPopUp && (
        <PopUpAnt
          visible
          title={contentPopUp[showPopUp].title}
          onClick={contentPopUp[showPopUp].onClick}
          onCancel={() => setShowPopUp(null)}
        />
      )}
    </>
  );
};
const WrapperIconButton = styled.div`
  button {
    width: 40px;
    height: 40px;
    color: #6b6f76;
  }
  &:not(:last-child) {
    margin-right: 10px;
  }
`;
const WrapperButton = styled.div`
  button {
    height: 40px;
  }
  &:not(:last-child) {
    margin-right: 18px;
  }
`;
const BLockBtn = styled.div`
  display: flex;
  align-items: center;
`;
const FooterBtn = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
`;

const WrapperColumn = styled.div`
  height: 100%;
`;
const WrapperItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;
const Content = styled.div`
  background: #f4f5f8;
  border-radius: 8px;
  padding: 8px;
  height: 100%;
  width: 100%;
`;
