import React from 'react';
import styled from 'styled-components';
import { IconFile } from 'icons';

type AddFileProps = {
  title?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const AddFile: React.FC<AddFileProps> = ({ title, onChange }) => {
  return (
    <Content>
      <IconFile />
      <Label htmlFor='input_file'>{title}</Label>
      <Input id='input_file' type={'file'} onChange={onChange} />
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  border: 1px #DFE1E4 solid;
  border-radius: 8px;
  padding: 1em;
  width: 148px;
  height: 148px;
`;

const Label = styled.label`
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  border-radius: 5px;
  opacity: 1;
  max-height: 30px;
  color: var(--color-blue);
  transition: all 0.33s ease;
  &:hover {
    opacity: 0.8;
  }
`;
const Input = styled.input`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
`;
