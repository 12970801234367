import React, {useState} from 'react';
import styled from 'styled-components';
import { Header } from 'layout/header';
import { ItemCardInfo } from 'components/itemCardInfo';
import moment from 'moment';
import { Moment } from 'moment/moment';
import { declension } from 'core/utils';
import { ModalAnt } from 'components/modalAnt';
import { ScheduleCalendar } from 'features/scheduleCalendar';

type RowProps = {
  dates?: Moment[];
  specialist?: React.ReactNode;
  data?: Record<string, any>;
};

export const Row: React.FC<RowProps> = ({ specialist, dates, data }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showPopUp, setShowPopUp] = useState<'delete' | 'save' | 'confirmToClose' | null>();
  const [isChanged, setChanged] = useState<boolean>(false);
  const [active, setActive] = useState<number>(0);
  const formatDate = (date?: Moment) => moment(date).format('YYYY-MM-DD');

  const handleCloseModal = () => {
    setShowModal(false);
    setShowPopUp(null);
    setChanged(false);
  };

  return (
    <>
    <Content>
      <Specialist>{specialist}</Specialist>
      <Body>
        {dates &&
          dates?.map((i: Moment) => {
            if (data && data[formatDate(i)]) {
              const count = data[formatDate(i)]?.length;
              return (
                <Item key={i?.toString()} onClick={() => setShowModal(true)}>
                  <ItemCardInfo
                    active={true}
                    info={`${count} ${declension(count, ['запись', 'записи', 'записей'])}`}
                    // time={'09:00 \u2013 18:00'}
                    time={''}
                  />
                </Item>
              );
            } else return <Empty key={i?.toString()} />;
          })}
      </Body>
    </Content>
    <ModalAnt key={showModal?.toString()} show={showModal} onClose={() => isChanged ? setShowPopUp('confirmToClose') : handleCloseModal()}>
      <ModalHeader>
        <Header title={'Специалист'} />
      </ModalHeader>
      <ScheduleCalendar showSwitcher={true} />
    </ModalAnt>
    </>
  );
};
const Empty = styled.div`
  &:first-child {
    border-left: 1px solid #eff1f4;
  }
  &:not(:last-child) {
    border-right: 1px solid #eff1f4;
  }
`;
const Body = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
`;
const Content = styled.div`
  display: grid;
  grid-template-columns: 265px 1fr;
  border-bottom: 1px solid #eff1f4;
`;
const Specialist = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 0 8px 24px;
`;
const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  &:hover {
    cursor: pointer;
  }
  &:first-child {
    border-left: 1px solid #eff1f4;
  }
  &:not(:last-child) {
    border-right: 1px solid #eff1f4;
  }
`;
const ModalHeader = styled.div`
  padding: 10px 0;
`;
