import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { Input } from 'components/input';
import { Textarea } from 'components/textarea';
import { AddPhoto } from 'features/addPhoto';

import { useAppSelector } from 'core/hooks';
import { specialistSelector } from '../specialistSlice';
import { InputMack } from 'components/inputMask';
import { valid } from 'core/utils';
import { useTabsContext } from './context';
import { transformDataSearch } from '../utils';

type ColorProps = {
  id: number;
  active: boolean;
  color: string;
  value: string;
};
const dataColors: ColorProps[] = [
  {
    id: 0,
    active: false,
    color: '#D8615C',
    value: 'red',
  },
  {
    id: 1,
    active: false,
    color: '#FFD0A5',
    value: 'orange',
  },
  {
    id: 2,
    active: false,
    color: '#FFE7A5',
    value: 'yellow',
  },
  {
    id: 3,
    active: false,
    color: '#4A9B6C',
    value: 'green',
  },
  {
    id: 4,
    active: false,
    color: '#8C96F0',
    value: 'blue',
  },
  {
    id: 5,
    active: false,
    color: '#C0A3EF',
    value: 'purple',
  },
  {
    id: 6,
    active: false,
    color: '#EFF1F4',
    value: 'grey',
  },
];

const initValidData: Record<string, boolean> = {
  timetable_color: true,
  surname: true,
  name: true,
  patronymic: true,
  phone: true,
  email: true,
};

type PersonalDataProps = {
  setChanged: (isChanged: boolean) => void;
};

export const PersonalData: React.FC<PersonalDataProps> = ({ setChanged }) => {
  const specialist = useAppSelector(specialistSelector.specialist);
  const [validData, setValidData] = useState<Record<string, boolean>>(initValidData);
  const [colorData, setColorData] = useState<ColorProps[]>(dataColors);
  const { state: stateTabs, setState: setStateTabs } = useTabsContext();
  const data = useMemo(() => stateTabs?.person?.data, [stateTabs?.person?.data]);

  useEffect(() => {
    if (specialist) {
      setStateTabs?.((prev) => ({
        ...prev,
        person: {
          ...prev?.person,
          data: {
            ...specialist,
            services: transformDataSearch(specialist?.services as Record<string, any>[], true) || [],
          },
        },
      }));
      if (specialist?.timetable_color) {
        setColorData(
          colorData.map((i) =>
            i.value === specialist.timetable_color ? { ...i, active: true } : { ...i, active: false },
          ),
        );
      }
    }
  }, [specialist]);

  const handleOnChangePhoto = (dataPhoto: string) => {
    setStateTabs?.((prev) => ({
      ...prev,
      person: {
        ...prev?.person,
        data: {
          ...prev?.person?.data,
          image: dataPhoto,
        },
      },
    }));
    setChanged(true);
  };

  useEffect(() => {
    setStateTabs?.((prev) => ({
      ...prev,
      person: {
        ...prev?.person,
        onIsValid: () => {
          const newValid = Object.entries(validData).reduce((acc, [key, _]) => ({ ...acc, [key]: !!data?.[key] }), {});
          setValidData(newValid);
          return !Object.entries(newValid)
            .map(([_, item]) => item)
            .includes(false);
        },
      },
    }));
  }, [validData, setValidData, data]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setStateTabs?.((prev) => ({
      ...prev,
      person: {
        ...prev?.person,
        data: {
          ...prev?.person?.data,
          [e.target.name]: e.target.value,
        },
      },
    }));
    if (Object.keys(validData).includes(e.target.name)) {
      setValidData({ ...validData, [e.target.name]: true });
    }
    setChanged(true);
  };

  const handleClickColor = (id: number) => () => {
    setColorData(colorData.map((i) => (i.id === id ? { ...i, active: true } : { ...i, active: false })));
    const findColor = colorData?.find((i) => i.id === id);
    if (findColor) {
      setStateTabs?.((prev) => ({
        ...prev,
        person: {
          ...prev?.person,
          data: {
            ...prev?.person?.data,
            timetable_color: findColor.value,
          },
        },
      }));
    }

    setValidData({ ...validData, timetable_color: true });
    setChanged(true);
  };

  const handleOnChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValidData({ ...validData, [e.target.name]: valid.email(e.target.value) });
    setStateTabs?.((prev) => ({
      ...prev,
      person: {
        ...prev?.person,
        data: {
          ...prev?.person?.data,
          [e.target.name]: e.target.value,
        },
      },
    }));
    setChanged(true);
  };

  return (
    <Content>
      <Left>
        <HeadLeft>
          <Block>
            <Title>Личная информация</Title>
            <WrapperInput>
              <Input
                onChange={handleOnChange}
                value={data?.surname || ''}
                valid={validData.surname}
                name={'surname'}
                placeholder='Фамилия'
              />
            </WrapperInput>
            <WrapperInput>
              <Input
                onChange={handleOnChange}
                value={data?.name || ''}
                valid={validData.name}
                name={'name'}
                placeholder='Имя'
              />
            </WrapperInput>
            <WrapperInput>
              <Input
                onChange={handleOnChange}
                value={data?.patronymic || ''}
                valid={validData.patronymic}
                name={'patronymic'}
                placeholder='Отчество'
              />
            </WrapperInput>
            <WrapperInput>
              <Input onChange={handleOnChange} value={data?.inn || ''} name={'inn'} placeholder='ИНН' />
            </WrapperInput>
            <WrapperInput>
              <Input onChange={handleOnChange} value={data?.snils || ''} name={'snils'} placeholder='СНИЛС' />
            </WrapperInput>
          </Block>
          <Block>
            <Title>Контакты</Title>
            <WrapperInput>
              <InputMack
                value={data?.phone || ''}
                mask='+7 (999) 999-99-99'
                onChange={handleOnChange}
                valid={validData.phone}
                name={'phone'}
                placeholder='Телефон'
              />
            </WrapperInput>
            <WrapperInput>
              <Input
                onChange={handleOnChangeEmail}
                value={data?.email || ''}
                valid={validData.email}
                name={'email'}
                placeholder='E-mail'
              />
            </WrapperInput>
            <WrapperInput>
              <Input onChange={handleOnChange} value={data?.telegram || ''} name={'telegram'} placeholder='Telegram' />
            </WrapperInput>
            <WrapperInput>
              <Input onChange={handleOnChange} value={data?.whatsapp || ''} name={'whatsapp'} placeholder='WhatsApp' />
            </WrapperInput>
          </Block>
        </HeadLeft>
        <WrapperTextarea>
          <Textarea onChange={handleOnChange} value={data?.comment || ''} name={'comment'} placeholder='Описание' />
        </WrapperTextarea>
      </Left>
      <Right>
        <Title>Фотография</Title>
        <AddPhoto
          onChange={handleOnChangePhoto}
          urlImg={data?.image?.file_path}
          title='Загрузить'
          height={146}
          width={146}
        />
        {/*<BlockColor>
          <Title>Цвет в расписании</Title>
          <ColorContent>
            {colorData.map((color) => (
              <Color
                onClick={handleClickColor(color.id)}
                active={color.active}
                valid={validData.timetable_color}
                key={color.id}
                style={{ background: color.color }}
              />
            ))}
          </ColorContent>
          {!validData?.timetable_color && <ErrorText>Выберите цвет!</ErrorText>}
        </BlockColor>*/}
      </Right>
    </Content>
  );
};
const ErrorText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #d23333;
  margin-top: 8px;
`;
const Color = styled.div<{ active: boolean; valid: boolean }>`
  border-radius: 8px;
  height: 32px;
  width: 32px;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 16px;
  }

  &:hover {
    border: 2px solid #b7b7b7;
  }

  ${({ active, valid }) =>
    !valid
      ? css`
          border: 2px solid var(--color-red);
        `
      : active
      ? css`
          border: 2px solid #09da09;
        `
      : css`
          border: 2px solid #ffffff;
        `}
`;
const ColorContent = styled.div`
  display: flex;
`;
const BlockColor = styled.div`
  margin-top: 24px;
`;

const WrapperTextarea = styled.div`
  margin-top: 16px;
  textarea {
    height: 160px;
  }
`;
const HeadLeft = styled.div`
  display: flex;
`;

const WrapperInput = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;
const Title = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #282a2f;
  margin-bottom: 16px;
`;
const Block = styled.div`
  width: 320px;
  &:not(:last-child) {
    margin-right: 56px;
  }
`;
const Left = styled.div``;
const Right = styled.div`
  margin-left: 56px;
`;
const Content = styled.div`
  padding: 24px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 0 8px 8px 8px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 90px;
`;
