import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Flex, Spin } from 'antd';
//import styled from 'styled-components';

type PreloaderProps = {
  loading: boolean;
  align?: string;
};

export const PreloaderSmall: React.FC<PreloaderProps> = ({loading}) => {
  return  (
    <Flex align="center" gap="middle">
      <Spin indicator={<LoadingOutlined spin />} spinning={loading} size="small" />
    </Flex>
  );
}

export const Preloader: React.FC<PreloaderProps> = ({loading, align}) => {
  return (
    <Flex align={align || "center"} gap="middle">
      <Spin indicator={<LoadingOutlined spin />} spinning={loading} />
    </Flex>
  );
}

export const PreloaderLarge: React.FC <PreloaderProps>= ({loading}) => {
  return (
    <Flex align="center" gap="middle">
      <Spin indicator={<LoadingOutlined spin />} spinning={loading} size="large" />
    </Flex>
  );
}

/*export const Preloader: React.FC = () => {
  return (
    <Content>
      <div />
      <div />
    </Content>
  );
};

const Content = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    border: 4px solid var(--color-purple);
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
`;*/
