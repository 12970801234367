import React, {useEffect, useState, useMemo} from 'react';
import styled from 'styled-components';
import { useScheduleContext } from '../context';
import GrafikWorkDay from './grafikWorkDay';
import { GrafikWeekDaySelector } from './grafikWeekDaySelector';
import { GrafikTimeout } from '../timeout';
import { ScheduleRowBlock, ScheduleRowTitle } from '../styles';

export const ScheduleGrafik: React.FC = () => {  
  const {schedule, setSchedule} = useScheduleContext();
  const [timeBegin, setTimeBegin] = useState<string | null | undefined>(null);
  const [timeEnd, setTimeEnd] = useState<string | null | undefined>(null);

  useMemo(() => {
    if (schedule?.length) {
      for (let i = 0; i < schedule.length; i++) {
        const from = schedule?.[i]?.data?.[0]?.grafik?.time?.[0]?.from;
        const to = schedule?.[i]?.data?.[0]?.grafik?.time?.[0]?.to;
        if (from && to) {
          setTimeBegin(from);
          setTimeEnd(to);
          break;
        }
      }
    }
  }, [schedule]);

  useEffect(() => {
    const scheduleClone = JSON.parse(JSON.stringify(schedule));
    for (let i = 0; i < scheduleClone.length; i++) {
      if (scheduleClone[i].data[0].grafik.time[0].from?.length && scheduleClone[i].data[0].grafik.time[0].to?.length) {
        scheduleClone[i].data[0].grafik.time[0].from = timeBegin?.length ? timeBegin : null;
        scheduleClone[i].data[0].grafik.time[0].to = timeEnd?.length ? timeEnd : null;
      }
    }
    setSchedule(scheduleClone);
  }, [timeBegin,timeEnd]);

  return (
    <Content>
      <GrafikWorkDay 
        timeBegin={timeBegin} 
        timeEnd={timeEnd} 
        setTimeBegin={setTimeBegin} 
        setTimeEnd={setTimeEnd} 
      />
      <GrafikWeekDaySelector 
        timeBegin={timeBegin} 
        timeEnd={timeEnd} 
      />
      <GrafikTimeoutBlock>
        <ScheduleRowTitle>Нерабочее время</ScheduleRowTitle>
        <GrafikTimeout showFirstOnly={true} />
      </GrafikTimeoutBlock>
    </Content>
  );
};

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  width: 100%;
  margin-top: 12px;
`;

const GrafikTimeoutBlock = styled(ScheduleRowBlock)`
  margin: 24px 0;
`;