import { FilterDataProps } from 'components/filters/filter';
import { DataSort } from 'components/filters/sorting';
import { MultiFilterDataProps } from '../../components/filters/multiFilter';

export const initFilters: MultiFilterDataProps = {
  1: {
    id: 1,
    active: false,
    label: 'Дата рождения',
    value: '',
    query: 'birthdayAt',
    type: 'DATE',
  },
  2: {
    id: 2,
    active: false,
    label: 'Имя',
    value: '',
    query: 'name',
    type: 'INPUT',
  },
  3: {
    id: 3,
    active: false,
    label: 'Фамилия',
    value: '',
    query: 'surname',
    type: 'INPUT',
  },
  4: {
    id: 4,
    active: false,
    label: 'Отчество',
    value: '',
    query: 'patronymic',
    type: 'INPUT',
  },
  5: {
    id: 5,
    active: false,
    label: 'Пол',
    value: '',
    query: 'gender',
    type: 'SELECTOR',
  },
  6: {
    id: 6,
    active: false,
    label: 'Услуга',
    value: '',
    query: 'service',
    type: 'INPUT',
  },
};

export const dataSorting: DataSort[] = [
  {
    id: 1,
    label: 'Услуга',
    value: 'service',
  },
  {
    id: 2,
    label: 'Специалист',
    value: 'specialist',
  },
  {
    id: 3,
    label: 'Дата',
    value: 'date_at',
  },
  {
    id: 4,
    label: 'Время',
    value: 'time_at',
  },
  {
    id: 5,
    label: 'Статус',
    value: 'status',
  },
  {
    id: 6,
    label: 'Сумма',
    value: 'amount',
  },
  {
    id: 7,
    label: 'Причина',
    value: 'reason',
  },
  {
    id: 8,
    label: 'Комментарий',
    value: 'comment',
  },
];

export const getActiveFilters = (data: FilterDataProps[]) => {
  return data.reduce((acc, item) => {
    if (item.active) {
      return { ...acc, [item.value]: item.value };
    } else return acc;
  }, {});
};

export const createData = (data?: Record<string, number | string | null>) => {
  return {
    client_id: data?.client_id,
    status_id: data?.status_id,
    status_name: data?.status_name, 
    services: [{
      amount: data?.services?.[0]?.amount,
      date_at: data?.services?.[0]?.date_at,
      time_at: data?.services?.[0]?.time_at,
      service_id: data?.services?.[0]?.service_id,
      specialist_id: data?.services?.[0]?.specialist_id,
      workplace_id: data?.services?.[0]?.workplace_id,
      reason: data?.services?.[0]?.reason,
      comment: data?.services?.[0]?.comment,
    }],
  };
};
export const updateData = (data?: Record<string, number | string | null>) => {
  return {
    id: data?.services?.[0]?.id,
    amount: data?.services?.[0]?.amount,
    client_id: data?.client_id,
    comment: data?.services?.[0]?.comment,
    date_at: data?.services?.[0]?.date_at,
    service_id: data?.services?.[0]?.service_id,
    specialist_id: data?.services?.[0]?.specialist_id,
    workplace_id: data?.services?.[0]?.workplace_id,
    status_id: data?.status_id,
    status_name: data?.status_name,
    time_at: data?.services?.[0]?.time_at,
    reason: data?.services?.[0]?.reason,
  };
};

export const clientFrontBack = (data: Record<string, any>) => {
  return {
    id: data?.id,
    is_active: data?.is_active,
    gender: data?.gender,
    surname: data?.surname,
    name: data?.name,
    patronymic: data?.patronymic,
    birthday_at: data?.birthday_at,
    profession: data?.profession,
    phone: data?.phone,
    email: data?.email,
    terminal: data?.terminal,
    channel: data?.channel,
    reason: data?.reason,
    todolistItems: data?.todolistItems,
    comment: data?.comment,
    series: data?.docs?.series,
    number: data?.docs?.number,
    code: data?.docs?.code,
    begin_at: data?.docs?.begin_at,
    issued_by: data?.docs?.issued_by,
    address_repeat: data?.address_repeat, ///
    address_register: data?.docs?.address_register,
    address_fact: data?.docs?.address_fact,
    inn: data?.docs?.inn,
    driver_license: data?.docs?.driver_license,
    car_pts: data?.docs?.car_pts,
    car_insurance: data?.docs?.car_insurance,
    car_number: data?.docs?.car_number,
    snils: data?.docs?.snils,
    policy_oms: data?.docs?.policy_oms,
    policy_dms: data?.docs?.policy_dms,
    notification_disable: data?.notification_disable, ///
  };
};

export const mapEntries = (entries: Record<string, any>[]) => {
  return entries.map(entry => {
    return {
      id: entry?.id,
      is_active: entry?.is_active,
      reason: entry?.reason,
      comment: entry?.comment,
      sort: entry?.sort,
      amount: entry?.amount,
      paid: entry?.paid,
      date_at: entry?.date_at,
      time_at: entry?.time_at,
      created_at: entry?.created_at,
      updated_at: entry?.updated_at,
      service_id: entry?.service?.service_id,
      service_title: entry?.service?.title,
      service_description: entry?.service?.description,
      specialist_id: entry?.specialist?.specialist_id,
      specialist_name: entry?.specialist?.name,
      specialist_surname: entry?.specialist?.surname,
      specialist_patronymic: entry?.specialist?.patronymic,
      specialist_phone: entry?.specialist?.phone,
      specialist_email: entry?.specialist?.email,
      status_id: entry?.status_name?.id,
      status_name: entry?.status_name?.name,
      status_is_archive: entry?.status_name?.is_archive,
      status_sort: entry?.status_name?.sort,
      workplace_id: entry?.workplace?.workplace_id,
      workplace_title: entry?.workplace?.title,
      workplace_address: entry?.workplace?.address,
      workplace_description: entry?.workplace?.description,
      client_fio: `${entry?.client?.surname || ''} ${entry?.client?.name || ''}`.trim(),
      client_phone: entry?.client?.phone,
      client_id: entry?.client?.client_id,
    };
  });
  
};
