import React from 'react';
import styled from 'styled-components';
import { ItemTable, HistoryTable } from './types';

type HeaderTableProps = {
  grid: string;
  data: ItemTable[] | HistoryTable[];
  children?: React.ReactNode;
};

export const HeaderTable: React.FC<HeaderTableProps> = ({ grid, data }) => {
  return (
    <Content grid={grid}>
      {data.map((item: ItemTable | HistoryTable) => (
        <Item key={item.id}>{item.value}</Item>
      ))}
    </Content>
  );
};
const Item = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: var(--color-table-title);
  line-break: normal;
  word-break: break-word;
  hyphens: auto;
`;
const Content = styled.div<{ grid: string }>`
  gap: 10px;
  background: var(--color-table-header-bg);
  display: grid;
  justify-content: space-between;
  grid-template-columns: ${({ grid }) => grid};
  padding: 15px 30px;
  text-align: left;
  @media screen and (max-width: 1199px) {
    width: 1199px;
    padding: 10px;
  }
`;
