import React from 'react';
import styled from 'styled-components';
import { TimePicker } from 'components/timePicker';
import { DatePicker } from 'components/datePicker';
import { SelectorStatic, ItemSelectPros } from 'components/selectorStatic';
import { useAppSelector } from 'core/hooks';
import { branchSelector } from 'pages/branch/branchSlice';
import { workplaceSelector } from 'pages/workplace/workplaceSlice';
import { specialistSelector } from 'pages/specialist/specialistSlice';

type FilterItemType = Record<string, any> | null;
type DateTimeType = string | null | undefined;

type CalendarFilterProps = {
  dateSelected?: DateTimeType;
  workTimeBeginSelected?: DateTimeType;
  workTimeEndSelected?: DateTimeType;
  branchSelected: FilterItemType;
  workplaceSelected: FilterItemType;
  specialistSelected: FilterItemType;
  setBranchSelected: (branch: FilterItemType) => void;
  setWorkplaceSelected: (workplace: FilterItemType) => void;
  setSpecialistSelected: (specialist: ItemSelectPros) => void;
  setDateSelected: (date?: DateTimeType) => void;
  setWorkTimeBeginSelected: (time?: DateTimeType) => void;
  setWorkTimeEndSelected: (time?: DateTimeType) => void;
}

export const CalendarFilter: React.FC<CalendarFilterProps> = (props) => {
  const {
    dateSelected,
    workTimeBeginSelected,
    workTimeEndSelected,
    branchSelected, 
    workplaceSelected, 
    specialistSelected, 
    setBranchSelected, 
    setWorkplaceSelected, 
    setSpecialistSelected,
    setDateSelected,
    setWorkTimeBeginSelected,
    setWorkTimeEndSelected
  } = props;

  const specialists = useAppSelector(specialistSelector.specialists);
  const branch = useAppSelector(branchSelector.branch);
  const workplace = useAppSelector(workplaceSelector.workplace);
  const specialist = useAppSelector(specialistSelector.specialist);

  const handleSelectBranch = (b: ItemSelectPros) => {
    setBranchSelected(specialist?.workplaces?.filter((item: any) => item.branch?.id === b?.id)?.[0] || null);
  }

  const handleSelectWorkplace = (w: ItemSelectPros) => {
    setWorkplaceSelected(specialist?.workplaces?.filter((item: any) => item?.id === w?.id)?.[0] || null);
  }

  const handleSelectSpecialist = (s: ItemSelectPros) => {
    setSpecialistSelected(s);
  }
  
  return (
    <Content>
      <FilterBlock>
        <p>Филиал</p>
        <SelectorStatic 
          data={specialist?.workplaces?.filter((w: any) => w.branch?.id)?.map?.((w: any) => {return {id: w.branch.id, value: w.branch.name, data: w.branch.name}})}
          //data={branches?.map((item: any) => {return {id: item.id,value: item.name,data: item.name}})}
          onChange={handleSelectBranch} 
          valueId={branchSelected?.id || branch?.id || ''}
          defaultValue={specialist?.workplaces?.filter((w: any) => w.branch?.id)?.map?.((w: any) => {return {id: w.branch.id, value: w.branch.name, data: w.branch.name}})?.[0] || null}
        />
      </FilterBlock>
      <FilterBlock>
        <p>Рабочее место</p>
        <SelectorStatic 
          data={specialist?.workplaces?.map?.((w: any) => {return {id: w.id, value: w.title, data: w.title}})}
          //data={workplaces?.map((item: any) => {return {id: item.id,value: item.name,data: item.name}})}
          onChange={handleSelectWorkplace} 
          valueId={workplaceSelected?.id || workplace?.id || ''}
          defaultValue={specialist?.workplaces?.map?.((w: any) => {return {id: w.id, value: w.title, data: w.title}})?.[0] || null}
        />
      </FilterBlock>
      {!specialist?.id && 
        <FilterBlock>
          <p>Специалист</p>
          <SelectorStatic 
            data={specialists?.map((item: any) => {return {id: item.id,value: item.name,data: item.name}})}
            onChange={handleSelectSpecialist} 
            valueId={specialistSelected?.id || specialist?.id || ''}
          />
        </FilterBlock>}
      <FilterBlock>
        <p>Дата</p>
        <DatePicker
          title={''}
          value={dateSelected || ''}
          placeholder={'дд.мм.гггг'}
          height={32}
          disabled={false}
          showSuffix={false}
          valid={true}
          onChange={(date?: DateTimeType) => setDateSelected(date)}
        />
      </FilterBlock>
      <FilterBlock>
        <p>Рабочее время</p>
        <div>
          <TimePicker
            title={'Начало'}
            value={workTimeBeginSelected || ''}
            placeholder={'чч:мм'}
            height={32}
            disabled={false}
            showSuffix={false}
            valid={true}
            onChange={(time?: DateTimeType) => setWorkTimeBeginSelected(time)}
          />
          <div>&ndash;</div>
          <TimePicker
            title={'Окончание'}
            value={workTimeEndSelected || ''}
            placeholder={'чч:мм'}
            height={32}
            disabled={false}
            showSuffix={false}
            valid={true}
            onChange={(time?: DateTimeType) => setWorkTimeEndSelected(time)}
          />
        </div>
      </FilterBlock>
      {!specialist?.id && 
        <FilterBlock>
          <p>Нерабочее время</p>
          <div>
            <TimePicker
              title={'Начало'}
              value={'14:00'}
              placeholder={'чч:мм'}
              height={32}
              disabled={false}
              showSuffix={false}
              valid={true}
              onChange={(e) => console.log(e)}
            />
          <div>&ndash;</div>
          <TimePicker
            title={'Окончание'}
            value={'15:00'}
            placeholder={'чч:мм'}
            height={32}
            disabled={false}
            showSuffix={false}
            valid={true}
            onChange={(e) => console.log(e)}
          />
        </div>
      </FilterBlock>}
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 15px;
  & > *:last-child {
    justify-self: flex-end;
  }
`;
const FilterBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap:8px;
  & > * {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    font-size: 11px;
    color: #6B6F76;
  }
`;