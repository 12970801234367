import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ImCopy } from 'react-icons/im';
import { Header } from 'layout/header';
import { Input } from 'components/input';
import { IconPrinter, IconSearch, IconTrash } from 'icons';
import { ButtonPros } from 'core/types';
import { ItemFilter, NumberFiler } from 'features/numberFiler';
import config from 'api/config';

import { useAppDispatch, useAppSelector } from 'core/hooks';
import {
  categoryActiveServiceSelector,
  categoryServiceSelector,
  countActiveServiceSelector,
  itemsDataServiceSelector,
  searchDataServiceSelector,
  serviceActiveCategorySelector,
  serviceSlice,
  servicesServiceSelector,
} from './serviceSlice';
import { PaginationBack } from 'features/paginationBack';
import { ItemService } from './itemParentService';
import { ModalAnt } from 'components/modalAnt';

import { DefaultButton, IconButton, WhiteButton } from 'components/buttons';

import { PopUpAnt } from 'components/popupAnt';

import { TabsContextProvider, useTabsContext } from './tabsModal/context';
import { ItemTabs, Tabs } from 'components/tabs';
import { ServiceTab } from './tabsModal/service';
import { isNewImage } from 'core/utils';
import debounce from 'lodash/debounce';
import { ItemCategoryServiceSearch } from './itemCategoryServiceSearch';
import { PreloaderLarge, Preloader } from 'components/preloader';
import { InputSearch } from 'components/inputSearch';
import { GenerateDateWeek } from 'features/generateDateWeek';

const dataFilter = [
  { id: 0, value: 10 },
  { id: 1, value: 20 },
  { id: 2, value: 40 },
  { id: 3, value: 80 },
];

const ServiceContent: React.FC = () => {
  const dispatch = useAppDispatch();

  const count = useAppSelector(countActiveServiceSelector);
  const itemData = useAppSelector(itemsDataServiceSelector);
  const category = useAppSelector(categoryServiceSelector);
  const services = useAppSelector(servicesServiceSelector);
  const serviceActive = useAppSelector(serviceActiveCategorySelector);
  const categoryActive = useAppSelector(categoryActiveServiceSelector);
  const searchData = useAppSelector(searchDataServiceSelector);

  const [checkSearch, setCheckSearch] = useState<boolean>(false);
  const [deleteData, setDeleteData] = useState<Record<string, any>>();
  const [copyData, setCopyData] = useState<Record<string, any>>();
  const [activeTab, setActiveTab] = useState<number>();
  const [showModal, setShowModal] = useState(false);
  const [size, setSize] = useState(dataFilter[0].value);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState<string>('');
  const [showPopUp, setShowPopUp] = useState<'save' | 'confirmToClose' | 'deleteCat' | 'deleteServ' | 'copyService' | null>();

  const { state: stateTabs, setState: setStateTabs } = useTabsContext();
  const [isChanged, setChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const ids = useMemo(() => Object.keys(itemData), [itemData]);

  const [servicesFiltered, setServicesFiltered] = useState<any[]>([]);

  useEffect(() => {
    //обновление структуры после поиска
    if (!checkSearch) {
      //Обновляю данные списка
      if (itemData && Object.values(itemData)) {
        Object.entries(itemData).forEach(([key, value]) => {
          if (key && value) {
            dispatch(
              serviceSlice.actions.getAllServiceRequest({
                parent_id: key,
              }),
            );
          }
        });
      }

      handledRequest();
    }
  }, [checkSearch]);

  const searchRequest = (search: string) => {
    setLoading(true);
    dispatch(
      // serviceSlice.actions.getSearchServiceRequest({
      //   payload: {
      //     data: {
      //       page: 1,
      //       size,
      //       title: search || null,
      //       // title: null
      //     },
      //     success: () => {
      //       //setCheckSearch(!!search);
      //       setLoading(false);
      //     },
      //   },
      // }),
      serviceSlice.actions.getAllServiceRequest({
        payload: {
          data: {},
          success: () => {
            //setCheckSearch(!!search);
            setLoading(false);
            setServicesFiltered(services);
          },
        },
      }),
    );
  };

  useEffect(() => {
      if (services.length) {
        if (search.length) {
          const servicesFiltered: Record<string, any>[] = [];
          for (let i = 0; i < services.length; i++) {
            const service: Record<string, any> = JSON.parse(JSON.stringify(services[i]));
            service.children.length = 0;
            for (let j = 0; j < services[i].children.length; j++) {
              if (services[i].children[j].title.toLowerCase().search(search.toLowerCase()) !== -1) {
                service.children.push(services[i].children[j]);
              }
            }
            if (service.title.toLowerCase().search(search.toLowerCase()) !== -1 || service.children.length) {
              servicesFiltered.push(service);
            }
          }
          setServicesFiltered(servicesFiltered);
        } else {
          setServicesFiltered(services);
        }
      }
      return () => setServicesFiltered(services);
    }, [search, services]);

  const handledRequest = useCallback(() => {
    setLoading(true);
    setServicesFiltered(services);
    dispatch(
      serviceSlice.actions.getAllServiceRequest({
        payload: {
          page,
          size,
          is_parent: true,
          success: () => {
            setLoading(false);
            setChanged(false);
            setServicesFiltered(services);
          }
        },
      }),
    );
  }, [page, size]);

  const updatePage = (type: 'create' | 'remove') => {
    switch (type) {
      case 'create': {
        const nextValue = Math.ceil(count / size);
        setPage(nextValue);
        break;
      }
      case 'remove':
        if (category?.length === 1 && page !== 1) {
          setPage((prev) => prev - 1);
        } else {
          handledRequest();
        }
        break;
      default:
        return;
    }
  };

  const handleClose = () => {
    //сброс при закрытии
    setStateTabs?.((prev) => ({ ...prev, service: null, category: null }));
    dispatch(serviceSlice.actions.resetActiveData());
    setShowModal(false);
    setActiveTab(undefined);
    setShowPopUp(null);
    setDeleteData(undefined);
    setCopyData(undefined);
    setChanged(false);
  };

  useEffect(() => {
    handledRequest();
  }, [page, size]);

  useEffect(() => {
    if (services) {
      setLoading(false);
    } else {
      setLoading(true);
    }    
  }, [services]);

  const dataTabs: ItemTabs[] = [
    {
      label: 'Услуга',
      content: <ServiceTab setChanged={setChanged} />,
    },
  ];

  const handleClickFilter = (d: ItemFilter) => {
    setSize(+d.value);
  };

  const buttons: ButtonPros[] = [
    {
      id: 1,
      type: 'icon',
      icon: IconPrinter,
      onClick: () => console.log('print'),
    },
    {
      id: 2,
      title: '+ Добавить',
      onClick: () => setShowModal(true),
    },
  ];

  const handledSuccess = (exit?: boolean, prevId?: number | symbol | null) => (data: Record<string, any>) => {
    //Обновляю данные списка
    if (itemData && Object.values(itemData)) {
      Object.entries(itemData).forEach(([key, value]) => {
        if (key && value) {
          dispatch(
            serviceSlice.actions.getAllServiceRequest({
              parent_id: key,
            }),
          );
        }
      });
    }

    handledRequest();

    if (exit) {
      //сброс после сохранить и выйти
      handleClose();
    } else {
      if (data?.type === 'service') {
        setActiveTab(0);
      }
      if (data?.type === 'category') {
        setActiveTab(1);
      }
    }
    if (checkSearch) {
      searchRequest(search);
    }

    setShowPopUp(null);
    setChanged(false);
  };

  const handleClickSave = (exit?: boolean) => {
    if (stateTabs?.service?.activeTab) {
      const data = { ...stateTabs?.service?.data };

      if (stateTabs?.service?.data?.id) {
        //UPDATE
        dispatch(
          serviceSlice.actions.postUpdateServiceRequest({
            payload: {
              data: { ...data, image: isNewImage(data?.image) },
              success: handledSuccess(exit),
            },
          }),
        );
      } else {
        //CREATE
        dispatch(
          serviceSlice.actions.postCreateServiceRequest({
            payload: {
              data,
              success: handledSuccess(exit),
            },
          }),
        );
      }
    }
    if (stateTabs?.category?.activeTab) {
      const data: Record<string, any> = { ...stateTabs?.category?.data };

      if (stateTabs?.category?.data?.id) {
        //UPDATE
        dispatch(
          serviceSlice.actions.updateCategoryRequest({
            payload: {
              data: { ...data, category_id: typeof data?.category_id === 'symbol' ? 'null' : data?.category_id },
              success: handledSuccess(exit),
            },
          }),
        );
      } else {
        //CREATE
        dispatch(
          serviceSlice.actions.createCategoryRequest({
            payload: {
              data: { ...data, category_id: typeof data?.category_id === 'symbol' ? 'null' : data?.category_id },
              success: handledSuccess(exit),
            },
          }),
        );
      }
    }
    setShowPopUp(null);
  };

  const successDelete = (data?: Record<string, any>) => async () => {
    if (ids.includes(data?.parent_id?.toString())) {
      dispatch(
        serviceSlice.actions.getAllServiceRequest({
          parent_id: data?.parent_id,
        }),
      );
    }
    //сделать запрос у родителя родительской категории
    const parent = Object.values(itemData)
      .reduce((acc, arrs) => [...acc, ...arrs], [])
      .find((item: Record<string, any>) => item?.id === data?.category_id);

    if (parent) {
      dispatch(
        serviceSlice.actions.getAllServiceRequest({
          parent_id: parent?.parent_id,
        }),
      );
    }

    updatePage('remove');

    handleClose();
  };

  const handledClickDelete = (type: 'category' | 'service', data?: Record<string, any>) => () => {
    switch (type) {
      case 'service':
        dispatch(
          serviceSlice.actions.deleteServiceRequest({
            payload: {
              data,
              success: successDelete(data),
            },
          }),
        );
        break;
      case 'category':
        dispatch(
          serviceSlice.actions.deleteCategoryRequest({
            payload: {
              data,
              success: successDelete(data),
            },
          }),
        );
        break;
      default:
        return;
    }
  };

  const handledCopy = () => {
    dispatch(
      serviceSlice.actions.copyServiceRequest({
        payload: {
          data: { id: copyData?.id },
          success: (data?: Record<string, any>) => {
            if (ids.includes(data?.category_id?.toString())) {
              dispatch(
                serviceSlice.actions.getAllServiceRequest({
                  category_id: data?.category_id,
                }),
              );
            }
            //сделать запрос у родителя родительской категории
            const parent = Object.values(itemData)
              .reduce((acc, arrs) => [...acc, ...arrs], [])
              .find((item: Record<string, any>) => item?.id === data?.category_id);

            if (parent) {
              dispatch(
                serviceSlice.actions.getAllServiceRequest({
                  category_id: parent?.category_id,
                }),
              );
            }
            setShowPopUp(null);
            setShowModal(true);
          },
        },
      }),
    );
  };

  const contentPopUp = {
    confirmToClose: {
      title: 'Закрыть окно?',
      onClick: () => handleClose(),
    },
    save: {
      title: 'Сохранить изменения?',
      onClick: () => handleClickSave(),
    },
    deleteCat: {
      title: 'Удалить категорию?',
      onClick: handledClickDelete('category', deleteData),
    },
    deleteServ: {
      title: 'Удалить услугу?',
      onClick: handledClickDelete('service', deleteData),
    },
    copyService: {
      title: 'Скопировать услугу?',
      onClick: handledCopy,
    },
  };

  const handleClickButtonSave = (exit?: boolean) => () => {
    if (stateTabs?.service?.onIsValid?.()) {
      if (exit) {
        handleClickSave(exit);
      } else {
        setShowPopUp('save');
      }
    }

    if (stateTabs?.category?.onIsValid?.()) {
      if (exit) {
        handleClickSave(exit);
      } else {
        setShowPopUp('save');
      }
    }
  };

  const changeService = (data?: Record<string, any>) => {
    switch (data?.type) {
      case 'service':
        dispatch(serviceSlice.actions.getServiceIdRequest({ id: data?.id }));
        setActiveTab(0);
        setShowModal(true);
        break;
      case 'category':
        setActiveTab(1);
        setShowModal(true);
        dispatch(serviceSlice.actions.changeCategory(data));
        break;
      default:
        return;
    }
  };

  const changeActive = (data?: Record<string, any>) => {
    switch (data?.type) {
      case 'service':
        dispatch(
          serviceSlice.actions.postUpdateServiceRequest({
            payload: {
              data: { id: data?.id, name: data?.title, is_active: !data?.is_active },
              success: handledSuccess(),
            },
          }),
        );

        break;
      case 'category':
        dispatch(
          serviceSlice.actions.updateCategoryRequest({
            payload: {
              data: { id: data?.id, name: data?.title, is_active: !data?.is_active },
              success: handledSuccess(),
            },
          }),
        );
        break;
      default:
        return;
    }
  };
  const handledClickTrash = () => {
    if (stateTabs?.category?.activeTab) {
      setDeleteData(stateTabs?.category?.data);
      setShowPopUp('deleteCat');
    }
    if (stateTabs?.service?.activeTab) {
      setDeleteData(stateTabs?.service?.data);
      setShowPopUp('deleteServ');
    }
  };

  const handledClickTrashRow = (data?: Record<string, any>) => {
    switch (data?.type) {
      case 'category':
        setDeleteData(data);
        setShowPopUp('deleteCat');
        break;
      case 'service':
        setDeleteData(data);
        setShowPopUp('deleteServ');
        break;
      default:
        return;
    }
  };

  const handledCopyService = (data?: Record<string, any>) => {
    setCopyData(data);
    setShowPopUp('copyService');
    setActiveTab(0);
  };

  const addServiceCategory = (data?: Record<string, any>, level?: number) => {
    if (level && level === 2) {
      setActiveTab(0);
    }
    setStateTabs?.((prev) => ({
      ...prev,
      category: {
        ...prev?.category,
        data: {
          ...prev?.category?.data,
          category_id: data?.id,
        },
      },
      service: {
        ...prev?.service,
        data: {
          ...prev?.service?.data,
          category_id: data?.id,
        },
      },
    }));
    setShowModal(true);
  };

  const showValue = (search: string) => {
    if (!search) {
      setCheckSearch(false);
      return dispatch(serviceSlice.actions.resetSearchData());
    }

    setCheckSearch(true);
    searchRequest(search);
  };

  const handledDebounce = useCallback(debounce(showValue, config.delaySearch), []);

  const handledOnChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    // handledDebounce(e.target.value);
  };

  return (
    <Content>
      <WrapperHeader>
        <Header title='Услуги' buttons={buttons} />
      </WrapperHeader>

      {((services && !!services?.length) || checkSearch) && (
        // <SearchBlock>
        //   <WrapperInput>
        //     <Input icon={IconSearch} placeholder='Найти' onChange={handledOnChangeSearch} value={search} />
        //   </WrapperInput>
        // </SearchBlock>
        <SearchBlock>
          <ControlBlock>
          <InputSearch title='Поиск' placeholder='Поиск' onChange={handledOnChangeSearch} value={search} />
        </ControlBlock>
        </SearchBlock>
      )}

      {loading ? <div style={{ marginLeft: 24 }}><PreloaderLarge loading={loading} /></div> : 
      <>
        {checkSearch ? (
          <Fragment key={search}>
            {searchData?.length ? (
              <Fragment key={search}>
                {searchData.map((item) => (
                  <ItemCategoryServiceSearch
                    key={`${item?.id}-${search}`}
                    data={item}
                    changeService={changeService}
                    changeActive={changeActive}
                    deleteService={handledClickTrashRow}
                    copyService={handledCopyService}
                    addServiceCategory={addServiceCategory}
                  />
                ))}
              </Fragment>
            ) : (
              <TextNotData>Ничего не найдено</TextNotData>
            )}
          </Fragment>
        ) : (
          <>
            {services && !!services?.length ? (
              <>
              {servicesFiltered.map((item) => {
                // {services?.map((item) => {
                  return (
                    <ItemService
                      key={item.id}
                      data={item}
                      addServiceCategory={addServiceCategory}
                      changeService={changeService}
                      changeActive={changeActive}
                      deleteService={handledClickTrashRow}
                      copyService={handledCopyService}
                    />
                  );
                })}
                <Footer>
                  <WrapperPagination>
                    <PaginationBack size={size} count={count} page={page} onChange={(v) => setPage(v)} />
                  </WrapperPagination>
                  <WrapperFilter>
                    <NumberFiler title='По' data={dataFilter} onClick={handleClickFilter} />
                  </WrapperFilter>
                </Footer>
              </>
            ) : (
              <TextNotData>Данные отсутствуют</TextNotData>
            )}
          </>
        )}
      </>}

      <ModalAnt key={showModal?.toString()} show={showModal} onClose={() => isChanged ? setShowPopUp('confirmToClose') : handleClose()}>
        <Tabs disableActiveTab={activeTab} data={dataTabs} />
        <FooterBtn>
          <BLockBtn>
            <WrapperButton>
              <DefaultButton onClick={handleClickButtonSave()}>Сохранить</DefaultButton>
            </WrapperButton>
            <WrapperButton>
              <WhiteButton onClick={handleClickButtonSave(true)}>Сохранить и выйти</WhiteButton>
            </WrapperButton>
            {loading && <Preloader loading={loading} />}
          </BLockBtn>
          <WrapperIconBtn>
            {serviceActive?.id && (
              <WrapperIconButton>
                <IconButton onClick={() => handledCopyService(serviceActive)}>
                  <ImCopy />
                </IconButton>
              </WrapperIconButton>
            )}
            {(serviceActive?.id || categoryActive?.id) && (
              <WrapperIconButton>
                <IconButton onClick={handledClickTrash}>
                  <IconTrash />
                </IconButton>
              </WrapperIconButton>
            )}
          </WrapperIconBtn>
        </FooterBtn>
      </ModalAnt>

      {!!showPopUp && (
        <PopUpAnt
          visible
          title={contentPopUp[showPopUp].title}
          onClick={contentPopUp[showPopUp].onClick}
          onCancel={() => setShowPopUp(null)}
        />
      )}
    </Content>
  );
};

export const Service = (props: Record<any, any>) => {
  return (
    <TabsContextProvider>
      <ServiceContent {...props} />
    </TabsContextProvider>
  );
};
const WrapperIconBtn = styled.div`
  display: flex;
  align-items: center;
`;
const TextNotData = styled.div`
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 14px;
  padding: 20px;
`;

const WrapperHeader = styled.div`
  padding: 0 30px;
`;
const WrapperIconButton = styled.div`
  button {
    width: 40px;
    height: 40px;
    color: #6b6f76;
  }
  &:not(:last-child) {
    margin-right: 10px;
  }
`;
const WrapperButton = styled.div`
  button {
    height: 40px;
  }
  &:not(:last-child) {
    margin-right: 18px;
  }
`;
const BLockBtn = styled.div`
  display: flex;
  align-items: center;
`;
const ControlBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
`;
const WrapperFilter = styled.div`
  margin-left: 3px;
`;
const WrapperPagination = styled.div`
  padding: 0 8px;
  border-right: 1px solid var(--color-grey);
`;
const Footer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  padding: 0 20px;
`;
const FooterBtn = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
`;
const WrapperInput = styled.div`
  width: 378px;
`;
const SearchBlock = styled.div`
  margin-top: 30px;
  margin-bottom: 10px;
  padding: 0 30px;
`;
const Content = styled.div`
  width: 100%;
  padding: 30px 0 150px 0;
`;
