import React from 'react';
import styled, {css} from 'styled-components';
import { calendarTypes } from '../data';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { scheduleFeatureSelector, scheduleFeatureSlice } from '../scheduleFeatureSlice';

export const ScheduleSwitcher: React.FC = () => {
  const dispatch = useAppDispatch();
  const scheduleType = useAppSelector(scheduleFeatureSelector.type);

  const handleOnSwitch = (type: number) => {
    dispatch(scheduleFeatureSlice.actions.setType(type));
  }

  return (
    <Content>
      {calendarTypes.map((item,i) => 
        <SelectCalendarTypeButtons 
          key={i} 
          active={(scheduleType || 0) === i} 
          onClick={() => handleOnSwitch?.(i)}
        >{item}
        </SelectCalendarTypeButtons>
      )}
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap:0;
  background-color: #EFF1F4;
  border-radius: 5px;
  padding: 2px;
  margin-bottom: 1.5em;
`;
export const SelectCalendarTypeButtons = styled.button<{active: boolean}>`
  padding: 6px 18px;
  font-size: 12px;
  ${({ active }) => {
    if (active) {
      return css`
        background-color: var(--color-white);
        border-radius: 5px;
      `;
    }
  }};
`;