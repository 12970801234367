import React, { ChangeEvent, InputHTMLAttributes, useRef } from 'react';
import styled, { css } from 'styled-components';
import { IconClose } from 'icons';

/*export type ItemDataSearchLocalProps = {
  id: number;
  label: string;
  active: boolean;
};*/

type InputSearchLocalProps = InputHTMLAttributes<HTMLInputElement> & {
  icon?: any;
  name?: string;
  isClear?: boolean;
  onClear?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  data: string;
};

export const InputSearchLocal: React.FC<InputSearchLocalProps> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const closeIconRef = useRef<HTMLSpanElement>(null);

  const Icon = props?.icon && props.icon;
  const { data } = props;

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    props?.onChange?.(e);
  };

  const handleOnFocus = (e: ChangeEvent<HTMLInputElement>) => {
    props?.onFocus?.(e);
    if (props?.isClear && closeIconRef?.current) {
      closeIconRef.current.style.opacity = '1';
      closeIconRef.current.style.cursor = 'pointer';
    }
  };

  const handleOnBlur = (e: ChangeEvent<HTMLInputElement>) => {
    props?.onBlur?.(e);
    if (props?.isClear && closeIconRef?.current) {
      closeIconRef.current.style.opacity = '0';
      closeIconRef.current.style.cursor = 'text';
    }
  };

  const handleClickClear = () => {
    if (inputRef?.current) {
      inputRef.current.value = '';
      props?.onClear?.();
    }
  };

  return (
    <WrapperInput>
      <WrapperIcon>
        <Icon />
      </WrapperIcon>
      <Content
        ref={inputRef}
        value={props?.value}
        name={props?.name}
        type={props?.type}
        icon={!!props.icon}
        isClear={!!props?.isClear}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      />
      {props.placeholder && (
        <Placeholder icon={!!props.icon} data={data}>
          {props.placeholder}
        </Placeholder>
      )}
      {props?.isClear && (
        <WrapperIconClose ref={closeIconRef} onClick={handleClickClear}>
          <IconClose />
        </WrapperIconClose>
      )}
    </WrapperInput>
  );
};

const WrapperIconClose = styled.span`
  cursor: text;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
`;

const Placeholder = styled.span<{ icon: boolean; data: string; }>`
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translateY(50%);
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  transition: all 0.33s ease;

  ${({ icon }) => {
    if (icon) {
      return css`
        left: 40px;
      `;
    } else {
      return css`
        left: 16px;
      `;
    }
  }}

${({ data }) => {
    if (data.length > 0) {
      return css`
        transform: translateY(0);
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        top: -6px;
        background: var(--color-white);
        padding: 0 5px;
        color: #3562d4;
      `;
    } else {
      return css`
        top: 0;
        transform: translateY(50%);
      `;
    }
  }}
`;
const WrapperIcon = styled.div`
  position: absolute;
  left: 12px;
  top: 0;
  transform: translateY(70%);
  display: flex;
  align-items: center;
  justify-content: center;
`;
const WrapperInput = styled.div`
  width: 100%;
  position: relative;
`;
const Content = styled.input<{ icon: boolean; isClear: boolean;}>`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black);
  height: 40px;
  border: 1px solid #dfe1e4;
  border-radius: 8px;
  width: 100%;

  &::placeholder {
    color: #ffffff00;
  }

  &:focus {
    border: 1px solid #3562d4;
  }

  &:focus + ${Placeholder} {
    transform: translateY(0);
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    top: -6px;
    background: var(--color-white);
    padding: 0 5px;
    color: #3562d4;
  }

  ${({ icon, isClear }) => {
    if (isClear) {
      if (icon) {
        return css`
          padding: 12px 40px 12px 40px;
        `;
      } else {
        return css`
          padding: 12px 40px 12px 16px;
        `;
      }
    } else if (icon) {
      return css`
        padding: 12px 16px 12px 40px;
      `;
    } else {
      return css`
        padding: 12px 16px;
      `;
    }
  }}
`;
