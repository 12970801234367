import { WeekDaysType, ScheduleStateType, ScheduleTypesType, ScheduleType } from './types';
import moment from 'moment';

export const weekDays: WeekDaysType[] = [
  { id: 1, name: 'Пн', active: true},
  { id: 2, name: 'Вт', active: true},
  { id: 3, name: 'Ср', active: true},
  { id: 4, name: 'Чт', active: true},
  { id: 5, name: 'Пт', active: true},
  { id: 6, name: 'Сб', active: false},
  { id: 7, name: 'Вс', active: false},
];

export const scheduleTypes: ScheduleTypesType = {
  byWeekDays: {
    title: 'По дням недели',
    type: 'by_week_days'
  },
  byShifts: {
    title: 'По сменам',
    type: 'by_shifts'
  },
};

export const calendarTypes: string[] = ['По календарю','По дням недели'];

export const initialState: ScheduleStateType = {
  type: 0,
  types: [scheduleTypes.byWeekDays.title, scheduleTypes.byShifts.title],
  vocation: [{from: null,to:null}], 
  shifts: [{from: null,to:null}], 
  flexibleGrafik: false,
  schedule: weekDays.map((d: WeekDaysType): ScheduleType => {
    return {
      day: +moment().day(d.id).format('DD'),
      month: +moment().day(d.id).format('MM'),
      year: +moment().day(d.id).format('YYYY'),
      week: d.id,
      active: d.active,
      data: [
        {
          name: d.name,
          branch_id: null,
          workplace_id: null,
          specialist_id: null,
          smena: {
            days: {
              from: "2",
              to: "2"
            },
            time: [
              /*{
                from: "13:00",
                to: "14:00"
              }*/
            ],
            timeout: [
              /*{
                from: "13:00",
                to: "14:00"
            }*/
            ],
          },
          grafik: {
            time: [
              {
                from: "09:00",
                to: "18:00"
              }
            ],
            timeout: [
              {
                from: null,
                to: null
              }
            ]
          },
        },
      ]
    };
  }).sort((a, b) => a.week - b.week )
};