import styled, { css } from 'styled-components';

const Flex = styled.div`
    display: flex;
    gap: 12px;
    width: 100%;
`;

const FlexColumn = styled(Flex)`
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
`;

const FlexRow = styled(Flex)`
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const ValidationError = styled(FlexRow)`
    justify-content: center;
    font-size: 10px;
    text-align: center;
    color: var(--color-red);
`;

const ScheduleSwitcherBlock = styled(FlexRow)`
    justify-content: space-between;
    gap: 16px;
    padding: 24px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border: 0;
`;

const ScheduleTypeBlock = styled(FlexColumn)<{disabled?: boolean}>`
    justify-content: center;
    gap: 16px;
    padding: 24px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    ${({ disabled }) => {
        if (disabled) {
        return css`
            pointer-events:none;
            opacity: .85;
            color: var(--color-table-title);
        `;
        }
    }};
`;

const ScheduleContainer = styled(FlexRow)`
    align-items: flex-start;
    justify-content: space-between;
    gap: 32px;
    & > * {flex: 1;}
`;

const ScheduleRowTitle = styled.h4`
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: var(--color-black);
`;

const ScheduleRowBlock = styled(FlexColumn)<{disabled?: boolean}>`
    justify-content: center;
    gap: 16px;
    ${({ disabled }) => {
        if (disabled) {
        return css`
            pointer-events:none;
            opacity: .85;
            color: var(--color-table-title);
        `;
        }
    }};
`;

const WeekDaysBlock = styled(FlexRow)`
    align-items: stretch;
    justify-content: space-between;
    gap: 16px;
    & > *:nth-child(1) {flex: 1 0 10%;}
    & > *:nth-child(2) {flex: 0 1 auto;}  
`;

const WeekDaysScheduleRowsBlock = styled(ScheduleRowBlock)`
    gap: 24px;
`;

const WeekDayCircle = styled.div<{selected: boolean; selectWeekDay?: boolean}>`
    min-width: 40px;
    height: 40px;
    padding: 10px 9px;
    text-align: center;
    font-size: 13px;
    color: #6B6F76;
    transition: .3s;
    ${({ selected, selectWeekDay }) => {
        if (selected && selectWeekDay) {
        return css`
            background-color: var(--color-blue);
            color: var(--color-white);
            border-radius: 50px;
            border: 1px #EFF1F4 solid;
        `;
        } else {
        return css`
            background-color: var(--color-white);
        `;
        }
    }};
    ${({ selectWeekDay }) => {
        if (selectWeekDay) {
        return css`
            cursor: pointer;
            border-radius: 50px;
            border: 1px #EFF1F4 solid;
            &:hover {
            opacity: .85;
            }
        `;
        }
    }};
`;

export {
    FlexColumn, 
    FlexRow, 
    ValidationError,
    ScheduleTypeBlock,
    ScheduleSwitcherBlock,
    ScheduleContainer,
    ScheduleRowTitle,
    ScheduleRowBlock,
    WeekDaysBlock,
    WeekDaysScheduleRowsBlock,
    WeekDayCircle,
};