import React, { ChangeEvent, InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { IconClose } from 'icons';
import { useMouseDown } from 'core/hooks';
import { Preloader } from 'components/preloader';

export type ItemDataSearchDefault = {
  id: string | number;
  name: string;
};

type SearchProps = InputHTMLAttributes<HTMLInputElement> & {
  icon?: any;
  valid?: boolean;
  name?: string;
  isClear?: boolean;
  onClear?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  disabled?: boolean;
  required?: boolean;
  data?: ItemDataSearchDefault[];
  loading?: boolean;
  value?: string;
  handleClickItem?: (data: Record<string, any>) => void;
};

export const SearchSelectorDefault: React.FC<SearchProps> = (props) => {
  const closeIconRef = useRef<HTMLSpanElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showList, setShowList] = useState(false);
  const Icon = props?.icon && props.icon;
  const { valid, loading } = props;

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (props?.disabled) return;
    props?.onChange?.(e);
  };

  const handleOnFocus = (e: ChangeEvent<HTMLInputElement>) => {
    if (props?.disabled) return;
    setShowList(true);
    props?.onFocus?.(e);
  };

  const handleOnBlur = (e: ChangeEvent<HTMLInputElement>) => {
    if (props?.disabled) return;
    props?.onBlur?.(e);
  };

  useEffect(() => {
    if (props?.value) {
      if (closeIconRef.current) {
        closeIconRef.current.style.opacity = '1';
        closeIconRef.current.style.cursor = 'pointer';
        closeIconRef.current.style.pointerEvents = 'auto';
      }
    } else {
      if (closeIconRef.current) {
        closeIconRef.current.style.opacity = '0';
        closeIconRef.current.style.cursor = 'text';
        closeIconRef.current.style.pointerEvents = 'none';
      }
    }
  }, [props?.value]);

  const handleClickClear = () => {
    if (props?.disabled) return;
    if (inputRef?.current) {
      inputRef.current.value = '';
      props?.onClear?.();
    }
  };

  useMouseDown(() => setShowList(false), listRef, inputRef);

  const handleClickItem = (data: Record<string, any>) => () => {
    props?.handleClickItem?.(data);
    setShowList(false);
  };

  return (
    <WrapperInput>
      {!!props?.icon && (
        <WrapperIcon>
          <Icon />
        </WrapperIcon>
      )}
      <Content
        ref={inputRef}
        disabled={!!props?.disabled}
        value={props?.value}
        name={props?.name}
        type={props?.type}
        icon={!!props.icon}
        valid={!!valid}
        isClear={!!props?.isClear}
        required={!!props?.required || false}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      ></Content>
      {props.placeholder && (
        <Placeholder valid={!!valid} icon={!!props.icon}>
          {props.placeholder}
        </Placeholder>
      )}
      {props?.isClear && (
        <WrapperIconClose ref={closeIconRef} onClick={handleClickClear}>
          <IconClose />
        </WrapperIconClose>
      )}
      {showList && (
        <List ref={listRef}>
          {loading && <div style={{ margin: 12 }}><Preloader loading={loading} /></div>}
          {props?.data?.length && !loading
            ? props?.data?.map((item: Record<string, any>) => (
                <Item key={item.id.toString()} onClick={handleClickItem(item)}>
                  {item.name}
                </Item>
              ))
            : !loading && <TextData>Ничего не найдено</TextData>}
        </List>
      )}
    </WrapperInput>
  );
};

const TextData = styled.div`
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
  text-align: center;
`;

const Item = styled.div`
  cursor: pointer;
  padding: 7px 16px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black-light);
  cursor: pointer;
  &:hover {
    background: var(--color-grey-light);
  }
`;
const List = styled.div`
  z-index: 9999;
  overflow-y: auto;
  width: 100%;
  max-height: 400px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: var(--color-white);
  transform: translateY(100%);
  border: 0.5px solid #dfe1e4;
  border-radius: 8px;
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.15);
`;
const WrapperIconClose = styled.span`
  cursor: text;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
`;

const Placeholder = styled.span<{ icon: boolean; valid: boolean }>`
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translateY(50%);
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  transition: all 0.33s ease;

  ${({ valid }) => {
    if (valid) {
      return css`
        color: var(--color-black-grey);
      `;
    } else {
      return css`
        color: var(--color-red);
      `;
    }
  }};

  ${({ icon }) => {
    if (icon) {
      return css`
        left: 40px;
      `;
    } else {
      return css`
        left: 16px;
      `;
    }
  }}
`;
const WrapperIcon = styled.div`
  position: absolute;
  left: 12px;
  top: 0;
  transform: translateY(70%);
  display: flex;
  align-items: center;
  justify-content: center;
`;
const WrapperInput = styled.div`
  width: 100%;
  position: relative;
`;
const Content = styled.input<{ icon: boolean; valid: boolean; isClear: boolean; disable?: boolean }>`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black);
  height: 40px;
  border: 1px solid #dfe1e4;
  border-radius: 8px;
  width: 100%;

  &::placeholder {
    color: #ffffff00;
  }

  &:focus {
    border: 1px solid #3562d4;
  }

  &:focus + ${Placeholder} {
    transform: translateY(0);
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    top: -6px;
    background: var(--color-white);
    padding: 0 5px;
    color: #3562d4;
  }
  &:valid + ${Placeholder} {
    transform: translateY(0);
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    top: -6px;
    background: var(--color-white);
    padding: 0 5px;
  }
  ${({ valid }) => {
    if (valid) {
      return css`
        border: 1px solid #dfe1e4;
      `;
    } else {
      return css`
        border: 1px solid var(--color-red);
      `;
    }
  }};

  ${({ icon, isClear }) => {
    if (isClear) {
      if (icon) {
        return css`
          padding: 12px 40px 12px 40px;
        `;
      } else {
        return css`
          padding: 12px 40px 12px 16px;
        `;
      }
    } else if (icon) {
      return css`
        padding: 12px 16px 12px 40px;
      `;
    } else {
      return css`
        padding: 12px 16px;
      `;
    }
  }}
`;
