import React from 'react';
import styled from 'styled-components';
import { HeaderTable } from './header';
import { header } from './helpers';
import { Row } from './row';
import { ItemTable } from './types';

const GRID_TABLE = '12% 7% 7% 8% 6% 7% 10% 10% 10% 8% 8%'; // '.06fr .22fr .2fr .12fr .07fr .04fr .16fr .15fr .15fr'

type TableProps = {
  handleChangeRow: (id?: number, remove?: boolean) => void;
  data: ItemTable[][];
  setSort: (value:string) => void;
  setSortType?: (type?: 'desc' | 'asc' | null | undefined) => void;
  sortType?: 'desc' | 'asc' | null | undefined;
  sort?: string | 'id';
};
export const Table: React.FC<TableProps> = ({ data, handleChangeRow, setSort, setSortType, sortType, sort }) => {
  
  return (
    <Content>
      <HeaderTable grid={GRID_TABLE} data={header} setSort={setSort} setSortType={setSortType} sortType={sortType} sort={sort}/>
      {data?.map((row, idx) => (//row?.[idx]?.id?.toString()
        <Row key={idx.toString()} grid={GRID_TABLE} data={row} handleChangeRow={handleChangeRow} />
      ))}
    </Content>
  );
};

const Content = styled.div``;
