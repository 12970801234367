import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import moment, { Moment } from 'moment';
import { IconCalendarFilter } from 'icons';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { getMonthDaysByString } from 'core/utils';
import { DatePicker } from 'components/datePicker';
import { TimePicker } from 'components/timePicker';
import { CalendarDays } from 'features/calendarDays';
import { entrySelector } from 'features/entry/entrySlice';
import { scheduleFeatureSelector } from 'features/schedule/scheduleFeatureSlice';
import { WeekDaysType } from 'features/schedule/types';
import { scheduleCalendarSlice } from 'features/scheduleCalendar/scheduleCalendarSlice';
import { weekDays } from 'features/schedule/data';
import { specialistSelector } from 'pages/specialist/specialistSlice';
import { initialState } from 'features/scheduleCalendar/data';
import { DataServiceProps, ItemServiceProps } from './index';

type ServiceCalendarProps = {
  serviceId: string;
  service: ItemServiceProps;
  dataService: DataServiceProps | null | undefined;
  showCalendar: boolean;
  setShowCalendar: (show: boolean) => void;
  setServices: React.Dispatch<React.SetStateAction<Record<string, ItemServiceProps>>>;
  onChangeDate: (date?: string) => void;
  onChangeTime: (time?: string) => void;
};

const getCurrentMonth = () => moment().format('YYYY-MM');
const getPrevMonth = (yearMonth: string) => moment(yearMonth).subtract(1, 'months').startOf('month').format('YYYY-MM');
const getNextMonth = (yearMonth: string) => moment(yearMonth).add(1, 'months').endOf('month').format('YYYY-MM');

export const ServiceCalendar: React.FC<ServiceCalendarProps> = (props) => {
  const {
    serviceId, 
    service, 
    dataService, 
    showCalendar, 
    setShowCalendar, 
    setServices, 
    onChangeDate, 
    onChangeTime
  } = props;
  const dispatch = useAppDispatch();
  const date_at = useAppSelector(entrySelector.date_at);
  const time_at = useAppSelector(entrySelector.time_at);
  const defaultSchedule = useAppSelector(scheduleFeatureSelector.schedule);
  const specialistId = useAppSelector(specialistSelector.specialistId);
  const specialist = useAppSelector(specialistSelector.specialist);
  const [yearMonth, setYearMonth] = useState<string>(() => getCurrentMonth());

  useEffect(() => {
    dispatch(scheduleCalendarSlice.actions.resetCalendar());
    dispatch(scheduleCalendarSlice.actions.setCalendar(initialState));
  }, []);

  useEffect(() => {
    const _calendar = {
      ...initialState,
        calendar: specialist?.calendar,
        days: specialist && getMonthDaysByString?.(yearMonth)?.map((day: Moment) => {
          const specialistSchedule = specialist?.schedule?.schedule 
            || specialist?.workplaces?.filter((w: any) => dataService?.workplace_id === w?.id && w?.schedule)?.[0]?.schedule?.schedule
            || (specialist?.workplaces?.filter((w: any) => dataService?.workplace_id === w?.id && w?.schedule)?.[0]?.branch?.schedule
              ? JSON.parse(specialist?.workplaces?.filter((w: any) => dataService?.workplace_id === w?.id && w?.schedule)?.[0]?.branch?.schedule)?.schedule
              : null
            )
            || defaultSchedule
            || null;

          return {
            yearMonth,
            date: day.format('DD.MM.YYYY'),
            dateMsec: day.unix(),
            dayNum: +day.format('DD'),
            monthNum: +day.format('MM'),
            yearNum: +day.format('YYYY'),
            weekDayNum: +moment(day).day(),
            weekDay: weekDays.filter((d: WeekDaysType) => d.id === +moment(day).day())?.[0]?.name || null,
            branch_id: null,
            branch: null,
            workplace_id: dataService?.workplace_id || null,
            workplace: specialist?.workplaces?.filter((w: any) => dataService?.workplace_id === w?.id)?.[0] || null,
            specialist_id: dataService?.specialist_id || specialistId || specialist?.id || null,
            specialist: specialist || null,
            calendar: specialist?.calendar?.filter((c: any) => c.date === day.format('YYYY-MM-DD') 
                && (dataService?.workplace_id ? dataService?.workplace_id === c?.workplace_id : c)
              ) || null,
            schedule: specialistSchedule,
          };
        }),
    };
    dispatch(scheduleCalendarSlice.actions.setCalendar(_calendar));
  }, [yearMonth, specialist, dataService]);


  const handleBlurDate = () => {
    if (dataService?.date_at) {
      const value = dataService?.date_at?.replaceAll('.', '').replaceAll('_', '');
      if (value.length < 8) {
        setServices?.((prev) => ({
          ...prev,
          [serviceId]: {
            ...prev[serviceId],
            validData: {
              ...prev[serviceId].validData,
              date_at: false,
            },
          },
        }));
      }
    }
  }

  const handleBlurTime = () => {
    if (dataService?.time_at) {
      const value = dataService?.time_at?.replaceAll(':', '').replaceAll('_', '');
      if (value.length < 4) {
        setServices?.((prev) => ({
          ...prev,
          [serviceId]: {
            ...prev[serviceId],
            validData: {
              ...prev[serviceId].validData,
              time_at: false,
            },
          },
        }));
      }
    }
  }

  console.log('ServiceCalendar',{yearMonth,defaultSchedule,dataService,specialistId,specialist});

  return (
    <InputsGroup>
      <WrapperInput>
        <DatePicker
          height={32}
          valid={service.validData.date_at}
          value={((date_at && time_at) && date_at) || dataService?.date_at || undefined}
          onChange={onChangeDate}
          disabled={true}
          onBlur={handleBlurDate}
        />
      </WrapperInput>
      <WrapperInput>
        <TimePicker
          height={32}
          placeholder={'чч:мм'}
          valid={service.validData.time_at}
          value={time_at || dataService?.time_at || undefined}
          onChange={onChangeTime}
          disabled={true}
          onBlur={handleBlurTime}
        />
      </WrapperInput>
      <WrapperInput>
        <ShowCalendarButton 
          disabled={(dataService?.service_id === undefined || dataService?.specialist_id === undefined) ? true : false}
          onClick={() => setShowCalendar(!showCalendar)}
        ><IconCalendarFilter/></ShowCalendarButton>
      </WrapperInput>
      {showCalendar && 
        <CalendarDays 
          setShowCalendar={setShowCalendar} 
          changeDate={onChangeDate}
          changeTime={onChangeTime}
        />
      }
    </InputsGroup>
  );
};

const InputsGroup = styled.div`
  display: grid;
  grid-template-columns: 125px 90px auto;
  grid-gap: 8px;
  position: relative;
`;
const WrapperInput = styled.div``;
const ShowCalendarButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  text-align: right;
  width: 100%;
  height: 100%;
`;
