import React, { useCallback, useState } from 'react';
import debounce from 'lodash/debounce';
import { ItemDataProps } from 'features/searchClientsSelector';
import config from 'api/config';

export const useSearch = ({
  setLoading,
  setSearch,
  request,
}: {
  request: (search?: string, size?: number) => Promise<ItemDataProps[]>;
  setLoading: (value: ((prevState: boolean) => boolean) | boolean) => void;
  setSearch: (value: ((prevState: string) => string) | string) => void;
}) => {
  const [requestData, setRequestData] = useState<ItemDataProps[]>([]);
  const [item, setItem] = useState<Record<string, any> | null>(null);

  const showValue = (name: string) => {
    request(name).then((res) => setRequestData(res));
  };

  const handledDebounce = useCallback(debounce(showValue, config.delaySearch), [item,request,requestData]);

  const handledOnChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length) {
      setLoading(!!e.target.value);
    }
    setSearch(e.target.value);
    handledDebounce(e.target.value);
    setItem(null);
  };

  const handleClickItem = (data: Record<string, any>) => {
    setItem(data);
    setSearch(data?.name);
  };

  const clearItem = () => {
    setItem(null);
    setSearch('');
  };

  return {
    requestData,
    handledOnChangeSearch,
    handleClickItem,
    activeItem: item,
    clearItem,
    setActiveItem: setItem,
  };
};
