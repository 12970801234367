import createRequestRest from 'api/createRequestRest';
import { put } from 'redux-saga/effects';
import { serviceSlice } from '../serviceSlice';

function* prepareRequest(data: any) {
  return data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  yield put(serviceSlice.actions.getAllServiceSuccess(response));
  payload?.payload?.success?.(response);

  // if (response?.data) {
  //   yield putResolve(
  //     serviceSlice.actions.updateItemsData({
  //       id: payload?.category_id?.toString(),
  //       data: [...(response?.data || [])],
  //     }),
  //   );
  // }
}

function* prepareFailure(res: any) {
  yield;
}

export const getAllService = () => {
  return createRequestRest({
    url: '/api/v1/service/getAll',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: serviceSlice.actions.getAllServiceRequest,
    method: 'get',
  });
};
