import React, { useState } from 'react';
import styled from 'styled-components';
import { IconPlus } from 'icons';
import { ModalAnt } from 'components/modalAnt';
import { Input } from 'components/input';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { DefaultButton, WhiteButton } from 'components/buttons';
import { kanbanSelector, kanbanSlice } from '../kanbanSlice';

export const AddColumn: React.FC = () => {
  const dispatch = useAppDispatch();
  const columns = useAppSelector(kanbanSelector.columns);
  const [show, setShow] = useState<boolean>(false);
  const [name, setName] = useState('');
  const handledClick = () => {
    setShow(true);
  };

  const handledClose = () => {
    setShow(false);
  };

  const success = () => {
    dispatch(kanbanSlice.actions.getAllColumnTodoListRequest());
    setShow(false);
    setName('');
  };

  const handledSubmit = () => {
    dispatch(
      kanbanSlice.actions.createColumnRequest({
        payload: {
          data: { name, sort: (columns?.length + 1).toString() },
          success,
        },
      }),
    );
  };

  return (
    <>
      <Content>
        <Button onClick={handledClick}>
          <IconPlus />
        </Button>
      </Content>
      <ModalAnt show={show} onClose={handledClose}>
        <BodyModal>
          <Title>Создание колонки</Title>
          <WrapperInput>
            <Input value={name} placeholder='Введите название колонки' onChange={(e) => setName(e.target.value)} />
          </WrapperInput>
          <WrapperButtons>
            <WrapperBtn onClick={handledSubmit}>
              <DefaultButton>Создать</DefaultButton>
            </WrapperBtn>
            <WrapperBtn onClick={handledClose}>
              <WhiteButton>Отмена</WhiteButton>
            </WrapperBtn>
          </WrapperButtons>
        </BodyModal>
      </ModalAnt>
    </>
  );
};
const WrapperBtn = styled.div`
  width: 100%;
  &:not(:last-child) {
    margin-right: 10px;
  }
`;
const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  width: 100%;
`;
const BodyModal = styled.div`
  padding: 10px;
`;
const WrapperInput = styled.div`
  width: 250px;
`;
const Title = styled.div`
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0;
  white-space: nowrap;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  &:hover {
    background: #f4f5f8;
  }
`;

const Button = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #dfe1e4;
  border-radius: 4px;
  svg {
    fill: #6471e0;
  }
`;
