import React from 'react';
import styled from 'styled-components';
import { DefaultButton, WhiteButton } from 'components/buttons';
import { Checkbox } from 'components/checkbox';

const noop = () => undefined;
export const SettingsButton: React.FC = () => {
  return (
    <Content>
      <Row>
        <Title>Настройка кнопки «Заказать звонок»</Title>
        <Checkbox active={true} setActive={noop} />
      </Row>
      <WrapperCheckbox>
        <Checkbox active={true} setActive={noop} title='Введите ваш номер телефона' />
      </WrapperCheckbox>
      <WrapperCheckbox>
        <Checkbox active={true} setActive={noop} title='Введите ваше имя' />
      </WrapperCheckbox>
      <WrapperCheckbox>
        <Checkbox active={true} setActive={noop} title='Введите ваш вопрос' />
      </WrapperCheckbox>
      <Row>
        <Title>Настройка кнопки «Отменить запись»</Title>
        <Checkbox active={true} setActive={noop} />
      </Row>
      <WrapperCheckbox>
        <Checkbox active={true} setActive={noop} title='Введите ваш номер телефона' />
      </WrapperCheckbox>
      <WrapperCheckbox>
        <Checkbox active={true} setActive={noop} title='Виберите запись' />
      </WrapperCheckbox>
      <WrapperCheckbox>
        <Checkbox active={true} setActive={noop} title='Введите слово “Отменить”' />
      </WrapperCheckbox>
      <WrapperButtons>
        <DefaultButton>Сохранить</DefaultButton>
        <WhiteButton>Отменить</WhiteButton>
      </WrapperButtons>
      <Dev><h4>В разработке</h4></Dev>
    </Content>
  );
};
const WrapperCheckbox = styled.div`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;
const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: var(--color-black);
`;
const Row = styled.div`
  display: grid;
  grid-column-gap: 86px;
  grid-template-columns: 310px 50px;
  grid-template-rows: 1fr;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const Content = styled.div`
  padding: 40px 60px;
  position: relative;
  pointer-events: none;
  opacity: 0.65;
`;

const WrapperButtons = styled.div`
  margin-top: 100px;
  display: grid;
  grid-template-columns: 150px 150px;
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
`;

const Dev = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > h4 {
    text-align: center;
    opacity: 1;
    color: #461684;
    padding: 2em;
    background-color: rgba(255,255,255,0.8);
  }
`;