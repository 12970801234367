import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'core/store';
import { clientFrontBack } from './helpers';

type TodolistStateProps = {
  entries: Record<string, any>[];
  count: number;
  entry: null | Record<string, any>;
  search: Record<string, any>[];
};

const initialState: TodolistStateProps = {
  entries: [],
  count: 0,
  entry: null,
  search: [],
};

export const todolistSlice = createSlice({
  name: 'todolist',
  initialState,
  reducers: {
    //сброс данных
    //---------------------------------
    resetTodolistActive: (state: TodolistStateProps) => {
      state.entry = null;
    },
    resetSearch: (state: TodolistStateProps) => {
      state.search = [];
    },
    //Предустановить данные по умолчанию
    setTodolistActive: (state: TodolistStateProps, action) => {
      state.entry = action.payload;
    },
    //Поиск Todolist
    //---------------------------------
    searchTodolistRequest: (state: TodolistStateProps, action) => {
      //
    },
    searchTodolistSuccess: (state: TodolistStateProps, action) => {
      state.search = action.payload?.data;
    },
    //Получение всех Todolist
    //---------------------------------
    getAllTodolistRequest: (state: TodolistStateProps, action) => {
      //
    },
    getAllTodolistSuccess: (state: TodolistStateProps, action) => {
      state.entries = action.payload?.data;
      state.count = action.payload?.count;
    },
    //Получение Todolist по id
    //---------------------------------
    getTodoListIdRequest: (state: TodolistStateProps, action) => {
      //
    },
    getTodoListIdSuccess: (state: TodolistStateProps, action) => {
      state.entry = action.payload; //clientFrontBack(action.payload);      
    },
    //Создание Todolist
    //---------------------------------
    postCreateTodolistRequest: (state: TodolistStateProps, action) => {
      //
    },
    postCreateTodolistSuccess: (state: TodolistStateProps, action) => {
      state.entry = action.payload;
    },
    updateTodolistTable: (state: TodolistStateProps, action) => {
      state.entries = [...state.entries, action.payload?.payload?.data];
    },
    //Обновление Todolist
    //---------------------------------
    postUpdateTodolistRequest: (state: TodolistStateProps, action) => {
      //
    },
    postUpdateTodolistSuccess: (state: TodolistStateProps, action) => {
      state.entry = action.payload; //clientFrontBack(action.payload);
    },
    //Удаление Todolist
    //---------------------------------
    deleteTodolistRequest: (state: TodolistStateProps, action) => {
      //
    },
    deleteTodolistSuccess: (state: TodolistStateProps, action) => {
      // state.entries = action.payload;
    },
  },
});

export const todolistSelector = {
  entries: (s: RootState) => s.todolist.entries,
  entry: (s: RootState) => s.todolist.entry,
  count: (s: RootState) => s.todolist.count,
};