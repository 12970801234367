import { all } from 'redux-saga/effects';
import { getTodolist } from './getTodolist';
import { deleteTodolist } from './deleteTodolist';
import { createTodolist } from './postCreateTodolist';
import { updateTodolist } from './postUpdateTodolist';
import { getTodolistId } from './getTodolistId';
import { searchTodolist } from './searchTodolist';

export default function* todolistSaga() {
  yield all([
    getTodolist(),
    deleteTodolist(),
    createTodolist(),
    updateTodolist(),
    getTodolistId(),
    searchTodolist(),
  ]);
}
