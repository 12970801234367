import React, { useState, useEffect } from 'react';
import { TimePicker } from 'components/timePicker';
import { FlexColumn, FlexRow, ValidationError } from '../styles';
import { compareTime } from '../utils';
import { DateTimePickersRowProps } from '../types';
import { WeekDay } from './weekDay'

export const TimePickersRow: React.FC<DateTimePickersRowProps> = (props) => {
  const {
    number, 
    name, 
    titleBegin, 
    titleEnd, 
    begin, 
    end, 
    disabled, 
    showSuffix, 
    showWeekDay, 
    selectWeekDay, 
    setBegin, 
    setEnd
  } = props;
  const [isValid, setValid] = useState<boolean>(true);
  
  useEffect(() => {
    if (begin !== undefined && end !== undefined && begin?.length && end?.length) {
      setValid(compareTime(begin, end));  
    }
  }, [begin, end]);

  useEffect(() => {
    !isValid && setEnd?.('');
  }, [isValid]);

  return (
    <FlexColumn>
      <FlexRow>
        {showWeekDay && 
          <WeekDay 
            number={number}
            name={name} 
            begin={begin} 
            end={end} 
            isValid={isValid} 
            selectWeekDay={selectWeekDay}
            setBegin={setBegin}
            setEnd={setEnd}
          />
        }
        <TimePicker
          title={titleBegin}
          value={begin}
          placeholder={'чч:мм'}
          height={32}
          disabled={disabled || false}
          showSuffix={showSuffix}
          valid={isValid}
          onChange={setBegin}
        />
        <div>&ndash;</div>
        <TimePicker
          title={titleEnd}
          value={end}
          placeholder={'чч:мм'}
          height={32}
          disabled={disabled || false}
          showSuffix={showSuffix}
          valid={isValid}
          onChange={setEnd}
        />
      </FlexRow>
      {!isValid && <ValidationError>Время окончания не может быть больше времени начала</ValidationError>}
    </FlexColumn>
  );
};
