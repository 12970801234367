import React from 'react';
import styled, { css } from 'styled-components';
import moment, { Moment } from 'moment';
import 'moment/locale/ru';
import { useAppDispatch } from 'core/hooks';
import { setDate } from 'features/entry/entrySlice';

moment.locale('ru');

type DateDayProps = {
  date: Moment;
  activeDayNum: number;
  setActiveDayNum: (i: number) => void;
  changeDate: (value?: string) => void;
  setLoading: (loading: boolean) => void;
};
export const DateDay: React.FC<DateDayProps> = ({ date, activeDayNum, setActiveDayNum, setLoading, changeDate }) => {
  const dispatch = useAppDispatch();
  const dayNum = +date.format('D');
  const dayName = date.format('dd').toUpperCase().charAt(0) + date.format('dd').charAt(1);  
  const dateString = date.format('DD.MM.YYYY').toString();
  
  const handleSetDateDay = () => {
    if (dayNum >= +moment().format('D') ) {
      setLoading(true);
      setActiveDayNum(dayNum);
      changeDate(dateString);
      setTimeout(() => {
        dispatch(setDate(dateString));
        setLoading(false);
      }, 500);
    }
  };
  
  return (
    <Content activeDayNum={activeDayNum === dayNum} onClick={handleSetDateDay}>
      <Day>{dayName}</Day>
      <Number>{dayNum}</Number>
    </Content>
  );
};

const Number = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-top: 3px;
`;
const Day = styled.div`
  font-size: 12px;
  font-weight: 500;
`;
const Content = styled.button<{ activeDayNum: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 8px;
  background: var(--color-white);
  color: #9499a3;

  ${({ activeDayNum }) => {
    if (activeDayNum) {
      return css`
        background: var(--color-blue);
        color: #fff;
        border-radius: 4px;
        padding: 3px 5px;
      `;
    }
  }}
`;
