import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useScheduleContext } from '../context';
import { ScheduleRow } from '../row';
import { TimePickersRow } from '../row/timePickersRow';
import { GrafikTimeout } from '../timeout';
import { ScheduleFromToType, ScheduleType } from '../types';
import { 
  ScheduleContainer, 
  ScheduleRowBlock, 
  ScheduleRowTitle,
  WeekDaysBlock,
  WeekDaysScheduleRowsBlock
} from '../styles';

export const ScheduleFlexibleGrafik: React.FC = () => {
  const { schedule, showShifts, setSchedule } = useScheduleContext();
  //const [timeouts, setTimeouts] = useState<ScheduleFromToType[] | null>(null);

  /*useEffect(() => {
    if (schedule?.length) {
      const scheduleClone = JSON.parse(JSON.stringify(schedule));
      for (let i = 0; i < scheduleClone.length; i++) {
        const timeout = scheduleClone[i].data[0].grafik.timeout;

        for (let j = 0; j < timeout.length; j++) {
          const from = timeout[j].from;
          const to = timeout[j].to;
          
          scheduleClone[i].data[0].grafik.timeout[j].from = from || null;
        }
      }
      setSchedule(scheduleClone);
    } 
  }, [schedule]);*/

  /*const handleSetTimeoutFrom = (number: number, dayName?: string | null, time?: string) => {
    if (schedule?.length) {
      const scheduleClone = JSON.parse(JSON.stringify(schedule));
      for (let i = 0; i < scheduleClone.length; i++) {
        if (number === i && scheduleClone[i].data[0].name === dayName) {
          scheduleClone[i].data[0].grafik.timeout[0].from = time || null; // пока только первый перерыв
        }
      }
      setSchedule(scheduleClone);
    }
  }

  const handleSetTimeoutTo = (number: number, dayName?: string | null, time?: string) => {
    if (schedule?.length) {
      const scheduleClone = JSON.parse(JSON.stringify(schedule));
      for (let i = 0; i < scheduleClone.length; i++) {
        if (number === i && scheduleClone[i].data[0].name === dayName) {
          scheduleClone[i].data[0].grafik.timeout[0].to = time || null; // пока только первый перерыв
        }
      }
      setSchedule(scheduleClone);
    }
  }*/
console.log('ScheduleFlexibleGrafik', schedule);

  return (
    <Content>
      <ScheduleContainer>
        <ScheduleRowBlock>
          <ScheduleRowTitle>Рабочее время</ScheduleRowTitle>
          <WeekDaysBlock>
            <WeekDaysScheduleRowsBlock>
              {schedule?.map?.((item: ScheduleType, i: number) => 
                <ScheduleRow 
                  key={i}
                  type={'time'}
                  number={i}
                  name={item?.data?.[0]?.name}
                  disabled={false}
                  showSuffix={false}
                  titleBegin='Начало'
                  titleEnd='Окончание'
                  grafikTime={item?.data?.[0]?.grafik?.time?.[0]}
                  showWeekDay={true}
                  selectWeekDay={true}
                  showShifts={showShifts}
                />
              )}
            </WeekDaysScheduleRowsBlock>
          </WeekDaysBlock>
        </ScheduleRowBlock>
        <ScheduleRowBlock>
          <ScheduleRowTitle style={{"marginLeft": "12px"}}>Нерабочее время</ScheduleRowTitle>
          <WeekDaysBlock>
            <WeekDaysScheduleRowsBlock>
              <GrafikTimeout />
            </WeekDaysScheduleRowsBlock>
          </WeekDaysBlock>
        </ScheduleRowBlock>
      </ScheduleContainer>
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  margin-top: 16px;
`;